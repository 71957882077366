import React, { useState, useEffect } from 'react';
import './App.css';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
    //Link    
} from 'react-router-dom';

import { MsalProvider, useMsal } from "@azure/msal-react";
import { MsalAuthenticationTemplate, useAccount } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { silentRequest } from "./authConfig";
//import { profileRequest } from "./authConfig";

//import { forgotPasswordRequest } from "../authConfig.js";
import { loginRequest } from "./authConfig";
//import { Check } from 'office-ui-fabric-react';

import axios from 'axios';

import { initializeFileTypeIcons } from '@fluentui/react-file-type-icons';
import { 
    //calculatePrecision,
    initializeIcons,
    Stack,
    Icon
} from '@fluentui/react';

import Navigation from './components/navigation/Navigation';
import Header from './components/header/Header';
import AllDocuments from './components/etax/documents/all/AllDocuments';
import Pdf from './components/etax/documents/pdf/Pdf';
import Xml from './components/etax/documents/xml/Xml';
import XmlServiceProvider from './components/etax/documents/xml/XmlServiceProvider';
import XmlLegacy from './components/etax/documents/xml/Xml-legacy';
import ViewPdf from './components/etax/documents/pdf/view/ViewPdf';
import ViewXml from './components/etax/documents/xml/view/ViewXml';
import SubscriptionAccounts from './components/accounts/subscriptions/SubscriptionAccounts';
import PrepaidAccounts from './components/accounts/prepaid/PrepaidAccounts'
import Companies from './components/companies/Companies';
import Checks from './components/checks/Checks';
import LogOut from './components/logout/LogOut';
import Loading from './components/loading/Loading';
import ScrollToTop from './components/scroll-to-top/ScrollToTop';
import Certificates from './components/etax/certificates/Certificates';
import P12 from './components/etax/certificates/add/p12/P12';
import Maintenance from './components/maintenance/Maintenance'
import DocumentsCreditsAdd from './components/companies/credits/documents/add/DocumentsCreditsAdd';
import SmsCreditsAdd from './components/accounts/credits/sms/add/SmsCreditsAdd';
import PdfTemplate from './components/templates/pdf/PdfTemplate';
import TemplatePreview from './components/templates/pdf/preview/TemplatePreview';
import XmlDeliver from './components/etax/xml/deliver/XmlDeliver';
import SettingsCompaniesDetails from './components/settings/companies-details/SettingsCompaniesDetails';
import CreateStatement from './components/accounts/subscriptions/CreateStatement';
import UpdateSupscription from './components/accounts/subscriptions/UpdateSupscription';

// Register icons and pull the fonts from the default SharePoint cdn.
initializeFileTypeIcons();
initializeIcons();


const API_URL = process.env.REACT_APP_API_URL;
const ADMIN_API_URL = process.env.REACT_APP_ADMIN_API_URL;

const LECEIPT_VERSION = process.env.REACT_APP_LECEIPT_VERSION;

// Styles definition
const stackStyles = {
    root: {
      //background: DefaultPalette.themeTertiary,
      //width: 300,
      background: '#FAF9F8',
      //minHeight: '100vh',
      //height: '100%',
      height: '100vh',
      //height: '500px',
      margin: 0,
      padding: 0,
      minWidth: '1400px',
      minHeight: '100%',
      //overflowY: 'scroll',
      //overflowX: 'hidden',
      
    },
  };


function App({ pca }) {
    return (
        <MsalProvider instance={pca}>
            <MsalAuthenticationTemplate
                interactionType={InteractionType.Redirect}
                authenticationRequest={loginRequest}
                /* authenticationRequest={{
                    redirectStartPage: "http://localhost:3000/",
                }} */
                //errorComponent={ErrorComponent}
                errorComponent={LogOut}
                //errorComponent={{redirectStartPage: "http://localhost:3000/"}}
                loadingComponent={Loading}
            >
                <Body />
    
            </MsalAuthenticationTemplate>
        </MsalProvider>
    );
    
}

const Pages = () => {
    
	return (
		
        <Switch>                

            <Route exact path="/etax/documents/xml" >                                    
                <ScrollToTop />
                <Xml/>                
            </Route>
            <Route exact path="/etax/documents/xml-service-provider" >                                    
                <ScrollToTop />
                <XmlServiceProvider/>                
            </Route>
            <Route exact path="/etax/documents/xml-legacy" >                                    
                <ScrollToTop />
                <XmlLegacy/>                
            </Route>
            <Route exact path="/etax/documents/pdf" >                                    
                <ScrollToTop />
                <Pdf/>                
            </Route>
            <Route exact path="/etax/documents/all" >                                    
                <ScrollToTop />
                <AllDocuments/>                
            </Route>
            <Route exact path="/etax/documents/pdf/view" >                                    
                <ScrollToTop />
                <ViewPdf/>                
            </Route> 
            <Route exact path="/etax/documents/xml/view" >                                    
                <ScrollToTop />
                <ViewXml/>                
            </Route>
            <Route exact path="/etax/certificates" >                                    
                <ScrollToTop />
                <Certificates />
            </Route>
            <Route exact path="/etax/certificates/add/p12" >                                    
                <ScrollToTop />
                <P12 />
            </Route>
            <Route exact path="/accounts/prepaid" >                                    
                <ScrollToTop />
                <PrepaidAccounts/>                
            </Route>
            <Route exact path="/accounts/subscriptions" >                                    
                <ScrollToTop />
                <SubscriptionAccounts/>                
            </Route>
            <Route exact path="/accounts/subscriptions/create-statement" >                                    
                <ScrollToTop />
                <CreateStatement />            
            </Route>
            <Route exact path="/accounts/subscriptions/update-subscription" >                                    
                <ScrollToTop />
                <UpdateSupscription />              
            </Route>
            <Route exact path="/accounts/credits/documents/add" >                                    
                <ScrollToTop />
                <DocumentsCreditsAdd />                
            </Route>
            <Route exact path="/accounts/credits/sms/add" >                                    
                <ScrollToTop />
                <SmsCreditsAdd />                
            </Route>
            <Route exact path="/companies" >                                    
                <ScrollToTop />
                <Companies />                
            </Route>
            <Route exact path="/checks" >                                    
                <ScrollToTop />
                <Checks />                
            </Route>
            <Route exact path="/maintenance" >                                    
                <ScrollToTop />
                <Maintenance />                
            </Route>
            <Route exact path="/templates/pdf" >                                    
                <ScrollToTop />
                <PdfTemplate />                
            </Route>
            <Route exact path="/templates/pdf/preview" >                                    
                <ScrollToTop />
                <TemplatePreview />                
            </Route>
            <Route exact path="/etax/xml/deliver">
                <ScrollToTop />
                <XmlDeliver />
            </Route>
            <Route exact path="/settings/companies-details">
                <ScrollToTop />
                <SettingsCompaniesDetails />
            </Route>
            
            <Redirect to="/etax/documents/all" />
                         
        </Switch>

                        
	)
}

const Body = () =>{    

    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    
    const [isLoadAccountsComplete, setIsLoadAccountsComplete] = useState(false);
    const [accountData, setAccountData] = useState({});

    const [isLoadRolesComplete, setIsLoadRolesComplete] = useState(false);
    const [role, setRole] = useState('');

    const initial = async () => {

        await instance.acquireTokenSilent({...silentRequest, account: account}).then(async tokenResponse => {
            console.log('tokenResponse: ', tokenResponse);

            await axios.get(ADMIN_API_URL + '/roles', 
                {
                headers: { 
                    'Authorization': 'Bearer ' + tokenResponse.accessToken
                } 
                })
                .then((responseRoles) => {                   

                    console.log(responseRoles);

                    if (responseRoles.data && responseRoles.data.roles) {

                        setRole(responseRoles.data.roles);
                        
                    } 

                    setIsLoadRolesComplete(true);

                }, (error) => {
                    console.log(error);
                
                });      

            await axios.get(API_URL + '/accounts', 
                {
                headers: { 
                    'Authorization': 'Bearer ' + tokenResponse.accessToken
                } 
                })
                .then((response) => {                   

                    console.log(response);

                    if (response.data) {
                        
                        
                        setAccountData(response.data);
                        
                    } 

                    setIsLoadAccountsComplete(true);

                    //setIsLoading(false);

                    
                }, (error) => {
                    console.log(error);
                
                });           


        }).catch(error => {
           
            //Acquire token silent failure, and send an interactive request
            console.log(error);
            instance.acquireTokenRedirect(silentRequest);

        });

    }

    useEffect(() => {
        console.log('userEffect retrieve accounts data!');    

        initial();       

    }, []);

    return (
        
        <Router>
            {role
                ?
                    !isLoadAccountsComplete 
                        ?
                            <div>
                                <Loading />
                                
                            </div>            
                    
                        :               
                        
                            <Stack styles={stackStyles} disableShrink={false} wrap={false} >
                            {/* <Stack styles={{ root: {overflowY: 'hidden', overflowX: 'hidden'} }} disableShrink={false} wrap={false}> */}
                                <Stack horizontal style={{ 
                                    //background: 'green', 
                                    //minWidth: '1440px' 
                                }} >
                                    <Header {...{accountData: accountData}} />
                                </Stack>
                                <Stack horizontal >
                                    <Stack vertical style={{ background: 'white', height: '100%', minHeight: 'calc(100vh - 70px)' }} verticalAlign='space-between'>                            
                                    {/* <Stack vertical style={{ background: 'white' }} verticalAlign='space-between'> */}
                                        
                                            <Navigation />
                                            
                                            <span style={{ paddingLeft: '25px', paddingBottom: '15px', fontSize: '14px', color: '#323130' }}>
                                                <br />
                                                <span>
                                                    Update: {LECEIPT_VERSION}
                                                </span>
                                            </span>
                                        


                                    </Stack>
                                    {/* <Stack vertical style={{ background: '#FAF9F8', width: '100%', overflowY: 'scroll', overflowY: 'scroll', height: '91vh' }}> */}
                                    <Stack vertical style={{ background: '#FAF9F8', width: '100%' }}>
                                        <Stack horizontal horizontalAlign='center' wrap={false}>
                                            <Pages /> 
                                            
                                        </Stack>
                                        {/* {JSON.stringify(accountData)} */}
                                    </Stack>
                                </Stack>
                            </Stack>
                :
                    isLoadRolesComplete
                        ?
                            <div>
                                <Maintenance />
                                
                            </div>   
                        :
                            <div>
                                <Loading />
                                
                            </div>   
            }                        
            
        </Router>
        
    
    );
    
}

export default App;
