import React, { useState, useEffect, useRef } from 'react';

import axios from 'axios';

import { useMsal, useAccount } from '@azure/msal-react';
import { silentRequest } from "../../../../authConfig";

import Handlebars from 'handlebars';

import { useHistory, useLocation } from 'react-router-dom';

import {     
    PrimaryButton, 
    DefaultButton,    
    Stack, 
    //getTheme,     
    ProgressIndicator,
    Spinner,
    MessageBar,
    MessageBarType
    
    
} from '@fluentui/react';

//import sanitizeHtml from 'sanitize-html';

// const API_URL = process.env.REACT_APP_API_URL;
const ADMIN_API_URL = process.env.REACT_APP_ADMIN_API_URL;

//const theme = getTheme();

const stackTokens = { childrenGap: 15 };

const dropdownStyles = {
    dropdown: { width: 250 },
};

//const iconProps = { iconName: 'Calendar' };
//const stackStyles = { root: { width: 800 } };
/* 
const _alertClicked = () => {
    alert('Clicked');
}
 */

//initializeIcons();


const TemplatePreview = () => {

    console.log('Re render');

    const history = useHistory();
    const location = useLocation();    

    const data = location.state;
    console.log(data);

    if ((data === undefined) || !data.companyId) {
        history.push('/templates/pdf');
    }

    const companyId = data.companyId;

    //console.log(data.htmlTemplate);

    const jsonData = JSON.parse(data.data);
    console.log(jsonData);

    //const source = "";

    Handlebars.registerHelper('decimal', function(number) {
        //return formatDecimal(number);
        return parseFloat(number).toLocaleString(undefined, {minimumFractionDigits:2, maximumFractionDigits:2})
    });

    Handlebars.registerHelper('isValue', function(value) {
        return (value !== undefined) && (value !== null) && (value !== ' ') && (value !== '-') 
    });

    Handlebars.registerHelper('numberWithCommas', function(number) {
        return number.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    });

    Handlebars.registerHelper('filterPostcode', function(postcode) {
        let _filterPostcode = '';
    
        if (postcode !== '00000') {
            _filterPostcode = postcode;
        }
    
        return _filterPostcode;
    });

    Handlebars.registerHelper('notShowThailand', function(countryName) {
        let _notShowThailand = '';
    
        if ((countryName !== 'Thailand') && (countryName !== 'ไทย')) {
            _notShowThailand = countryName;
        }
    
        return _notShowThailand;
    });

    const template = Handlebars.compile(data.htmlTemplate);
    const htmlString = template(jsonData);
/* 
    const cleanHtml = sanitizeHtml(htmlString, {
        allowedTags: [ 'style', 'page' ],
    });


    const html = "<strong>hello world</strong>";
    console.log(sanitizeHtml(html));
    console.log(sanitizeHtml("<img src=x onerror=alert('img') />"));
    console.log(sanitizeHtml("console.log('hello world')"));
    console.log(sanitizeHtml("<script>alert('hello world')</script>"));
 */

    //const auth = Auth();
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});    

    //const [html, setHtml] = useState('');
    const [documentType, setDocumentType] = useState({ key: '', text: '' });
    const [clickSave, setClickSave] = useState(false);
    const [showMessageBarSuccess, setShowMessageBarSuccess] = useState(false);
    const [showMessageBarError, setShowMessageBarError] = useState(false);

    
    const centerItem = { display: "flex", alignItems: "center", justifyContent: "center" };


    

    const handleSubmit = (e) => {
        e.preventDefault();        

        console.log("submit click");

        setClickSave(true);

        //setShowMessageBarSuccess(true);
        //setShowMessageBarError(true);

        instance.acquireTokenSilent({...silentRequest, account: account}).then(tokenResponse => {       
            
            let _apiEndpoint = ADMIN_API_URL + '/etax/templates/' + documentType.key + '/pdf/' + companyId;

            if (documentType.key === 'quotation' || documentType.key === 'invoice' || documentType.key === 'billing-note' || documentType.key === 'deliveryorder') {
                _apiEndpoint = ADMIN_API_URL + '/documents/templates/' + documentType.key + '/pdf/' + companyId;
            }

            //axios.post('https://etax.azure-api.net/company/create', {
            //axios.put(API_URL + '/etax/templates/' + documentType.key + '/pdf', {
            axios.put(_apiEndpoint, {
                //"$content-type": "application/octet-stream",
                "$content-type": "text/plain",
                "$content": data.htmlTemplate,                
            }, {
                headers: {
                    'Authorization': 'Bearer ' + tokenResponse.accessToken,
                    'Content-Type': 'application/json',
                    
                }
            })
            .then((response) => {
                //console.log(response.data.id);
                console.log(response);
                setClickSave(false);
                setShowMessageBarSuccess(true);
                window.scrollTo(0, 0);
                
            }, (error) => {
                console.log(error);
                setClickSave(false);
                setShowMessageBarError(true);
                window.scrollTo(0, 0);

            });
           

        }).catch(error => {
            /* 
            if (error instanceof InteractionRequiredAuthError) {
                // fallback to interaction when silent call fails
                return instance.acquireTokenRedirect(tokenRequest)
            }
    */

            //Acquire token silent failure, and send an interactive request
            console.log(error);
            instance.acquireTokenRedirect(silentRequest);

/* 
            if (error.errorMessage.indexOf("interaction_required") !== -1) {
                instance.acquireTokenRedirect(silentRequest);
            }
*/

        });

    }

    const handleEdit = (e) => {
        e.preventDefault();  
    
        history.push({
            pathname: '/templates/pdf',
            //search: '?update=true',  // query string
            state: data
        }); 
    

    }

    useEffect(() => {
        console.log('userEffect call documentType change!');          
        


    }, [documentType]);

    useEffect(() => {
        console.log('userEffect initial call!');    
        setDocumentType({
            key: data.documentType.key,
            text: data.documentType.text
        });
        

        

    }, []);

    return (
        <div>                        
            { showMessageBarSuccess && <MessageBar
/* 
                actions={
                <div>
                    <MessageBarButton>Yes</MessageBarButton>
                    <MessageBarButton>No</MessageBarButton>
                </div>
                }
                 */
                messageBarType={MessageBarType.success}
                isMultiline={false}
                onDismiss={()=> setShowMessageBarSuccess(false)}
                dismissButtonAriaLabel="Close"
            >
                บันทึกสำเร็จ!                           
            </MessageBar> }
            { showMessageBarError && <MessageBar
/* 
                actions={
                <div>
                    <MessageBarButton>Yes</MessageBarButton>
                    <MessageBarButton>No</MessageBarButton>
                </div>
                }
                 */
                messageBarType={MessageBarType.error}
                isMultiline={false}
                onDismiss={()=> setShowMessageBarError(false)}
                dismissButtonAriaLabel="Close"
            >
                บันทึกไม่สำเร็จ!                           
            </MessageBar> }
            <h2 style={centerItem}>ตัวอย่างรูปแบบเอกสาร {documentType.text}</h2>
            <center>
                <h3>บริษัท: {data.companyName}</h3>
                {/* <br/> */}
                ชื่อ: {data.firstName} {data.lastName}
                <br/>
                อีเมล: {data.email}
                <br/>
                companyId: {data.companyId}   
                <br/>
                oid: {data.oid}                                
                <br/>
                <br/>
            </center>

            <Stack horizontal style={centerItem} tokens={{ childrenGap: 30 }} >
                { !clickSave && <PrimaryButton text="บันทึก" iconProps={{ iconName: 'SaveTemplate' }} onClick={handleSubmit} /> }
                { !clickSave &&  <DefaultButton text="กลับ" iconProps={{ iconName: 'Back' }} onClick={handleEdit} /> }

                { clickSave && <Spinner label="กำลังบันทึก..." labelPosition="right" /> }
                
            </Stack>
            
            <br />            
            <br />
            <div dangerouslySetInnerHTML={{ __html: htmlString }} />
            {/* <div>{JSON.stringify(data)}</div> */}
            <br />
            
        </div>
    );
}

export default TemplatePreview;