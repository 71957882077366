import React, { useState, useEffect } from "react";
import axios from "axios";

import { useMsal, useAccount } from "@azure/msal-react";
import { silentRequest } from "../../../authConfig";
// import { useTranslation } from 'react-i18next';

import { useHistory, useLocation } from "react-router-dom";

import {
    mergeStyles,
    PrimaryButton,
    DefaultButton,
    IconButton,
    Stack,
    getTheme,
    Icon,
    FontIcon,
    Dialog,
    DialogType,
    Spinner,
    mergeStyleSets,
    FontWeights,
    Modal,
    DialogFooter,
} from "@fluentui/react";

import { useBoolean } from "@fluentui/react-hooks";

import moment from "moment";

import "./Certificates.css";

const API_URL = process.env.REACT_APP_API_URL;
const ADMIN_API_URL = process.env.REACT_APP_ADMIN_API_URL;

const theme = getTheme();

//Style
const centerItem = { display: "flex", alignItems: "center", justifyContent: "center" };

const iconClass = mergeStyles({
    fontSize: 26,
    paddingLeft: "10px",
    paddingTop: "3px",
    color: "green",
});

// Processing dialog style
const spinnerStyles = {
    circle: {
        height: 56,
        width: 56,
        borderWidth: 4,
    },
};

// Modal style
const contentStyles = mergeStyleSets({
    container: {
        display: "flex",
        flexFlow: "column nowrap",
        alignItems: "stretch",
    },

    header: [
        theme.fonts.xxLarge,
        {
            flex: "1 1 auto",
            borderTop: `4px solid ${theme.palette.themePrimary}`,
            color: theme.palette.neutralPrimary,
            display: "flex",
            alignItems: "center",
            fontWeight: FontWeights.semibold,
            padding: "12px 12px 14px 24px",
        },
    ],

    body: {
        flex: "4 4 auto",
        padding: "0 24px 24px 24px",
        overflowY: "hidden",
        selectors: {
            p: { margin: "14px 0" },
            "p:first-child": { marginTop: 0 },
            "p:last-child": { marginBottom: 0 },
        },
        fontSize: "16px",
    },
});

const iconButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: "auto",
        marginTop: "4px",
        marginRight: "2px",
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};

const Certificates = () => {
    //   const { t, i18n } = useTranslation();

    const history = useHistory();
    const location = useLocation();

    const [data] = useState(location.state);

    const [companyId, setCompanyId] = useState('');

    const [certificatesRequest, setCertificatesRequest] = useState({});
    const [isLoadComplete, setIsLoadComplete] = useState(false);
    const [isCancelComplete, setIsCancelComplete] = useState(true);

    const [certificateCards, setCertificateCards] = useState([]);

    const [isModalOpen, { setFalse: hideModal }] = useBoolean(false);

    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});

    //   const companyId = sessionStorage.getItem('companyId') || '';
    // const companyId = data.companyId || "";

    const [dialogConfirm, setDialogConfirm] = useState({
        isClick: false,
        header: "",
        message: "",
        onConfirm: null,
        onCancel: null,
    });

    useEffect(() => {
        console.log('useEffect data call!: ', data);

        if (data !== undefined) {
            //console.log('useEffect data call! not undefied.');

            console.log('data: ', data);
            setCompanyId(data.companyId);
            

        } else {
            history.push({ pathname: '/accounts/prepaid', state: {} });
        }

    }, [data]);

    const handleCancel = () => {
        console.log("Click cancel!");

        setIsCancelComplete(false);

        instance
            .acquireTokenSilent({ ...silentRequest, account: account })
            .then((tokenResponse) => {
                console.log(tokenResponse);

                //Update certificates request
                axios
                    .put(
                        API_URL + "/certificate-requests/" + certificatesRequest.id,
                        {
                            period: certificatesRequest.period,
                            taxId: certificatesRequest.taxId,
                            type: certificatesRequest.type,
                            nameTH: certificatesRequest.nameTH,
                            nameEN: certificatesRequest.nameEN,
                            email: certificatesRequest.email,

                            identity: certificatesRequest.identity,
                            address: certificatesRequest.address,
                            postcode: certificatesRequest.postcode,
                            district: certificatesRequest.district,
                            province: certificatesRequest.province,
                            phone: certificatesRequest.phone,
                            mobile: certificatesRequest.mobile,

                            firstName: certificatesRequest.firstName,
                            lastName: certificatesRequest.lastName,
                            contactPhone: certificatesRequest.contactPhone,
                            contactMobile: certificatesRequest.contactMobile,
                            contactEmail: certificatesRequest.contactEmail,

                            ca: certificatesRequest.ca,
                            createdTime: certificatesRequest.createdTime,
                            updatedTime: certificatesRequest.updatedTime,
                            status: "canceled",

                            document1: certificatesRequest.document1,
                            document2: certificatesRequest.document2,
                            document3: certificatesRequest.document3,
                            document4: certificatesRequest.document4,
                            document5: certificatesRequest.document5,
                        },
                        {
                            headers: {
                                Authorization: "Bearer " + tokenResponse.accessToken,
                                cid: companyId,
                            },
                        }
                    )
                    .then(
                        (response) => {
                            console.log("Certificate requests update : ", response);

                            if (response.data) {
                                setCertificatesRequest(response.data);
                            }

                            setIsCancelComplete(true);
                        },
                        (error) => {
                            console.log(error);
                        }
                    );
            })
            .catch((error) => {
                //Acquire token silent failure, and send an interactive request
                console.log(error);
                if (error.errorMessage.indexOf("interaction_required") !== -1) {
                    instance.acquireTokenRedirect(silentRequest);
                }
            });
    };
    /* 
  useEffect(() => {
    console.log('userEffect initial Call!');

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        console.log(tokenResponse);
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {}, [certificatesRequest]);
 */
    const deleteCertificate = (certificateId) => {
        console.log("Delete certificate!");

        setIsLoadComplete(false);

        instance
            .acquireTokenSilent({ ...silentRequest, account: account })
            .then((tokenResponse) => {
                axios
                    .delete(ADMIN_API_URL + "/certificates/" + certificateId, {
                        headers: {
                            'Authorization': 'Bearer ' + tokenResponse.accessToken,
                            'User-Oid': data.oid,
                            'User-Cid': data.companyId
                        },
                    })
                    .then(
                        (response) => {
                            console.log("Delete certificate response: ", response);

                            getAllData();
                        },
                        (error) => {
                            console.log(error);
                        }
                    );
            })
            .catch((error) => {
                //Acquire token silent failure, and send an interactive request
                console.log(error);
                instance.acquireTokenRedirect(silentRequest);
            });
    };

    const getCertificates = (accessToken, certificateId) => {
        //Retrieve account
        axios
            .get(ADMIN_API_URL + "/certificates", {
                headers: {
                    'Authorization': 'Bearer ' + accessToken,
                    'User-Oid': data.oid,
                    'User-Cid': data.companyId
                },
            })
            .then(
                (response) => {
                    console.log("Account data: ", response);

                    if (response.data) {
                        console.log("Certificates: ", response.data);

                        let _cards = [];

                        response.data.forEach((item) => {
                            _cards.push(createCards(item, certificateId));
                        });

                        setIsLoadComplete(true);

                        setCertificateCards(_cards);
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
    };

    const getDefaultCertificateId = (accessToken) => {
        //Retrieve account
        axios
            // .get(API_URL + '/companies-details/' + companyId, {
            .get(ADMIN_API_URL + "/companies-details/" + data.companyId, {
                headers: {
                    'Authorization': 'Bearer ' + accessToken,
                    // 'User-Oid': data.oid,
                    // 'User-Cid': data.companyId
                },
            })
            .then((response) => {
                console.log("Companies details data: ", response);

                if (response.data && response.data.settings?.etax?.defaultCertificateId) {
                    getCertificates(
                        accessToken,
                        response.data.settings?.etax?.defaultCertificateId
                    );
                } else {
                    axios
                        .get(API_URL + "/accounts", {
                            headers: {
                                Authorization: "Bearer " + accessToken,
                            },
                        })
                        .then((accountsResponse) => {
                            console.log("Account data: ", accountsResponse);

                            if (accountsResponse.data) {
                                getCertificates(
                                    accessToken,
                                    accountsResponse.data.settings?.etax?.defaultCertificateId
                                );
                            }
                        })
                        .catch((accountsError) => {
                            console.log(accountsError);
                        });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const getCertificateRequest = (accessToken) => {
        //Retrieve certificates request
        axios
            .get(API_URL + "/certificate-requests/", {
                headers: {
                    Authorization: "Bearer " + accessToken,
                    cid: companyId,
                },
            })
            .then(
                (response) => {
                    console.log("Certificate requests : ", response);

                    if (response.data.length > 0) {
                        console.log("Certificates request data: ", response.data);

                        response.data.forEach((item, index) => {
                            if (
                                item.status === "created" ||
                                item.status === "uploaded" ||
                                item.status === "shipped"
                            ) {
                                setCertificatesRequest(item);
                            }
                        });
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
    };

    const createCards = (item, certificateId) => {
        return (
            <div key={item.id}>
                <div
                    style={{
                        width: "500px",
                        paddingLeft: "25px",
                        paddingRight: "25px",
                        paddingTop: "25px",
                        paddingBottom: "5px",
                        background: "#ffffff",
                        marginLeft: 0,
                        marginTop: 0,
                        borderRadius: "5px",
                        boxShadow: theme.effects.elevation4,
                    }}
                >
                    <Stack horizontal>
                        <h2 style={{ marginTop: "0px" }}>{item.certificateName}</h2>
                        {certificateId === item.id && (
                            <FontIcon iconName="SkypeCircleCheck" className={iconClass} />
                        )}
                    </Stack>

                    <Stack horizontal style={{ marginTop: "0px", marginBottom: "0px" }}>
                        <h4 style={{ marginTop: "0px" }}>
                            <Icon iconName="ContactCard" /> {"ซีเรียลนัมเบอร์:"}
                        </h4>
                        <span style={{ marginTop: "2px" }}>&nbsp;{item.serialNumber}</span>
                    </Stack>

                    {item.email && (
                        <Stack horizontal style={{ marginTop: "-7px", marginBottom: "0px" }}>
                            <h4 style={{ marginTop: "0px" }}>
                                <Icon iconName="Mail" /> {"อีเมล:"}
                            </h4>
                            <span style={{ marginTop: "2px" }}>&nbsp;{item.email}</span>
                        </Stack>
                    )}

                    <Stack horizontal style={{ marginTop: "-7px", marginBottom: "0px" }}>
                        <h4 style={{ marginTop: "0px" }}>
                            <Icon iconName="Bank" /> {"ออกให้โดย:"}
                        </h4>
                        <span style={{ marginTop: "2px" }}>&nbsp;{item.issuer.CN}</span>
                    </Stack>

                    <Stack horizontal horizontalAlign="space-between">
                        <Stack vertical>
                            <Stack horizontal style={{ marginTop: "-7px", marginBottom: "0px" }}>
                                <h4 style={{ marginTop: "0px" }}>
                                    <Icon iconName="DateTime" /> {"วันที่เริ่มต้น:"}
                                </h4>
                                <span style={{ marginTop: "2px" }}>
                                    &nbsp;
                                    {moment(item.validity.notBefore).format("DD/MM/YYYY  HH:mm:ss")}
                                </span>
                            </Stack>

                            <Stack horizontal style={{ marginTop: "-7px", marginBottom: "0px" }}>
                                <h4 style={{ marginTop: "0px" }}>
                                    <Icon iconName="DateTime2" /> {"วันที่หมดอายุ:"}
                                </h4>
                                <span style={{ marginTop: "2px" }}>
                                    &nbsp;
                                    {moment(item.validity.notAfter).format("DD/MM/YYYY  HH:mm:ss")}
                                </span>
                            </Stack>
                        </Stack>

                        {certificateId !== item.id && (
                            <Stack
                                vertical
                                verticalAlign="end"
                                style={{ marginBottom: "20px", marginRight: "0px" }}
                            >
                                <Stack horizontal>
                                    <DefaultButton
                                        text={"เลือกใช้"}
                                        onClick={() => clickSetDefault(item.id)}
                                    ></DefaultButton>
                                    &nbsp;
                                    <IconButton
                                        iconProps={{
                                            iconName: "Delete",

                                            styles: {
                                                root: {
                                                    fontSize: 26,
                                                    color: "black",
                                                    marginBottom: "0px",
                                                },
                                            },
                                        }}
                                        title={"ลบ"}
                                        className="certificates-delete-btn"
                                        onClick={
                                            () =>
                                                setDialogConfirm((prev) => ({
                                                    ...prev,
                                                    isClick: true,
                                                    header: "ลบใบรับรองอิเล็กทรอนิกส์",
                                                    message:
                                                        "กรุณายืนยัน การลบใบรับรองอิเล็กทรอนิกส์!",
                                                    onConfirm: () => {
                                                        deleteCertificate(item.id);
                                                        setDialogConfirm((prev) => ({
                                                            ...prev,
                                                            isClick: false,
                                                        }));
                                                    },
                                                    onCancel: () =>
                                                        setDialogConfirm((prev) => ({
                                                            ...prev,
                                                            isClick: false,
                                                        })),
                                                }))
                                            //   {
                                            //   deleteCertificate(item.id);
                                            // }
                                        }
                                    ></IconButton>
                                </Stack>
                            </Stack>
                        )}
                    </Stack>
                </div>
                <br />
                <br />
            </div>
        );
    };

    const clickSetDefault = (certificateId) => {
        setIsLoadComplete(false);

        console.log("companyId: ", companyId);
        console.log("Select id: ", certificateId);
        console.log("All cards: ", certificateCards);

        if (companyId) { 
          instance
              .acquireTokenSilent({ ...silentRequest, account: account })
              .then((tokenResponse) => {
                  console.log(tokenResponse);

                  let data = {
                      replace: "/settings/etax/defaultCertificateId",
                      value: certificateId,
                  };

                  axios
                      .patch(ADMIN_API_URL + "/companies-details/"  + companyId, data, {
                          headers: {
                              Authorization: "Bearer " + tokenResponse.accessToken,
                              // cid: companyId,
                          },
                      })
                      .then(
                          (response) => {
                              console.log("click set default: ", response);

                              getAllData();
                          },
                          (error) => {
                              console.log(error);
                          }
                      );
              })
              .catch((error) => {
                  //Acquire token silent failure, and send an interactive request
                  console.log(error);
                  instance.acquireTokenRedirect(silentRequest);
              });
        }
    };

    const getAllData = () => {
        instance
            .acquireTokenSilent({ ...silentRequest, account: account })
            .then((tokenResponse) => {
                console.log(tokenResponse);

                getDefaultCertificateId(tokenResponse.accessToken);

                // getCertificateRequest(tokenResponse.accessToken);
            })
            .catch((error) => {
                //Acquire token silent failure, and send an interactive request
                console.log(error);
                instance.acquireTokenRedirect(silentRequest);
            });
    };

    useEffect(() => {
        console.log("userEffect initial Call!");

        getAllData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companyId]);
    /* 
  useEffect(() => {
    setIsLoadComplete(false);
    getAllData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);
 */
    return (
        <Stack>
            <h1 style={{ marginLeft: "0px", marginTop: "25px" }}>
                <Icon iconName="Certificate" style={{ color: "#106ebe" }} />{" "}
                {"ใบรับรองอิเล็กทรอนิกส์"}
            </h1>
            {/* <br /> */}
            ชื่อบริษัท: {data.companyName}
            <br />
            ชื่อ: {data.firstName} {data.lastName}
            <br />
            อีเมล: {data.email}
            <br />
            <br />
            companyId: {data.companyId}
            <br />
            oid: {data.oid}
            <br />
            <br />
            <br />
            {isLoadComplete && (
                <Stack vertical verticalAlign="center">
                    {certificateCards}
                </Stack>
            )}
            {!isLoadComplete && (
                <Stack>
                    <Stack horizontal horizontalAlign="center">
                        <br />
                        <br />
                        <div className="certificates-loading-center">
                            <Spinner
                                label="กำลังโหลด..."
                                labelPosition="down"
                                styles={spinnerStyles}
                            />
                        </div>
                        <br />
                    </Stack>
                </Stack>
            )}
            <Stack horizontal style={centerItem} tokens={{ childrenGap: 30 }}>
                {(certificatesRequest.status === "created" ||
                    certificatesRequest.status === "uploaded" ||
                    certificatesRequest.status === "shipped") && (
                    <DefaultButton
                        text="กำลังโหลด..."
                        onClick={() =>
                            setDialogConfirm((prev) => ({
                                ...prev,
                                isClick: true,
                                header: "ยกเลิกการติดตั้งใบรับรองอิเล็กทรอนิกส์",
                                message: "กรุณายืนยัน การยกเลิกการติดตั้งใบรับรองอิเล็กทรอนิกส์",
                                onConfirm: () => {
                                    handleCancel();
                                    setDialogConfirm((prev) => ({
                                        ...prev,
                                        isClick: false,
                                    }));
                                },
                                onCancel: () =>
                                    setDialogConfirm((prev) => ({
                                        ...prev,
                                        isClick: false,
                                    })),
                            }))
                        }
                        disabled={certificatesRequest.status === "shipped" ? true : false}
                    />
                )}
                {isLoadComplete &&
                    certificatesRequest.status !== "created" &&
                    certificatesRequest.status !== "uploaded" &&
                    certificatesRequest.status !== "shipped" && (
                        <Stack>
                            <Stack horizontal horizontalAlign="center">
                                <PrimaryButton
                                    iconProps={{ iconName: "Add" }}
                                    name="add"
                                    text={"เพิ่มใบรับรอง"}
                                    onClick={() => {
                                        history.push({
                                            pathname: "/etax/certificates/add",
                                            state: {},
                                        });
                                    }}
                                />
                            </Stack>
                        </Stack>
                    )}
            </Stack>
            {certificatesRequest.status === "created" && (
                <Stack>
                    <br />
                    <br />

                    <h1 style={{ marginLeft: "0px", marginTop: "25px" }}>
                        <Icon iconName="ContactCardSettings" style={{ color: "#106ebe" }} />{" "}
                        {"ขั้นตอนการติดตั้ง ใบรับรองอิเล็กทรอนิกส์"}
                    </h1>

                    <h4>
                        {"* เจ้าหน้าที่จะส่ง แบบคำขอใบรับรองอิเล็กทรอนิกส์ ให้ภายใน 1-2 วันทำการ"}{" "}
                        <br />
                        {"หลังจากได้รับแบบคำขอใบรับรองฯทางอีเมลแล้ว ให้ดำเนินการตามขั้นตอนต่อไปนี้"}
                    </h4>

                    <div
                        className="ms-Grid"
                        dir="ltr"
                        style={{
                            width: 560,
                            paddingLeft: 30,
                            paddingRight: 30,
                            paddingTop: 20,
                            paddingBottom: 30,
                            background: "#ffffff",
                            margin: "0",
                            boxShadow: theme.effects.elevation4,
                        }}
                    >
                        <div className="ms-Grid-row">
                            <Stack horizontal>
                                <h2 style={{ marginTop: "0px" }}>
                                    {"1. เซ็นเอกสาร แบบคำขอใบรับรองอิเล็กทรอนิกส์"}
                                </h2>
                            </Stack>

                            <Stack horizontal style={{ marginTop: "-20px", marginBottom: "0px" }}>
                                <h4 style={{ marginTop: "0px" }}>
                                    <ul>
                                        <li>
                                            {
                                                "กรรมการผู้มีอำนาจลงนามเซ็นทุกหน้า พร้อมตราประทับ (ถ้ามี)"
                                            }
                                        </li>
                                        <li>
                                            {"แบบฟอร์มมอบอำนาจ กรรมการ + พยานเซ็น"} <br />
                                            {"พร้อมติดอากรแสตมป์ 30 บาท"}
                                        </li>
                                    </ul>
                                </h4>
                            </Stack>

                            <Stack horizontal>
                                <h2 style={{ marginTop: "0px" }}>{"2. เอกสารประกอบแบบคำขอ"}</h2>
                            </Stack>

                            <Stack horizontal style={{ marginTop: "-20px", marginBottom: "0px" }}>
                                <h4 style={{ marginTop: "0px" }}>
                                    <ul>
                                        <li>
                                            {"หนังสือรับรองบริษัท อายุไม่เกิน 3 เดือน"} <br />
                                            <u>{"กรรมการเซ็นทุกหน้า พร้อมตราประทับ (ถ้ามี)"}</u>
                                        </li>
                                        <li>
                                            {"สำเนาบัตรประชาชนกรรมการ"}{" "}
                                            <u>{"พร้อมเซ็นรับรองสำเนา"}</u>
                                        </li>
                                    </ul>
                                </h4>
                            </Stack>

                            <Stack horizontal>
                                <h2 style={{ marginTop: "0px" }}>
                                    {"3. ส่งเอกสารทางอีเมลเพื่อตรวจสอบความถูกต้อง"}
                                </h2>
                            </Stack>

                            <Stack horizontal style={{ marginTop: "-20px", marginBottom: "0px" }}>
                                <h4 style={{ marginTop: "0px" }}>
                                    <ul>
                                        <li>
                                            {
                                                "สแกนเอกสารในข้อ 1 และ ข้อ 2 แล้วส่งมาที่อีเมล support@leceipt.com"
                                            }
                                        </li>
                                    </ul>
                                </h4>
                            </Stack>

                            <Stack horizontal>
                                <h2 style={{ marginTop: "0px" }}>
                                    {"4. จัดส่งเอกสารตัวจริงทางไปรษณีย์"}
                                </h2>
                            </Stack>

                            <Stack horizontal style={{ marginTop: "-20px", marginBottom: "0px" }}>
                                <h4 style={{ marginTop: "0px" }}>
                                    <ul>
                                        <li>
                                            {
                                                "หลังจากตรวจสอบเอกสารทางอีเมลแล้ว จะแจ้งที่อยู่สำหรับจัดส่งเอกสารตัวจริง"
                                            }
                                        </li>
                                    </ul>
                                </h4>
                            </Stack>

                            <Stack horizontal></Stack>
                        </div>
                    </div>
                </Stack>
            )}
            <br />
            <br />
            <Dialog
                hidden={isCancelComplete}
                dialogContentProps={{
                    type: DialogType.largeHeader,
                    title: "กำลังดำเนินการ...",
                }}
            >
                <Spinner
                    label={"ระบบกำลังดำเนินการ..."}
                    labelPosition="down"
                    styles={spinnerStyles}
                />
            </Dialog>
            <Dialog
                hidden={!dialogConfirm.isClick}
                onDismiss={dialogConfirm.onCancel}
                styles={{ main: { minHeight: 0 } }}
                dialogContentProps={{
                    type: DialogType.largeHeader,
                    title: dialogConfirm.header,
                    styles: {
                        header: { textAlign: "center" },
                        title: { paddingRight: 24 },
                        subText: { textAlign: "center" },
                    },
                    subText: dialogConfirm.message,
                }}
            >
                <DialogFooter styles={{ actionsRight: { textAlign: "center" } }}>
                    <PrimaryButton onClick={dialogConfirm.onConfirm} text={"ยืนยัน"} />
                    <DefaultButton onClick={dialogConfirm.onCancel} text={"ยกเลิก"} />
                </DialogFooter>
            </Dialog>
            <Modal
                isOpen={isModalOpen}
                onDismiss={hideModal}
                isBlocking={false}
                containerClassName={contentStyles.container}
                dragOptions={true}
            >
                <div className={contentStyles.header}>
                    <span id={1}>{"ขั้นตอนที่ 2: ส่งเอกสารทางไปรษณีย์"}</span>
                    <IconButton
                        styles={iconButtonStyles}
                        iconProps={{ iconName: "Cancel" }}
                        onClick={hideModal}
                    />
                </div>

                <div className={contentStyles.body}>
                    <p>
                        {
                            "กรุณารอเจ้าหน้าที่โทรติดต่อท่าน ก่อนจัดส่งเอกสารทางไปรษณีย์ ปกติจะใช้เวลาประมาณ 1-2 วันทำการในการตรวจสอบเอกสาร"
                        }
                    </p>
                    <p>
                        {
                            "หากต้องการสอบถาม โทร. 082-579-8555 เวลา 8.30 - 17.30 น. (ทุกวัน จันทร์ - อาทิตย์)"
                        }
                    </p>
                    <br />
                    <u>{"ที่อยู่ในการจัดส่งเอกสาร"}</u>
                    <p>
                        {"ฝ่ายบริหารจัดการใบรับรองอิเล็กทรอนิกส์"}
                        <br />
                        {"บริษัท ฟรีเวชั่น จำกัด"}
                        <br />
                        {"682 หมู่ 1 ถ.สุขาภิบาล 1"}
                        <br />
                        {"ต.บรบือ อ.บรบือ จ.มหาสารคาม 44130"}
                        <br />
                        {"โทร. 082-579-8555"}
                    </p>
                    <p>
                        {
                            "หลังจากบริษัท ได้รับเอกสารทางไปรษณีย์แล้ว จะดำเนินการขอใบรับรองอิเล็กทรอนิกส์ให้ท่าน ปกติบริษัทที่มีหน้าที่ออกใบรับรองอิเล็กทรอนิกส์ (CA) จะใช้เวลาดำเนินการประมาณ 3-5 วันทำการ"
                        }
                    </p>
                    <p>
                        {
                            "ท่านสามารถโทรสอบถามสถานะการดำเนินงานได้ตลอด ที่เบอร์ 082-579-8555 เวลา 8.30 - 17.30 น. (ทุกวัน จันทร์ - อาทิตย์)"
                        }
                    </p>
                    <br />
                </div>
            </Modal>
        </Stack>
    );
};

export default Certificates;
