import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';

import { useMsal, useAccount } from "@azure/msal-react";
import { silentRequest } from "../../../authConfig";

import { useHistory, useLocation } from 'react-router-dom';



import { 
    Stack,
    Icon,
    PrimaryButton,
    DefaultButton,
    TextField,
    FocusTrapZone,
    Spinner,
    getTheme,
    MessageBar,
    MessageBarType,
    DatePicker,
    Dropdown
} from '@fluentui/react';

import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';



var forge = require('node-forge');

// const API_URL = process.env.REACT_APP_API_URL;
const ADMIN_API_URL = process.env.REACT_APP_ADMIN_API_URL;

const theme = getTheme();

//Dropzone Style

const getColor = (props) => {
    if (props.isDragAccept) {
        return '#00e676';
    }
    if (props.isDragReject) {
        return '#ff1744';
    }
    if (props.isDragActive) {
        return '#2196f3';
    }
    //return '#eeeeee';
    //return 'rgb(194, 194, 194)';
    return 'rgb(134, 134, 134)';
}

const Container = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: ${props => getColor(props)};
    border-style: dashed;    
    background-color: #ffffff;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
    margin-top: 5px;
`;

// Processing dialog style
const spinnerStyles = {
    circle: {
      height: 24,
      width: 24,
      borderWidth: 4
    }
}

const cardStyle = {
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 20,
    paddingBottom: 30,
    background: '#ffffff',
    marginLeft: '0',
    marginTop: '10px',
    boxShadow: theme.effects.elevation4,
};

const homeStyles = {
    border: 0,
    margin: 0,
    width: '100%',
    background: '#FAF9F8',
    position: 'relative',
};

const toThaiDateString = (isoDateTime) => {
    let date = "";
    date = new Date(isoDateTime);

    //console.log(dateTime.toLocaleDateString() + ' ' + dateTime.toLocaleTimeString());
    /* 
  let monthNames = [
    "มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน",
    "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม",
    "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"
  ];
*/
    let year = date.getFullYear() + 543;
    //let month = monthNames[date.getMonth()];
    let month = (date.getMonth() + 1).toString().padStart(2, "0");
    let numOfDay = date.getDate().toString().padStart(2, "0");

    let hour = date.getHours().toString().padStart(2, "0");
    let minutes = date.getMinutes().toString().padStart(2, "0");
    let second = date.getSeconds().toString().padStart(2, "0");

    return `${numOfDay}/${month}/${year} ${hour}:${minutes}:${second} น.`;
};

const onFormatDate = (date, format) => {
    if (format === 'CE') {
      return `${date.getDate()}`.padStart(2, '0') + '/' + `${date.getMonth() + 1}`.padStart(2, '0') + '/' + date.getFullYear();
    } else {
      return `${date.getDate()}`.padStart(2, '0') + '/' + `${date.getMonth() + 1}`.padStart(2, '0') + '/' + (date.getFullYear() + 543);
    }
  };

  const DayPickerStrings = {
    months: [
      "มกราคม",
      "กุมภาพันธ์",
      "มีนาคม",
      "เมษายน",
      "พฤษภาคม",
      "มิถุนายน",
      "กรกฎาคม",
      "สิงหาคม",
      "กันยายน",
      "ตุลาคม",
      "พฤศจิกายน",
      "ธันวาคม",
    ],

    shortMonths: [
      'ม.ค.',
      'ก.พ.',
      'มี.ค.',
      'เม.ย.',
      'พ.ค.',
      'มิ.ย.',
      'ก.ค.',
      'ส.ค.',
      'ก.ย.',
      'ต.ค.',
      'พ.ย.',
      'ธ.ค.',
    ],

    days: ['อาทิตย์', 'จันทร์', 'อังคาร', 'พุธ', 'พฤหัสบดี', 'ศุกร์', 'เสาร์'],

    shortDays: ['อา.', 'จ.', 'อ.', 'พ.', 'พฤ.', 'ศ.', 'ส.'],

    goToToday: 'ไปวันที่ปัจจุบัน',
    prevMonthAriaLabel: 'ไปเดือนที่แล้ว',
    nextMonthAriaLabel: 'ไปเดือนถัดไป',
    prevYearAriaLabel: 'ไปปีที่แล้ว',
    nextYearAriaLabel: 'ไปปีถัดไป',
    closeButtonAriaLabel: 'ปิด',
    monthPickerHeaderAriaLabel: '{0}, เลือกเพื่อเปลี่ยนปี',
    yearPickerHeaderAriaLabel: '{0}, เลือกเพื่อเปลี่ยนเดือน',

    isRequiredErrorMessage: 'กรุณาเลือกวันที่',


    invalidInputErrorMessage: 'รูปแบบวันที่ไม่ถูกต้อง',
  };

  const packagesList = {
    LECEIPT_MONTH_300: { 
      package_name: "ค่า Subscription ซอฟต์แวร์ Leceipt รายเดือน (300 เอกสาร/เดือน หากเกินจ่ายเพิ่มเอกสารละ 8 บาท)",
      price: 2999,
      price_per_page: 8,
      documents: 300,
      sms_price: 2,
      email_price: 0
    },
    LECEIPT_MONTH_500: { 
      package_name: "ค่า Subscription ซอฟต์แวร์ Leceipt รายเดือน (500 เอกสาร/เดือน หากเกินจ่ายเพิ่มเอกสารละ 7 บาท)",
      price: 3499,
      price_per_page: 7,
      documents: 500,
      sms_price: 2,
      email_price: 0
    },
    LECEIPT_MONTH_1000: { 
      package_name: "ค่า Subscription ซอฟต์แวร์ Leceipt รายเดือน (1,000 เอกสาร/เดือน หากเกินจ่ายเพิ่มเอกสารละ 5 บาท)",
      price: 4999,
      price_per_page: 5,
      documents: 1000,
      sms_price: 2,
      email_price: 0
    },
    LECEIPT_MONTH_3000: { 
      package_name: "ค่า Subscription ซอฟต์แวร์ Leceipt รายเดือน (3,000 เอกสาร/เดือน หากเกินจ่ายเพิ่มเอกสารละ 3 บาท)",
      price: 8999,
      price_per_page: 3,
      documents: 3000,
      sms_price: 2,
      email_price: 0
    },
    LECEIPT_MONTH_10000: { 
      package_name: "ค่า Subscription ซอฟต์แวร์ Leceipt รายเดือน (10,000 เอกสาร/เดือน หากเกินจ่ายเพิ่มเอกสารละ 2 บาท)",
      price: 19999,
      price_per_page: 2,
      documents: 10000,
      sms_price: 2,
      email_price: 0
    },
    LECEIPT_YEAR_300: { 
      package_name: "ค่า Subscription ซอฟต์แวร์ Leceipt รายปี (300 เอกสาร/เดือน หากเกินจ่ายเพิ่มเอกสารละ 8 บาท)",
      price: 29990,
      price_per_page: 8,
      documents: 300,
      sms_price: 2,
      email_price: 0
    },
    LECEIPT_YEAR_500: { 
      package_name: "ค่า Subscription ซอฟต์แวร์ Leceipt รายปี (500 เอกสาร/เดือน หากเกินจ่ายเพิ่มเอกสารละ 7 บาท)",
      price: 34990,
      price_per_page: 7,
      documents: 500,
      sms_price: 2,
      email_price: 0
    },
    LECEIPT_YEAR_1000: { 
      package_name: "ค่า Subscription ซอฟต์แวร์ Leceipt รายปี (1,000 เอกสาร/เดือน หากเกินจ่ายเพิ่มเอกสารละ 5 บาท)",
      price: 49990,
      price_per_page: 5,
      documents: 1000,
      sms_price: 2,
      email_price: 0
    },
    LECEIPT_YEAR_3000: { 
      package_name: "ค่า Subscription ซอฟต์แวร์ Leceipt รายปี (3,000 เอกสาร/เดือน หากเกินจ่ายเพิ่มเอกสารละ 3 บาท)",
      price: 89990,
      price_per_page: 3,
      documents: 3000,
      sms_price: 2,
      email_price: 0
    },
    LECEIPT_YEAR_10000: { 
      package_name: "ค่า Subscription ซอฟต์แวร์ Leceipt รายปี (10,000 เอกสาร/เดือน หากเกินจ่ายเพิ่มเอกสารละ 2 บาท)",
      price: 199990,
      price_per_page: 2,
      documents: 10000,
      sms_price: 2,
      email_price: 0
    },
  }

const packagesOptions = [
    {
      key: "",
      text: "ไม่ระบุ"
    },
    {
      key: "LECEIPT_MONTH_300",
      text: "รายเดือน 300 เอกสาร/เดือน"
    },
    {
      key: "LECEIPT_MONTH_500",
      text: "รายเดือน 500 เอกสาร/เดือน"
    },
    {
      key: "LECEIPT_MONTH_1000",
      text: "รายเดือน 1000 เอกสาร/เดือน"
    },
    {
      key: "LECEIPT_MONTH_3000",
      text: "รายเดือน 3000 เอกสาร/เดือน"
    },
    {
      key: "LECEIPT_MONTH_10000",
      text: "รายเดือน 10000 เอกสาร/เดือน"
    },
    {
      key: "LECEIPT_YEAR_300",
      text: "รายปี 300 เอกสาร/เดือน"
    },
    {
      key: "LECEIPT_YEAR_500",
      text: "รายปี 500 เอกสาร/เดือน"
    },
    {
      key: "LECEIPT_YEAR_1000",
      text: "รายปี 1,000 เอกสาร/เดือน"
    },
    {
      key: "LECEIPT_YEAR_3000",
      text: "รายปี 3,000 เอกสาร/เดือน"
    },
    {
      key: "LECEIPT_YEAR_10000",
      text: "รายปี 10,000 เอกสาร/เดือน"
    },
  ]

  const billingTypeOptions = [
    {
      key: "prepaid",
      text: "Prepaid"
    },
    {
      key: "subscription",
      text: "Subscription"
    },
  ]

  function getMonthDifference(startDate, endDate) {
    // Calculate the difference in months
    let startYear = startDate.getFullYear();
    let startMonth = startDate.getMonth();
    let endYear = endDate.getFullYear();
    let endMonth = endDate.getMonth();
    
    let months = (endYear - startYear) * 12 + (endMonth - startMonth);
    
    return months;
}

const UpdateSupscription = () => {

    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});

    const history = useHistory();    
    const location = useLocation();

    const [data] = useState(location?.state);

    const [companyId, setCompanyId] = useState('');
    const [credits, setCredits] = useState(0);
    const [topupAmount, setTopupAmount] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [lastTopup, setLastTopup] = useState(0);
    const [lastTopupTime, setLastTopupTime] = useState('');

    const [isLoading, setIsLoading] = useState(false);

    const [showTopupSuccess, setShowUpdateSubscriptionSuccess] = useState(false);
    const [showTopupError, setShowUpdateSubscriptionError] = useState(false);

    const [billingTypeKey, setBillingTypeKey] = useState("")
    const [packageId, setPackageId] = useState("")
    const [fromDate, setFromDate] = useState(null)
    const [toDate, setToDate] = useState(null)

    const [currentSubscriptions, setCurrentSubscriptions] = useState(null)
    const [currentBillingType, setCurrentBillingType] = useState("prepaid")

    const [invalidToDate, setInvalidToDate] = useState(false)
    const [showPackageError, setShowPackageError] = useState(false)
    const [showFromDateError, setShowFromDateError] = useState(false)
    const [showToDateError, setShowToDateError] = useState(false)

    useEffect(() => {
        console.log('useEffect data call!: ', data);

        if (data !== undefined) {
            //console.log('useEffect data call! not undefied.');

            setIsLoading(true)

            console.log('data: ', data);
            setCompanyId(data.companyId);
            // setCredits(data.documentsCredits);
            // setLastTopup(data.documentsLastTopup);
            // setLastTopupTime(data.documentsLastTopupTime);
            // setTotalAmount(data.documentsCredits + topupAmount);

            setBillingTypeKey(data.companiesDetails?.billings?.type || "prepaid")
            setCurrentBillingType(data.companiesDetails?.billings?.type || "prepaid")

            instance
            .acquireTokenSilent({ ...silentRequest, account: account })
            .then(async (tokenResponse) => {
                const [
                    accountDetail, 
                  ] = await Promise.all([
                                          axios.get(`${ADMIN_API_URL}/companies-details/${data.companyId}`, {
                                            headers: {
                                                Authorization: 'Bearer ' + tokenResponse.accessToken,
                                                // 'cid': cid
                                            },
                                          }),
                                        ])
                
                console.log("accountDetail.data", accountDetail.data)
                const _currentSubscriptions = accountDetail.data.subscriptions

                setCurrentSubscriptions(_currentSubscriptions)

                if (_currentSubscriptions.from) {
                  setFromDate(new Date(_currentSubscriptions.from))     

                }

                if (_currentSubscriptions.to) {
                  setToDate(new Date(_currentSubscriptions.to))

                }
                setPackageId(_currentSubscriptions.plan.id)                  

                setIsLoading(false)
                
            }).catch((error) => {
                //Acquire token silent failure, and send an interactive request
                console.log(error);
                instance.acquireTokenRedirect(silentRequest);
            });
            



        } else {
            history.push({ pathname: '/accounts/prepaid', state: {} });
        }

    }, [data]);

          
    

    const clearData = () => {
        setIsLoading(false);
        // setUploadStatus('');

    }

    const handleSubmit = (e) => {
      e.preventDefault();   

      if (billingTypeKey === "prepaid") {
        setIsLoading(true)

        instance
        .acquireTokenSilent({ ...silentRequest, account: account })
        .then(async (tokenResponse) => {
          const updateBillingResponse = await axios
                                        .patch(`${ADMIN_API_URL}/companies-details/${data.companyId}`,
                                          {
                                            replace: '/billings/type',
                                            value: billingTypeKey,
                                          },
                                          {
                                            headers: {
                                              Authorization: 'Bearer ' + tokenResponse.accessToken,
                                            //   'cid': cid,
                                            },
                                          }
                                        ).then(response => {
                                          setIsLoading(false)

                                          setShowUpdateSubscriptionSuccess(true);
                                          window.scrollTo(0, 0);

                                          return response
                                        })

          setCurrentBillingType(updateBillingResponse.data.billings.type)
          
        }).catch((error) => {
          //Acquire token silent failure, and send an interactive request
          console.log(error);
          instance.acquireTokenRedirect(silentRequest);

          setShowUpdateSubscriptionError(true);
          window.scrollTo(0, 0);
        });
      } else {
        if (!packageId) {
            setShowPackageError(true)

            return;
        }

        if (!fromDate) {
            setShowFromDateError(true)

            return;
        }

        if (!toDate) {
            setShowToDateError(true)

            return;
        }

        if (!invalidToDate) {
            setIsLoading(true)

            instance
            .acquireTokenSilent({ ...silentRequest, account: account })
            .then(async (tokenResponse) => {
                const [
                    accountDetail, 
                ] = await Promise.all([
                                        axios.get(`${ADMIN_API_URL}/companies-details/${data.companyId}`, {
                                        headers: {
                                            Authorization: 'Bearer ' + tokenResponse.accessToken,
                                            // 'cid': cid
                                        },
                                        }),
                                    ])
    
                const _currentSubscriptions = accountDetail.data.subscriptions
    
                const _newSubscriptions = {..._currentSubscriptions}
    
                _newSubscriptions.from = fromDate.toISOString()
                _newSubscriptions.to = toDate.toISOString()
    
                _newSubscriptions.plan = {
                    "id": packageId,
                    "name": packagesList[packageId].package_name,
                    "type": "paid",
                    "price": packagesList[packageId].price,
                    "documents": packagesList[packageId].documents
                }
    
                if (packageId.includes("YEAR")) {
                    _newSubscriptions.reset = {
                        "left": 11,
                        "total": 12
                    }
                } else {
                    _newSubscriptions.reset = {
                        "left": 0,
                        "total": 1
                    }
                }
    
                const [
                  updateSubscriptionsResponse, 
                  updateBillingResponse
                ] = await Promise.all([
                                        axios
                                        .patch(`${ADMIN_API_URL}/companies-details/${data.companyId}`,
                                          {
                                            replace: '/subscriptions',
                                            value: _newSubscriptions,
                                          },
                                          {
                                            headers: {
                                              Authorization: 'Bearer ' + tokenResponse.accessToken,
                                            //   'cid': cid,
                                            },
                                          }
                                        ),
                                        axios
                                        .patch(`${ADMIN_API_URL}/companies-details/${data.companyId}`,
                                          {
                                            replace: '/billings/type',
                                            value: billingTypeKey,
                                          },
                                          {
                                            headers: {
                                              Authorization: 'Bearer ' + tokenResponse.accessToken,
                                            //   'cid': cid,
                                            },
                                          }
                                        )
                                      ])

                // const updateSubscriptionsResponse = await axios
                //                             .patch(`${ADMIN_API_URL}/companies-details/${data.companyId}`,
                //                               {
                //                                 replace: '/subscriptions',
                //                                 value: _newSubscriptions,
                //                               },
                //                               {
                //                                 headers: {
                //                                   Authorization: 'Bearer ' + tokenResponse.accessToken,
                //                                 //   'cid': cid,
                //                                 },
                //                               }
                //                             ).then(response => {
                //                                 setIsLoading(false)

                //                                 setShowUpdateSubscriptionSuccess(true);
                //                                 window.scrollTo(0, 0);
    
                //                                 return response
                //                             })

                setIsLoading(false)

                setShowUpdateSubscriptionSuccess(true);
                window.scrollTo(0, 0);
    
                console.log("updateSubscriptionsResponse", updateSubscriptionsResponse)
                const _updatedSubscriptions = updateSubscriptionsResponse.data.subscriptions
                
                setCurrentSubscriptions(_updatedSubscriptions)
                setCurrentBillingType(updateBillingResponse.data.billings.type)

            }).catch((error) => {
                //Acquire token silent failure, and send an interactive request
                console.log(error);
                instance.acquireTokenRedirect(silentRequest);

                setShowUpdateSubscriptionError(true);
                window.scrollTo(0, 0);
            });
        }
      }
    }

    useEffect(() => {
      setInvalidToDate(false)

      if (fromDate && toDate && packageId) {
        const monthDifference = getMonthDifference(fromDate, toDate)

        if (packageId.includes("YEAR")) {
            if (monthDifference < 12) {
                setInvalidToDate(true)
            }
        } else {
            if (monthDifference > 1) {
                setInvalidToDate(true)
            }
        }
      }
    }, [fromDate, toDate, packageId])

    function currentPackage(_currentSubscriptions) {
        if (_currentSubscriptions?.plan?.id) {
            return packagesList[_currentSubscriptions?.plan?.id]?.package_name

        }

        return "-"
    }

    function currentPeriod(_currentSubscriptions) {
        if (_currentSubscriptions?.from && _currentSubscriptions?.to) {
            return `${onFormatDate(new Date(_currentSubscriptions.from), "BE")} - ${onFormatDate(new Date(_currentSubscriptions.to), "BE")}`

        }

        return "-"
    }
    

    return (
        <>
        <div style={homeStyles}>
        
            {showTopupSuccess && (
            <MessageBar
                messageBarType={MessageBarType.success}
                isMultiline={false}
                onDismiss={() => setShowUpdateSubscriptionSuccess(false)}
                dismissButtonAriaLabel="Close"
                style={{
                    fontSize: 14,
                  }}
            >
                อัพเดต Subscription สำเร็จ!
            </MessageBar>
            )}
            {showTopupError && (
            <MessageBar
                messageBarType={MessageBarType.error}
                isMultiline={false}
                onDismiss={() => setShowUpdateSubscriptionError(false)}
                dismissButtonAriaLabel="Close"
                style={{
                    fontSize: 14,
                  }}

            >
                อัพเดต Subscription ไม่สำเร็จ!
            </MessageBar>
            )}
           <Stack horizontal horizontalAlign="center">
        <Stack 
            vertical
            style={{
              width: '80%',
              maxWidth: '500px',
            }}>
                <h1 style={{ marginLeft: '0px', marginTop: '27px' }} ><Icon iconName="AccountManagement" style={{ color: '#106ebe'}} /> รายละเอียดบัญชี</h1>
                {/* <h3 style={{ marginTop: '0px', marginBottom: '10px' }} >{data.companyName}</h3> */}
                {/* <br/> */}
                ชื่อบริษัท: {data.companyName}
                <br/>
                ชื่อ: {data.firstName} {data.lastName}
                <br/>
                อีเมล: {data.email}
                <br/>
                companyId: {data.companyId}                                
                <br/>
                <br/>
                oid: {data.oid}                                
                <br/>
                <br/>
                {isLoading ? (
                  <Stack horizontalAlign='center' styles={{ root: { width: "100%"}}}>
                    <Spinner label="กำลังโหลด..." labelPosition="down" styles={spinnerStyles} />

                  </Stack>
                ) : (
                  currentBillingType === "subscription" ? (
                    <>
                      ประเภทบัญชี: Subscription
                      <br/>
                      <br/>
                      แพ็กเกจปัจจุบัน: {currentPackage(currentSubscriptions)}
                      <br/>
                      <br/>
                      รอบบิลล่าสุด: {currentPeriod(currentSubscriptions)} 
                    </>
                  ) : (
                    <>
                    ประเภทบัญชี: Prepaid
                    </>
                  )
                )}
                
                <br/>
                <br/>

               
                <div style={cardStyle}>
                    <Stack >
                        <Stack horizontalAlign="start">
                            <Dropdown
                                placeholder="เลือกประเภทบัญชี"
                                label="ประเภทบัญชี"
                                // options={Object.keys(packagesList).map(_key => ({ key: _key, text: packagesList[_key].package_name_abb }))}
                                options={billingTypeOptions}
                                styles={{ root: { width: "100%" } }}
                                selectedKey={billingTypeKey}
                                onChange={(e, selectedOption) => {
                                    setBillingTypeKey(selectedOption.key);
                                }}
                                required
                                disabled={isLoading}
                            />
                            {billingTypeKey === "subscription" ? (
                              <>
                                <Dropdown
                                    placeholder="เลือกแพ็กเกจ"
                                    label="แพ็กเกจ"
                                    // options={Object.keys(packagesList).map(_key => ({ key: _key, text: packagesList[_key].package_name_abb }))}
                                    options={packagesOptions}
                                    styles={{ root: { width: "100%" } }}
                                    selectedKey={packageId}
                                    onChange={(e, selectedOption) => {
                                        setPackageId(selectedOption.key);
                                        setShowPackageError(false)
                                    }}
                                    errorMessage={showPackageError ? "กรุณาเลือก" : ""}
                                    required
                                    disabled={isLoading}
                                />
                                <div style={{ width: "100%"}}>
                                    <DatePicker
                                        id='fromDate'
                                        label='วันที่เริ่ม'
                                        styles={{ root: { width: '100%' } }}
                                        placeholder="เลือก"
                                        strings={DayPickerStrings}
                                        value={fromDate}
                                        maxDate={toDate}
                                        formatDate={onFormatDate}
                                        onSelectDate={(_date) => {
                                            setFromDate(_date)
                                            setShowFromDateError(false)
                                            // setDialogCreateStatement((prev) => ({
                                            //     ...prev,
                                            //     fromDate: _date
                                            // }))

                                            // setFromDate(_date);

                                            // setCurrentPeriod(false)
                                            // setTimeRangeError(false);
                                            // if (Math.ceil(Math.abs(_date - toDate) / (1000 * 60 * 60 * 24)) > 30) setTimeRangeError(true);
                                        }}
                                        disabled={isLoading}
                                    />
                                    {showFromDateError && (
                                        <p
                                            style={{
                                            marginLeft: 0,
                                            marginTop: 5,
                                            marginBottom: 0,
                                            color: '#a4262c',
                                            fontSize: 12,
                                            fontWeight: 400,
                                            fontFamily:
                                                '"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif',
                                            }}
                                        >
                                            กรุณาเลือก
                                        </p>
                                    )}
                                </div>
                                <div style={{ width: "100%"}}>
                                    <DatePicker
                                        id='toDate'
                                        styles={{ root: { width: '100%' } }}
                                        label="วันที่สิ้นสุด"
                                        placeholder="เลือก"
                                        strings={DayPickerStrings}
                                        value={toDate}
                                        minDate={fromDate}
                                        formatDate={onFormatDate}
                                        onSelectDate={(_date) => {
                                            setToDate(_date)
                                            setShowToDateError(false)
                                            // setDialogCreateStatement((prev) => ({
                                            //     ...prev,
                                            //     toDate: _date
                                            // }))

                                            // setToDate(_date);

                                            // setCurrentPeriod(false)
                                            // setTimeRangeError(false);
                                            // if (Math.ceil(Math.abs(_date - toDate) / (1000 * 60 * 60 * 24)) > 30) setTimeRangeError(true);
                                        }}
                                        disabled={isLoading}
                                    />
                                    {showToDateError && (
                                        <p
                                            style={{
                                            marginLeft: 0,
                                            marginTop: 5,
                                            marginBottom: 0,
                                            color: '#a4262c',
                                            fontSize: 12,
                                            fontWeight: 400,
                                            fontFamily:
                                                '"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif',
                                            }}
                                        >
                                            กรุณาเลือก
                                        </p>
                                    )}
                                    {invalidToDate && (
                                        <p
                                            style={{
                                            marginLeft: 0,
                                            marginTop: 5,
                                            marginBottom: 0,
                                            color: '#a4262c',
                                            fontSize: 12,
                                            fontWeight: 400,
                                            fontFamily:
                                                '"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif',
                                            }}
                                        >
                                            วันที่สิ้นสุดไม่ถูกต้อง
                                        </p>
                                    )}
                                </div>
                              </>
                            ) : null}
                            {/* <TextField
                                label="เติมเครดิตจำนวน"
                                type="number"
                                style={{ width: "150px" }}
                                disabled={isLoading}
                                required
                                onChange={ e => {
                                    setTopupAmount(e.target.value*1);
                                    setTotalAmount((e.target.value*1) + credits);
                                } } 
                                value={topupAmount}
                                // errorMessage={passwordError}
                            /> */}

                        </Stack>
                        {/* <br/>
                        <h3>เครดิตหลังเติม: {totalAmount.toLocaleString('en-US', {minimumFractionDigits: 0})} เอกสาร</h3> */}
                        <br/>
                        <Stack horizontal horizontalAlign="space-around">
                            {!isLoading &&
                                <PrimaryButton 
                                    style={{ fontSize: "20px", height: "50px", width: "150px" }} 
                                    onClick={handleSubmit}
                                    text="อัปเดท"/>
                            }
                            {!isLoading &&
                            
                                <DefaultButton 
                                    style={{ fontSize: "20px", height: "50px" }}
                                    onClick={ () => {
                                        //setClickAdd(true) 

                                        history.push({
                                            pathname: '/accounts/prepaid',
                                            //search: '?update=true',  // query string
                                            state: {}
                                        })
                                    } 
                                }
                                >
                                    ย้อนกลับ
                                </DefaultButton>
                            }
                            {isLoading &&
                                <Spinner /* size={SpinnerSize.large} */ label="กำลังดำเนินการ..." labelPosition="down" styles={spinnerStyles} />

                            }
                        </Stack>

                        
                    </Stack>
                </div> 
               
        </Stack>
        </Stack>
</div>
    </>
    );

}

export default UpdateSupscription;