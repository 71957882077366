import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';

import { useMsal, useAccount } from "@azure/msal-react";
import { silentRequest } from "../../../../../authConfig";

import { useHistory, useLocation } from 'react-router-dom';



import { 
    Stack,
    Icon,
    PrimaryButton,
    DefaultButton,
    TextField,
    FocusTrapZone,
    Spinner,
    getTheme,
    MessageBar,
    MessageBarType
} from '@fluentui/react';

import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';



var forge = require('node-forge');

// const API_URL = process.env.REACT_APP_API_URL;
const ADMIN_API_URL = process.env.REACT_APP_ADMIN_API_URL;

const theme = getTheme();

//Dropzone Style

const getColor = (props) => {
    if (props.isDragAccept) {
        return '#00e676';
    }
    if (props.isDragReject) {
        return '#ff1744';
    }
    if (props.isDragActive) {
        return '#2196f3';
    }
    //return '#eeeeee';
    //return 'rgb(194, 194, 194)';
    return 'rgb(134, 134, 134)';
}

const Container = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: ${props => getColor(props)};
    border-style: dashed;    
    background-color: #ffffff;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
    margin-top: 5px;
`;

// Processing dialog style
const spinnerStyles = {
    circle: {
      height: 24,
      width: 24,
      borderWidth: 4
    }
}

const cardStyle = {
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 20,
    paddingBottom: 30,
    background: '#ffffff',
    marginLeft: '0',
    marginTop: '10px',
    boxShadow: theme.effects.elevation4,
};

const homeStyles = {
    border: 0,
    margin: 0,
    width: '100%',
    background: '#FAF9F8',
    position: 'relative',
};

const toThaiDateString = (isoDateTime) => {
    let date = "";
    date = new Date(isoDateTime);

    //console.log(dateTime.toLocaleDateString() + ' ' + dateTime.toLocaleTimeString());
    /* 
  let monthNames = [
    "มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน",
    "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม",
    "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"
  ];
*/
    let year = date.getFullYear() + 543;
    //let month = monthNames[date.getMonth()];
    let month = (date.getMonth() + 1).toString().padStart(2, "0");
    let numOfDay = date.getDate().toString().padStart(2, "0");

    let hour = date.getHours().toString().padStart(2, "0");
    let minutes = date.getMinutes().toString().padStart(2, "0");
    let second = date.getSeconds().toString().padStart(2, "0");

    return `${numOfDay}/${month}/${year} ${hour}:${minutes}:${second} น.`;
};

const DocumentsCreditsAdd = () => {

    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});

    const history = useHistory();    
    const location = useLocation();

    const [data] = useState(location.state);

    const [companyId, setCompanyId] = useState('');
    const [credits, setCredits] = useState(0);
    const [topupAmount, setTopupAmount] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [lastTopup, setLastTopup] = useState(0);
    const [lastTopupTime, setLastTopupTime] = useState('');

    const [isLoading, setIsLoading] = useState(false);

    const [showTopupSuccess, setShowTopupSuccess] = useState(false);
    const [showTopupError, setShowTopupError] = useState(false);

    useEffect(() => {
        console.log('useEffect data call!: ', data);

        if (data !== undefined) {
            //console.log('useEffect data call! not undefied.');

            console.log('data: ', data);
            setCompanyId(data.companyId);
            setCredits(data.documentsCredits);
            setLastTopup(data.documentsLastTopup);
            setLastTopupTime(data.documentsLastTopupTime);
            setTotalAmount(data.documentsCredits + topupAmount);



        } else {
            history.push({ pathname: '/accounts/prepaid', state: {} });
        }

    }, [data]);

          
    

    const clearData = () => {
        setIsLoading(false);
        // setUploadStatus('');

    }

    const handleSubmit = (e) => {

        e.preventDefault();   

        console.log('companyId: ', companyId);
        console.log('topupAmount: ', topupAmount);
        console.log('totalAmount: ', totalAmount);

        setIsLoading(true);

  
        instance
        .acquireTokenSilent({ ...silentRequest, account: account })
        .then((tokenResponse) => {
            axios
              .patch(
                ADMIN_API_URL + '/companies-details/' + companyId,
                {
                  replace: '/balances/documents/lastTopup',
                  value: topupAmount
                },
                {
                  headers: {
                    Authorization: 'Bearer ' + tokenResponse.accessToken,
                    'Content-Type': 'application/json',
                  },
                }
              )
              .then(
                (response) => {
                  //console.log(response.data.id);
                  console.log(response);
                  setIsLoading(false);
                  setShowTopupSuccess(true);
                  setTopupAmount(0);
                  window.scrollTo(0, 0);

                  if (response.data.balances) {
                      setCredits(response.data.balances.documents.credits);                      
                      setLastTopup(response.data.balances.documents.lastTopup);
                      setLastTopupTime(toThaiDateString(response.data.balances.documents.lastTopupTime));
                      setTotalAmount(response.data.balances.documents.credits);

                  }
                },
                (error) => {
                  console.log(error);
                  setIsLoading(false);
                  setShowTopupError(true);
                  setTopupAmount(0);
                  window.scrollTo(0, 0);
                }
              );
          
        })
        .catch((error) => {
       
          //Acquire token silent failure, and send an interactive request
          console.log(error);
          instance.acquireTokenRedirect(silentRequest);

        });

    }

    return (
        <>
        <div style={homeStyles}>
        
            {showTopupSuccess && (
            <MessageBar
                messageBarType={MessageBarType.success}
                isMultiline={false}
                onDismiss={() => setShowTopupSuccess(false)}
                dismissButtonAriaLabel="Close"
                style={{
                    fontSize: 14,
                  }}
            >
                เติมเครดิตสำเร็จ!
            </MessageBar>
            )}
            {showTopupError && (
            <MessageBar
                messageBarType={MessageBarType.error}
                isMultiline={false}
                onDismiss={() => setShowTopupError(false)}
                dismissButtonAriaLabel="Close"
                style={{
                    fontSize: 14,
                  }}

            >
                เติมเครดิตไม่สำเร็จ!
            </MessageBar>
            )}
           <Stack horizontal horizontalAlign="center">
        <Stack 
            vertical
            style={{
              width: '80%',
              maxWidth: '500px',
            }}>
                <h1 style={{ marginLeft: '0px', marginTop: '27px' }} ><Icon iconName="PageAdd" style={{ color: '#106ebe'}} /> เติมเครดิตเอกสาร</h1>
                {/* <h3 style={{ marginTop: '0px', marginBottom: '10px' }} >{data.companyName}</h3> */}
                {/* <br/> */}
                ชื่อบริษัท: {data.companyName}
                <br/>
                ชื่อ: {data.firstName} {data.lastName}
                <br/>
                อีเมล: {data.email}
                <br/>
                companyId: {data.companyId}                                
                <br/>
                <br/>
                oid: {data.oid}                                
                <br/>
                <br/>
                เครดิตเอกสาร: {credits}
                <br/>
                เติมล่าสุด: {lastTopup}
                <br/>
                เวลาเติมล่าสุด: {lastTopupTime}                                
                <br/>
                <br/>

               
                <div style={cardStyle}>
                    <Stack >
                        <Stack horizontal horizontalAlign="start">

                            <TextField
                                label="เติมเครดิตจำนวน"
                                type="number"
                                style={{ width: "150px" }}
                                disabled={isLoading}
                                required
                                onChange={ e => {
                                    setTopupAmount(e.target.value*1);
                                    setTotalAmount((e.target.value*1) + credits);
                                } } 
                                value={topupAmount}
                                // errorMessage={passwordError}
                            />

                        </Stack>
                        <br/>
                        <h3>เครดิตหลังเติม: {totalAmount.toLocaleString('en-US', {minimumFractionDigits: 0})} เอกสาร</h3>
                        <br/>
                        <Stack horizontal horizontalAlign="start" tokens={{ childrenGap: "30px" }}>
                            {!isLoading &&
                                <PrimaryButton 
                                    style={{ fontSize: "20px", height: "50px", width: "150px" }} 
                                    onClick={handleSubmit}
                                    text="เติมเครดิต"/>
                            }
                            {!isLoading &&
                            
                                <DefaultButton 
                                    style={{ fontSize: "20px", height: "50px" }}
                                    onClick={ () => {
                                        //setClickAdd(true) 

                                        history.push({
                                            pathname: '/accounts/prepaid',
                                            //search: '?update=true',  // query string
                                            state: {}
                                        })
                                    } 
                                }
                                >
                                    ย้อนกลับ
                                </DefaultButton>
                            }
                            {isLoading &&
                                <Spinner /* size={SpinnerSize.large} */ label="กำลังดำเนินการ..." labelPosition="down" styles={spinnerStyles} />

                            }
                        </Stack>

                        
                    </Stack>
                </div> 
               
        </Stack>
        </Stack>
</div>
    </>
    );

}

export default DocumentsCreditsAdd;