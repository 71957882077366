import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";

import axios from 'axios';

import { useMsal } from "@azure/msal-react";
import { silentRequest } from "../../authConfig";

// import * as signalR from "@microsoft/signalr";

import paginate from 'jw-paginate';

import { Pagination } from '@fluentui/react-experiments/lib/Pagination';

import CountUp from 'react-countup';

import { 
    //TextField, 
    //DetailsList,
    DetailsListLayoutMode,
    ShimmeredDetailsList,
    //Selection,
    SelectionMode,
    //IColumn, 
    mergeStyleSets, 
    // TooltipHost,
    //Fabric,
    // ActionButton,
    // Icon,    
    //ProgressIndicator,
    //Label,
    // Spinner,
    // SpinnerSize,
    CommandBar,
    Stack,
    DocumentCard,
    //DocumentCardActivity,
    DocumentCardTitle,
    DocumentCardDetails,
    DocumentCardLogo,
    //DocumentCardStatus,
    DocumentCardType,
    Text,
    // DefaultButton
    //Pagination
    //initializeIcons
  } from '@fluentui/react';
  /* 
import { 
    getFileTypeIconProps, 
//FileIconType 
} from '@uifabric/file-type-icons';
 */
//const API_URL = process.env.REACT_APP_API_URL;
const ADMIN_API_URL = process.env.REACT_APP_ADMIN_API_URL;

const homeStyles = {    
    //width: '208',
    //height: '10vh',
    //width: 185,
    //height: 70,
    //boxSizing: 'border-box',
    //border: '1px solid #eee',
    //overflowY: 'auto',
    //paddingTop: '10vh',    
    //background: '#FFFFFF',
    //background: '#106ebe',
    border: 0,
    /* paddingTop: '2%',
    paddingLeft: '2%',
    paddingRight: '2%', */
    margin: 0,
    //minHeight: '100vh',
    width: '100%',
    background: '#FAF9F8'
    
}

//ProgressIndicator
//const centerItem = { display: "flex", alignItems: "center", justifyContent: "center" };
//Progress Indicator Setting
//const intervalDelay = 100;
//const intervalIncrement = 0.01;

const classNames = mergeStyleSets({
    fileIconHeaderIcon: {
    padding: 0,
    fontSize: '16px',
    },
    fileIconCell: {
    textAlign: 'center',
    selectors: {
        '&:before': {
        content: '.',
        display: 'inline-block',
        verticalAlign: 'middle',
        height: '100%',
        width: '0px',
        visibility: 'hidden',
        },
    },
    },
    fileIconImg: {
    verticalAlign: 'middle',
    maxHeight: '16px',
    maxWidth: '16px',
    },
    controlWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    },
    exampleToggle: {
    display: 'inline-block',
    marginBottom: '10px',
    marginRight: '30px',
    },
    selectionDetails: {
    marginBottom: '20px',
    },
});
/* 
const shimmeredDetailsListProps = {
renderedWindowsAhead: 0,
renderedWindowsBehind: 0,
};
*/

const cardStyles = {
    cardStyles: {
        root: {
        background: 'white',      
        //padding: 20,
        //marginRight: '50%',
        //marginRight: '40px',
        //borderTop: '5px solid #0078d4',
        borderLeft: '5px solid #0078d4',
        width: '30%',
        minWidth: '315px',
        //maxWidth: '415px',
        maxWidth: '424px',
        height: '130px',
        //margin: 'auto',
        //padding: '0 10% 0 10% ',
        //display: 'flex',
        //alignItems: 'center',
        //justifyContent: 'center',

        }
    },
    header: {
        root: {
        fontSize: 20,
        fontWeight: 'bold',
        //paddingTop: '5%',
        paddingTop: '20px',
        textAlign: 'center',
        //color: '#696969'
        }
    },
    amount: {
        root: {
        fontSize: 30,
        //paddingBottom: 20,
        //paddingBottom: '8%',
        paddingBottom: '25px',
        //paddingTop: '20%',
        //paddingRight: '20%',
        textAlign: 'center'
        }
    },
};

function formatDateTime(date) {
    //let d = new Date(date).toLocaleString("en-GB", {timeZone: "Asia/Bangkok"}),
    let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear(),
        hour = '' + d.getHours(),
        minute = '' + d.getMinutes(),
        second = '' + d.getSeconds()

    //console.log(d.toString());

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;
    if (hour.length < 2) 
        hour = '0' + hour;
    if (minute.length < 2) 
        minute = '0' + minute;
    if (second.length < 2) 
        second = '0' + second;

    return [year, month, day, hour, minute, second].join('');
}

const Companies = (props) => {

    console.log('Home render!!!');

    const history = useHistory();

    const { instance, accounts } = useMsal();
    //const account = useAccount(accounts[0] || {});
    const account = instance.getAllAccounts()[0];
    
    //let token = b2cauth.getAccessToken();
    //console.log('Bearer', token);

    //const token = props.token;    
    //const token = props.token;    
    //

    //const api_key = props.api_key;
    //console.log('Api-Key:', props.api_key);

    //const items = [];
    const [subscriptions, setSubscriptions] = useState([]);
    const [documents, setDocuments] = useState([]);
    const [items, setItems] = useState([]);
    const [loadDataComplete, setLoadDataComplete] = useState(false);
    const [getNewDocuments, setGetNewDocuments] = useState(true);
    const [totalProcessingDocuments, setTotalProcessingDocuments] = useState(0);
    //const [clickDelete, setClickDelete] = useState(false);
    const [pageData, setPageData] = useState({});
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
/* 
    const [pageData, setPageData] = useState({
      totalItems: '',
      currentPage: '',
      pageSize: '',
      totalPages: '',
      startPage: '',
      endPage: '',
      startIndex: '',
      endIndex: '',
      pages: [] 
    });
 */
    //const [percentComplete, setPercentComplete] = useState(0);

    //CommandBars Items
    const [documentType, setDocumentType] = useState({ key: 'ALL', text: 'เอกสารทุกประเภท' });

    //Display Summary
    const [totalDocuments, setTotalDocuments] = useState(0);   
    const [totalPaidAccounts, setTotalPaidAccounts] = useState(0);   
    const [income, setIncome] = useState(0);   
    

    const getMonth = (goBack) => {
      let monthNames = [
        "มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน",
        "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม.",
        "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"
      ];
 
      let d = new Date();
      //console.log('new Date(): ', d);

      let adjustDate = new Date(d.getFullYear(), d.getMonth() - goBack, 1);
      
      //d.setMonth(d.getMonth() - goBack);
      //console.log(d.toLocaleDateString());
      //console.log(d.getMonth());

      let year = adjustDate.getFullYear() + 543;

      return { 
        month: adjustDate.getMonth(), 
        year: adjustDate.getFullYear(), 
        key: adjustDate.getMonth() + 1, 
        text: monthNames[adjustDate.getMonth()] + ' ' + year 
      };
      //return monthNames[d.getMonth()] + ' ' + year;
      //let m = d.getMonth();
/* 
      let d;
      let year;
      let _month = [];
      
      for (let i = 0; i < 6; i++) {
        d = new Date();
        d.setMonth(d.getMonth() - i);
        year = d.getFullYear() + 543;
        _month.push(monthNames[d.getMonth()] + ' ' + year);
      }
*/      

      //console.log(_month);
        
      //return _month;
    }

    const [queryMonth, setQueryMonth] = useState({ key: getMonth(0).key, text: 'เดือนนี้' });

    const getFrom = (month, year) => {
      //let date = new Date();
      let _firstDay = new Date(year, month, 1);
      //let _lastDay = new Date(year, month + 1, 1);
      console.log('First day: ', formatDate(_firstDay));

      return formatDate(_firstDay);
      //setLastDay(formatDate(_lastDay));

      
      //console.log('Last day: ', formatDate(_lastDay));
    }

    const getTo = (month, year) => {
      //let date = new Date();
      //let _firstDay = new Date(year, month, 1);
      let _lastDay = new Date(year, month + 1, 1);
      console.log('Last day: ', formatDate(_lastDay));

      return formatDate(_lastDay);
      //setLastDay(formatDate(_lastDay));

      //console.log('Fist day: ', formatDate(_firstDay));
      
    }

    const formatDate = (d) => {
      //let d = new Date(date),
      let month = '' + (d.getMonth() + 1);
      let day = '' + d.getDate();
      let year = d.getFullYear();
  
      if (month.length < 2) 
          month = '0' + month;
      if (day.length < 2) 
          day = '0' + day;
  
      return [year, month, day].join('-');
    }

    function convertDateToUTC() {
      var date = new Date(); 
      var now_utc =  Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(),
      date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());

      return new Date(now_utc).toISOString();

    }

    const [from, setFrom] = useState(getFrom(getMonth(0).month, getMonth(0).year));
    //const [from, setFrom] = useState('2021-07-01');
    const [to, setTo] = useState(getTo(getMonth(0).month, getMonth(0).year));
    //const [to, setTo] = useState('2021-08-01');

    const commandBarItems = [
      {
        key: documentType.key,
        text: documentType.text,
        cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
        iconProps: { iconName: 'Page' },
        subMenuProps: {
          items: [
            {
              key: 'ALL',
              text: 'เอกสารทุกประเภท',
              iconProps: { iconName: 'Page' },
              onClick: () => {
                console.log('เอกสารทุกประเภท');
                setDocumentType({ key: 'ALL', text: 'เอกสารทุกประเภท' });

                setLoadDataComplete(false);
                setItems([]);
                setGetNewDocuments(true);

                setTotalDocuments(0);
                
              },
              //['data-automation-id']: 'newEmailButton', // optional
            },
            {
              key: 'RECEIPT-TAXINVOICE',
              text: 'ใบเสร็จรับเงิน/ใบกำกับภาษี',
              iconProps: { iconName: 'Page' },
              onClick: () => {
                console.log('ใบเสร็จรับเงิน/ใบกำกับภาษี');
                setDocumentType({ key: 'RECEIPT-TAXINVOICE', text: 'ใบเสร็จรับเงิน/ใบกำกับภาษี' });

                setLoadDataComplete(false);
                setItems([]);
                setGetNewDocuments(true);

                setTotalDocuments(0);
                
              },
              //['data-automation-id']: 'newEmailButton', // optional
            },
/* 
            {
              key: 'RECEIPT-TAXINVOICE-ABB',
              text: 'ใบเสร็จรับเงิน/ใบกำกับภาษีอย่างย่อ',
              iconProps: { iconName: 'Page' },
              onClick: () => {
                console.log('ใบเสร็จรับเงิน/ใบกำกับภาษีอย่างย่อ');
                setDocumentType({ key: 'RECEIPT-TAXINVOICE-ABB', text: 'ใบเสร็จรับเงิน/ใบกำกับภาษีอย่างย่อ' });
                setLoadDataComplete(false);
                setItems([]);
                setGetNewDocuments(true);
                setTotalDocuments(0);
                setTotalVat(0);
                setTotalGrand(0);
              },
              //['data-automation-id']: 'newEmailButton', // optional
            },
  */           
            {
              key: 'RECEIPT',
              text: 'ใบเสร็จรับเงิน',
              iconProps: { iconName: 'Page' },
              onClick: () => {
                console.log('ใบเสร็จรับเงิน');
                setDocumentType({ key: 'RECEIPT', text: 'ใบเสร็จรับเงิน' });

                setLoadDataComplete(false);
                setItems([]);
                setGetNewDocuments(true);

                setTotalDocuments(0);
                
              },
              //['data-automation-id']: 'newEmailButton', // optional
            },
            {
              key: 'DEBIT-NOTE',
              text: 'ใบเพิ่มหนี้',
              iconProps: { iconName: 'Page' },
              onClick: () => {
                console.log('ใบเพิ่มหนี้');
                setDocumentType({ key: 'DEBIT-NOTE', text: 'ใบเพิ่มหนี้' });

                setLoadDataComplete(false);
                setItems([]);
                setGetNewDocuments(true);

                setTotalDocuments(0);
                
              },
            },
            {
              key: 'CREDIT-NOTE',
              text: 'ใบลดหนี้',
              iconProps: { iconName: 'Page' },
              onClick: () => {
                console.log('ใบลดหนี้');
                setDocumentType({ key: 'CREDIT-NOTE', text: 'ใบลดหนี้' });

                setLoadDataComplete(false);
                setItems([]);
                setGetNewDocuments(true);

                setTotalDocuments(0);
                
              },
            },
          ],
        },
        
      },
      {
        key: queryMonth.key,
        text: queryMonth.text,
        iconProps: { iconName: 'Calendar' },
        //href: 'https://developer.microsoft.com/en-us/fluentui',
        subMenuProps: {
          items: [
            {
              key: getMonth(0).key,
              text: getMonth(0).text,
              iconProps: { iconName: 'Calendar' },
              onClick: () => {               
                console.log('Selected month: ', getMonth(0).key);
                setQueryMonth({ key: getMonth(0).key, text: getMonth(0).text });
                //setFirstLastDay(getMonth(0).month, getMonth(0).year);
                setFrom(getFrom(getMonth(0).month, getMonth(0).year));
                setTo(getTo(getMonth(0).month, getMonth(0).year));

                setLoadDataComplete(false);
                setItems([]);
                setGetNewDocuments(true);

                setTotalDocuments(0);
                setTotalPaidAccounts(0);
                setIncome(0);
                
              },
              //['data-automation-id']: 'newEmailButton', // optional
            },
            {
              key: getMonth(1).key,
              text: getMonth(1).text,
              iconProps: { iconName: 'Calendar' },
              onClick: () => {
                console.log('Selected month: ', getMonth(1).key);
                setQueryMonth({ key: getMonth(1).key, text: getMonth(1).text });
                //setFirstLastDay(getMonth(1).month, getMonth(1).year);
                setFrom(getFrom(getMonth(1).month, getMonth(1).year));
                setTo(getTo(getMonth(1).month, getMonth(1).year));

                setLoadDataComplete(false);
                setItems([]);
                setGetNewDocuments(true);

                setTotalDocuments(0);
                setTotalPaidAccounts(0);
                setIncome(0);
                
              },
              //['data-automation-id']: 'newEmailButton', // optional
            },
            {
              key: getMonth(2).key,
              text: getMonth(2).text,
              iconProps: { iconName: 'Calendar' },
              onClick: () => {
                console.log('Selected month: ', getMonth(2).key);
                setQueryMonth({ key: getMonth(2).key, text: getMonth(2).text });
                //setFirstLastDay(getMonth(2).month, getMonth(2).year);
                setFrom(getFrom(getMonth(2).month, getMonth(2).year));
                setTo(getTo(getMonth(2).month, getMonth(2).year));

                setLoadDataComplete(false);
                setItems([]);
                setGetNewDocuments(true);

                setTotalDocuments(0);
                setTotalPaidAccounts(0);
                setIncome(0);
                
              },
              //['data-automation-id']: 'newEmailButton', // optional
            },
            {
              key: getMonth(3).key,
              text: getMonth(3).text,
              iconProps: { iconName: 'Calendar' },
              onClick: () => {
                console.log('Selected month: ', getMonth(3).key);
                setQueryMonth({ key: getMonth(3).key, text: getMonth(3).text });
                //setFirstLastDay(getMonth(3).month, getMonth(3).year);
                setFrom(getFrom(getMonth(3).month, getMonth(3).year));
                setTo(getTo(getMonth(3).month, getMonth(3).year));

                setLoadDataComplete(false);
                setItems([]);
                setGetNewDocuments(true);

                setTotalDocuments(0);
                setTotalPaidAccounts(0);
                setIncome(0);
                
              },
              //['data-automation-id']: 'newEmailButton', // optional
            },
            {
              key: getMonth(4).key,
              text: getMonth(4).text,
              iconProps: { iconName: 'Calendar' },
              onClick: () => {
                console.log('Selected month: ', getMonth(4).key);
                setQueryMonth({ key: getMonth(4).key, text: getMonth(4).text });
                //setFirstLastDay(getMonth(4).month, getMonth(4).year);
                setFrom(getFrom(getMonth(4).month, getMonth(4).year));
                setTo(getTo(getMonth(4).month, getMonth(4).year));

                setLoadDataComplete(false);
                setItems([]);
                setGetNewDocuments(true);

                setTotalDocuments(0);
                setTotalPaidAccounts(0);
                setIncome(0);
                
              },
              //['data-automation-id']: 'newEmailButton', // optional
            },
            {
              key: getMonth(5).key,
              text: getMonth(5).text,
              iconProps: { iconName: 'Calendar' },
              onClick: () => {
                console.log(getMonth(5).key);
                setQueryMonth({ key: getMonth(5).key, text: getMonth(5).text });
                //setFirstLastDay(getMonth(5).month, getMonth(5).year);
                setFrom(getFrom(getMonth(5).month, getMonth(5).year));
                setTo(getTo(getMonth(5).month, getMonth(5).year));

                setLoadDataComplete(false);
                setItems([]);
                setGetNewDocuments(true);

                setTotalDocuments(0);
                setTotalPaidAccounts(0);
                setIncome(0);
                
              },
              //['data-automation-id']: 'newEmailButton', // optional
            },
            {
                key: 0,
                text: 'ทั้งหมด',
                iconProps: { iconName: 'Calendar' },
                onClick: () => {
                  //console.log(getMonth(5).key);
                  setQueryMonth({ key: 0, text: 'ทั้งหมด' });
                  //setFirstLastDay(getMonth(5).month, getMonth(5).year);
                  setFrom('2020-01-01');
                  setTo(getTo(getMonth(0).month, getMonth(0).year));
  
                  setLoadDataComplete(false);
                  setItems([]);
                  setGetNewDocuments(true);
  
                  setTotalDocuments(0);
                  setTotalPaidAccounts(0);
                  setIncome(0);
                  
                },
                //['data-automation-id']: 'newEmailButton', // optional
            },          
          ]
        }
      },      
      {
        key: 'refresh',
        text: 'รีเฟรช',
        iconProps: { iconName: 'Refresh' },
        onClick: () => {
          console.log('Refresh');

          setLoadDataComplete(false);
          setSubscriptions([]);
          setDocuments([]);
          setItems([]);
          setGetNewDocuments(true);

          setTotalDocuments(0);
          setTotalPaidAccounts(0);
          setIncome(0);
        },
      },
    ];

    const toThaiDateString = (isoDateTime) => {
      let date = '';
      date = new Date(isoDateTime);
      
      //console.log(dateTime.toLocaleDateString() + ' ' + dateTime.toLocaleTimeString());
/* 
      let monthNames = [
        "มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน",
        "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม.",
        "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"
      ];
 */
      let year = date.getFullYear() + 543;      
      //let month = monthNames[date.getMonth()];
      let month = (date.getMonth() + 1).toString().padStart(2, '0');
      let numOfDay = date.getDate().toString().padStart(2, '0');

      let hour = date.getHours().toString().padStart(2, '0');
      let minutes = date.getMinutes().toString().padStart(2, '0');
      let second = date.getSeconds().toString().padStart(2, '0');

      return `${numOfDay}/${month}/${year} ${hour}:${minutes}:${second} น.`;
    }

    const formatBytes = (bytes, decimals = 2) => {
      if (bytes === 0) return '0 Bytes';
  
      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  
      const i = Math.floor(Math.log(bytes) / Math.log(k));
  
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }
    
    

    useEffect(() => {
        console.log('userEffect Call!');

        //console.log('UTC now: ', convertDateToUTC());

        

    }, []);

    useEffect(() => {
      console.log('userEffect getNewDocuments Call!');   

      instance.acquireTokenSilent({...silentRequest, account: account}).then(tokenResponse => {
        // Do something with the tokenResponse
        console.log('Token response: ', tokenResponse);       

        axios.get(ADMIN_API_URL + '/subscriptions?product=etax&from=' + from + '&to=' + to, 
        {
          headers: { 
            'Authorization': 'Bearer ' + tokenResponse.accessToken
          } 
        })
        .then(async (responseSubscriptions) => {

          console.log('Subscriptions: ', responseSubscriptions);                            
          
          if (responseSubscriptions.data.length > 0) {

            let _subscriptions = responseSubscriptions.data;

            setSubscriptions(_subscriptions);

            await axios.get(ADMIN_API_URL + '/companies?from=' + from + '&to=' + to, 
                {
                headers: { 
                    'Authorization': 'Bearer ' + tokenResponse.accessToken
                } 
                })
                .then((response) => {

                console.log(response);
                console.log('Docs number: ', response.data.length);

                setTotalDocuments(response.data.length);

                //set page
                console.log('Page data: ', paginate(response.data.length, 1, 5, 10));
                setPageData(paginate(response.data.length, currentPage, pageSize, 10));


                setDocuments(response.data);

                const _docs = [];
                
                
                if (response.data.length > 0) {

                    const _total = response.data.length;
                    
                    for (let i = (currentPage-1)*pageSize; i < Math.min(currentPage*pageSize, response.data.length); i++) {
                    
                        let createdTime = toThaiDateString(response.data[i].createdTime);    
                        
                        let subscriptions = _subscriptions.find((item) => {

                            return (item.createdBy === response.data[i].createdBy);

                        });

                        if (subscriptions === undefined) {
                            subscriptions = {
                                plan: { 
                                    id: '',
                                    documents: 0,
                                },
                                documents: {
                                    spent: 0
                                },
                                to: ''
                              
                            }
                        }

                        

                        

                        _docs.push({
                            key: response.data[i].id,  
                            number: _total - i,   
                            createdBy: response.data[i].createdBy,   
                            createdTime: createdTime,           
                            email: response.data[i].email,
                            //firstName: response.data[i].profiles.firstName,
                            //lastName: response.data[i].profiles.lastName,
                            name: response.data[i].name,
                            //mobile: response.data[i].profiles.mobile,                
                            provinceName: response.data[i].provinceName, 
                            planId: subscriptions.plan.id,
                            planDocuments: subscriptions.plan.documents,
                            spent: subscriptions.documents.spent,
                            subscriptionTo: toThaiDateString(subscriptions.to),
                
                        });                      
                    
                    
                    }
                
                }

                console.log('Docs data', _docs);
                setItems(_docs);

                //setDocuments(_docs);

                /* setLoadDataComplete(true);
                setGetNewDocuments(false); */


                }, (error) => {
                    console.log(error);
                });

            
            let _income = 0;
            let _paidAccounts = 0;
            for (let k = 0; k < _subscriptions.length; k++) {

                _income = _income + _subscriptions[k].plan.price;

                if (_subscriptions[k].plan.type === 'paid') {
                    
                    _paidAccounts = _paidAccounts + 1;

                }
            }

            
            setTotalPaidAccounts(_paidAccounts);
            setIncome(_income);

            
           
          }

            setLoadDataComplete(true);
            setGetNewDocuments(false);

            

         


        }, (error) => {
            console.log(error);
        });

            
        
                
                


      }).catch(error => {
        
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
        

      });

   

    }, [getNewDocuments]);
    
    useEffect(() => {

      if (documents.length > 0) {    
          
        //let _subscriptions = subscriptions || [];

        const _docs = [];

        const _total = documents.length;
        
        for (let i = (currentPage-1)*pageSize; i < Math.min(currentPage*pageSize, documents.length); i++) {
         
          let createdTime = toThaiDateString(documents[i].createdTime);
          
          let _subscriptions = subscriptions.find((item) => {

            return (item.createdBy === documents[i].createdBy);

          });

          if (_subscriptions === undefined) {
                _subscriptions = {
                    plan: { 
                        id: '',
                        documents: 0,
                    },
                    documents: {
                        spent: 0
                    },
                    to: ''
                  
              }
          }

            console.log('Current page: ', currentPage);
            console.log('Page size: ', pageSize);
            console.log('Total - (currentPage-1)*pageSize: ', _total - (currentPage-1)*pageSize);

          _docs.push({
            key: documents[i].id,     
            number: _total - i,
            createdBy: documents[i].createdBy,               
            createdTime: createdTime,           
            email: documents[i].email,
            //firstName: documents[i].profiles.firstName,
            //lastName: documents[i].profiles.lastName,
            name: documents[i].name,
            //mobile: documents[i].profiles.mobile,                
            provinceName: documents[i].provinceName,
            planId: _subscriptions.plan.id,
            planDocuments: _subscriptions.plan.documents,
            spent: _subscriptions.documents.spent,
            subscriptionTo: toThaiDateString(_subscriptions.to),
           
          });

                    
          
          
        }
        console.log('Docs data', _docs);
        
        setItems(_docs);
        //setLoadDataComplete(true);
        //setGetNewDocuments(false);
      }

    }, [currentPage]);
    

    const onPageChange = (selectedPageIndex) => {
      console.log('selectedPageIndex: ', selectedPageIndex);
      setCurrentPage(selectedPageIndex+1);
    }

    
    const columns = [
      {
        key: 'column1',
        name: 'Number',
        className: classNames.fileIconCell,
        iconClassName: classNames.fileIconHeaderIcon,        
        iconName: 'CityNext2',
        isIconOnly: true,
        fieldName: 'number',
        minWidth: 16,
        maxWidth: 16,
        onColumnClick: '',

        onRender: (item) => {
            return <span>{item.number}</span>;
        },

      },
      {
        key: 'column2',
        name: 'วันที่สร้าง',
        fieldName: 'createdTime',
        minWidth: 60,
        maxWidth: 125,
        isResizable: true,
        isSorted: true,
        isSortedDescending: true,
        onColumnClick: '',
        data: 'string',
        onRender: (item) => {
          return <span>{item.createdTime}</span>;
        },
        isPadded: true,
      },
      {
        key: 'column3',
        name: 'สร้างโดย',
        fieldName: 'createBy',
        minWidth: 50,
        maxWidth: 50,
        isResizable: true,
        isSorted: true,
        isSortedDescending: true,
        onColumnClick: '',
        data: 'string',
        onRender: (item) => {
          return <span>{item.createdBy}</span>;
        },
        isPadded: true,
      },
      {
        key: 'column4',
        name: 'ชื่อ',
        fieldName: 'name',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        isSorted: true,
        isSortedDescending: true,
        onColumnClick: '',
        data: 'string',
        onRender: (item) => {
          return <span>{item.name}</span>;
        },
        isPadded: true,
      },
      {
        key: 'column5',
        name: 'จังหวัด',        
        fieldName: 'provinceName',
        minWidth: 50,
        maxWidth: 100,
        isRowHeader: true,
        isResizable: true,
        isCollapsible: true,
        isSorted: false,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        onColumnClick: '',
        data: 'string',
        onRender: (item) => {
            return <span>{item.provinceName}</span>;
        },
        isPadded: true,        
        
      },     
      {
        key: 'column6',
        name: 'อีเมล',        
        fieldName: 'email',
        minWidth: 80,
        maxWidth: 100,
        isRowHeader: true,
        isResizable: true,
        isSorted: false,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        onColumnClick: '',
        data: 'string',
        onRender: (item) => {
            return <span>{item.email}</span>;
        },
        isPadded: true,        
        
      },             
      {
        key: 'column7',
        name: 'แพ็กเกจ',
        fieldName: 'package',
        minWidth: 30,
        maxWidth: 30,
        isResizable: true,
        //isSorted: true,
        //isSortedDescending: true,
        onColumnClick: '',
        data: 'string',
        onRender: (item) => {
          return <span>{item.planId}</span>;
        },
        isPadded: true,
      },
      {
        key: 'column8',
        name: 'ใช้ไป',
        fieldName: 'spent',
        minWidth: 30,
        maxWidth: 30,
        isResizable: true,
        //isSorted: true,
        //isSortedDescending: true,
        onColumnClick: '',
        data: 'string',
        onRender: (item) => {
          return <span>{item.spent}</span>;
        },
        isPadded: true,
      },
      {
        key: 'column9',
        name: 'ใช้ได้ถึง',
        fieldName: 'to',
        minWidth: 30,
        maxWidth: 30,
        isResizable: true,
        //isSorted: true,
        //isSortedDescending: true,
        onColumnClick: '',
        data: 'string',
        onRender: (item) => {
          return <span>{item.subscriptionTo}</span>;
        },
        isPadded: true,
      },
      
    ];
    
    const isCompactMode = false;
    //const isModealSelection = false;
    //const annoucedMessage = undefined;
    
    

    return (
        <div style={homeStyles}>
          <Stack>
            <center>
              <h2 style={{ marginLeft: '0px', marginTop: '25px' }}>ข้อมูลบริษัท</h2>
              {/* <h4>( สำหรับส่งให้กรมสรรพากร )</h4> */}
              {/* <br/> */}
            </center>

            
              <Stack 
                horizontal 
                horizontalAlign="center"
                //wrap 
/* 
                styles={{
                  root: {
                    background: '#FAF9F8',                    
                  } 
                }} 
                tokens={{ childrenGap: '2%' }}
 */
                tokens={{ childrenGap: '30px'}}
              >

                  <DocumentCard
                    aria-label="จำนวนบัญชี"
                    styles={cardStyles.cardStyles}                    
                    type={DocumentCardType.compact}
                    //onClickHref="http://bing.com"
                  >
                    <DocumentCardLogo { ...{ logoIcon: 'Contact' } } title="จำนวนบัญชี" />
                      {/* <div className={conversationTileClass}> */}
                    <DocumentCardDetails>
                      <DocumentCardTitle
                        title="จำนวนบัญชี"
                        //shouldTruncate
                        styles={cardStyles.header}
                      />                      
                      {/* <Text styles={cardStyles.amount}>{totalDocuments} ใบ</Text> */}
                      <Text styles={cardStyles.amount}><CountUp end={totalDocuments} separator=','  duration={1} /> บัญชี</Text>
                      {/* </div>  */} 
                    </DocumentCardDetails>
                  </DocumentCard>
                    
                
                <DocumentCard
                    aria-label="บัญชีที่ชำระเงิน"
                    styles={cardStyles.cardStyles}                    
                    type={DocumentCardType.compact}
                    //onClickHref="http://bing.com"
                  >
                    <DocumentCardLogo { ...{ logoIcon: 'PaymentCard' } } title="บัญชีที่ชำระเงิน" />
                      {/* <div className={conversationTileClass}> */}
                    <DocumentCardDetails>
                      <DocumentCardTitle
                        title="บัญชีที่ชำระเงิน"
                        //shouldTruncate
                        styles={cardStyles.header}
                      />                      
                      {/* <Text styles={cardStyles.amount}>{totalVat.toLocaleString(undefined, {maximumFractionDigits:2, minimumFractionDigits:2})} บาท</Text> */}
                      <Text styles={cardStyles.amount}><CountUp end={totalPaidAccounts} separator=',' duration={1} /> บัญชี</Text>
                      {/* </div>  */} 
                    </DocumentCardDetails>
                  </DocumentCard>
                
                <DocumentCard
                    aria-label="รายได้"
                    styles={cardStyles.cardStyles}                    
                    type={DocumentCardType.compact}
                    //onClickHref="http://bing.com"
                  >
                    <DocumentCardLogo { ...{ logoIcon: 'Money' } } title="รายได้" />
                      {/* <div className={conversationTileClass}> */}
                    <DocumentCardDetails>
                      <DocumentCardTitle
                        title="รายได้"
                        //shouldTruncate
                        styles={cardStyles.header}
                      />                      
                      {/* <Text styles={cardStyles.amount}>{totalGrand.toLocaleString(undefined, {maximumFractionDigits:2, minimumFractionDigits:2})} บาท</Text> */}
                      <Text styles={cardStyles.amount}><CountUp end={income} separator=',' decimals={2} decimal='.' duration={1} /> บาท</Text>
                      {/* </div>  */} 
                    </DocumentCardDetails>
                  </DocumentCard>
              </Stack>
            </Stack>
            
            <br />
            
            <Stack 
              horizontal 
              horizontalAlign="center"              
            >
              <Stack vertical style={{ 
                width: '98%', 
                //minWidth: '1070px', 
                maxWidth: '1350px' 
              }}>
                <CommandBar
                  items={commandBarItems}
                  //overflowItems={_overflowItems}
                  //overflowButtonProps={overflowProps}
                  //farItems={_farItems}
                  //ariaLabel="Use left and right arrow keys to navigate between commands"
                />
                <ShimmeredDetailsList
                  items={items || []}
                  enableShimmer={!loadDataComplete}
                  compact={isCompactMode}
                  columns={columns}
                  selectionMode={SelectionMode.none}
                  //selectionMode={SelectionMode.multiple}
                  //getKey="1"
                  //setKey="set"
                  layoutMode={DetailsListLayoutMode.justified}
                  isHeaderVisible={true}
                  //selection={Selection}
                  //selectionPreservedOnEmptyClick={true}
                  //onItemInvoked={download}
                  //enterModalSelectionOnTouch={true}
                  //ariaLabelForSelectionColumn="Toggle selection"
                  //ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                  //checkButtonAriaLabel="Row checkbox"
                  //onRenderItemColumn={ this.onRenderItemColumn }
                  //listProps={shimmeredDetailsListProps}
                />
              </Stack>
            </Stack>
            {/* </Fabric> */}
            <br/><center>
            {!(documents.length===0 || documents.length < pageSize) && <Pagination
              //selectedPageIndex={page}
              selectedPageIndex={currentPage-1}
              //pageCount={pageCount}
              pageCount={pageData.totalPages}
              onPageChange={onPageChange}
              format
              firstPageIconProps={{ iconName: 'DoubleChevronLeft' }}
              previousPageIconProps={{ iconName: 'ChevronLeft' }}
              nextPageIconProps={{ iconName: 'ChevronRight' }}
              lastPageIconProps={{ iconName: 'DoubleChevronRight' }}
            />}
            
            <br/>
            <br/>

            
            </center>

        </div>
    );

}

export default Companies;