import React, { useState, useEffect } from 'react';
//import { Nav, INavLink, INavStyles, INavLinkGroup } from 'office-ui-fabric-react/lib/Nav';
import { Nav, initializeIcons, PrimaryButton, Stack } from '@fluentui/react';

import { withRouter } from 'react-router-dom';

import axios from 'axios';

import { useMsal, useAccount } from "@azure/msal-react";
import { silentRequest } from "../../authConfig";
import { profileEditingRequest, passwordResetRequest } from "../../authConfig";

const API_URL = process.env.REACT_APP_API_URL;

const links = [
  {
    name: 'เอกสาร e-Tax',
    isExpanded: true,

    links: [
      {

        name: 'เอกสารทั้งหมด',
        url: '/etax/documents/all',
        key: '/etax/documents/all',
        //key: 'home',        
        iconProps: {
          //iconName: 'Home',
          //iconName: 'Documentation',
          iconName: 'DocumentSet',
          styles: {
            root: {
              fontSize: 20,
              color: '#106ebe',
            }
          }
        }
      },
      /* 
                  {
                      
                      name: 'เอกสาร PDF',
                      url: '/etax/documents/pdf',
                      key: '/etax/documents/pdf',
                      //key: 'home',        
                      iconProps: {
                          //iconName: 'Home',
                          //iconName: 'Documentation',
                          iconName: 'DocumentSet',
                          styles: {
                              root: {
                                  fontSize: 20,
                                  color: '#106ebe',
                              }
                          }
                      }
                  },
             */      
      /* 
                  {
                      
                      name: 'เอกสาร XML Legacy',
                      url: '/etax/documents/xml-legacy',
                      key: '/etax/documents/xml-legacy',
                      //key: 'home',        
                      iconProps: {
                          //iconName: 'Home',
                          //iconName: 'Documentation',
                          iconName: 'DocumentSet',
                          styles: {
                              root: {
                                  fontSize: 20,
                                  color: '#106ebe',
                              }
                          }
                      }
                  },
                   */
    ]
  },
  {
    name: 'ข้อมูล',
    isExpanded: true,
    links: [
      {
        name: 'บัญชีผู้ใช้งาน',
        url: '/accounts/prepaid',
        key: '/accounts/prepaid',
        //key: 'receipts-taxinvoices',
        iconProps: {
          iconName: 'PageAdd',
          styles: {
            root: {
              fontSize: 20,
              color: '#106ebe',
            }
          }
        }

      },
      {
        name: 'บัญชีผู้ใช้งาน (Subscription)',
        url: '/accounts/subscriptions',
        key: '/accounts/subscriptions',
        //key: 'receipts-taxinvoices',
        iconProps: {
          iconName: 'PageAdd',
          styles: {
            root: {
              fontSize: 20,
              color: '#106ebe',
            }
          }
        }

      },
      {
        name: 'สร้าง Statement',
        url: '/accounts/subscriptions/create-statement',
        key: '/accounts/subscriptions/create-statement',
        //key: 'receipts-taxinvoices',
        iconProps: {
          iconName: 'PageAdd',
          styles: {
            root: {
              fontSize: 20,
              color: '#106ebe',
            }
          }
        }

      },
      {
        name: 'บริษัท',
        url: '/companies',
        key: '/companies',
        //key: 'receipts-taxinvoices',
        iconProps: {
          iconName: 'CityNext2',
          styles: {
            root: {
              fontSize: 20,
              color: '#106ebe',
            }
          }
        }

      },
      {
        name: 'ออเดอร์',
        url: '/etax/documents/receipts-taxinvoices',
        key: '/etax/documents/receipts-taxinvoices',
        //key: 'receipts-taxinvoices',
        iconProps: {
          iconName: 'PageAdd',
          styles: {
            root: {
              fontSize: 20,
              color: '#106ebe',
            }
          }
        }

      }
    ]

  },
  {
    name: 'นำส่งข้อมูล E-Tax',
    isExpanded: true,

    links: [      
      {

        name: 'เอกสาร XML (Web Upload)',
        url: '/etax/documents/xml',
        key: '/etax/documents/xml',
        //key: 'home',        
        iconProps: {
          //iconName: 'Home',
          //iconName: 'Documentation',
          iconName: 'DocumentSet',
          styles: {
            root: {
              fontSize: 20,
              color: '#106ebe',
            }
          }
        }
      },
      {

        name: 'เอกสาร XML (Service Provider)',
        url: '/etax/documents/xml-service-provider',
        key: '/etax/documents/xml-service-provider',
        //key: 'home',        
        iconProps: {
          //iconName: 'Home',
          //iconName: 'Documentation',
          iconName: 'DocumentSet',
          styles: {
            root: {
              fontSize: 20,
              color: '#106ebe',
            }
          }
        }
      },      
    ]
  },
  {
    name: 'เช็ค',
    isExpanded: true,
    links: [
      {
        name: 'เอกสารทั้งหมด',
        url: '/checks',
        key: '/checks',
        //key: 'receipts-taxinvoices',
        iconProps: {
          iconName: 'ComplianceAudit',
          styles: {
            root: {
              fontSize: 20,
              color: '#106ebe',
            }
          }
        }

      }
    ]

  },


]



const navigationStyles = {
  root: {
    //position: 'relative',
    //width: '208',
    //height: '100vh',
    //minHeight: '100vh',
    //height: '100vh',
    height: '100%',
    //width: 380,
    //width: '100%',
    width: '286px',
    //height: '100%',
    //height: 350,
    boxSizing: 'border-box',
    //border: '1px solid #eee',
    //overflowY: 'auto',
    //paddingTop: '10vh',
    paddingTop: '5%',
    paddingLeft: '5%',
    paddingRight: '3%',
    margin: 0,
    fontSize: '100',
    //background: 'green'   
    //background: 'white'    


  }
}



const Navigation = () => {
  initializeIcons();

  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const [keyUrl, setKeyUrl] = useState('/');
  const [command, setCommand] = useState('');

  const [companySetting, setCompanySetting] = useState(true);

  useEffect(() => {
    if (command === 'logout') {

      let confirmLogout = window.confirm('กรุณายืนยันการออกจากระบบ');

      if (confirmLogout) {

        instance.logout();

      } else {
        setCommand('');
      }

    } else if (command === 'user-profiles') {

      instance.loginRedirect(profileEditingRequest);

    } else if (command === 'password-reset') {

      instance.loginRedirect(passwordResetRequest);

    }
  }, [command]);
  /* 
      useEffect(() => {
          setLinks(init_links);
  
          
      }, [oid]); */

  useEffect(() => {
    console.log('Nav render useEffect!!');
    console.log(window.location.pathname);
    setKeyUrl(window.location.pathname);

    //setLinks(init_links);        
  });

  useEffect(async () => {
    console.log('userEffect retrieve company data!');

    //this.token = b2cauth.getAccessToken();
    //b2cauth.run();
    //let token = await AccessToken();
    instance.acquireTokenSilent({ ...silentRequest, account: account }).then(tokenResponse => {

      //axios.get('https://etax.azure-api.net/company/b5cf38c9-5e52-4bd3-b737-caf5c6d30635')
      //axios.get('https://etax.azure-api.net/company/' + userId)
      axios.get(API_URL + '/etax/companies',
        {
          headers: {
            'Authorization': 'Bearer ' + tokenResponse.accessToken
          }
        })
        .then((response) => {

          console.log(response);

          if (response.data.length === 0) {

            setCompanySetting(true);

          } else {
            setCompanySetting(false);
          }


        }, (error) => {
          console.log(error);

        });

    }).catch(error => {

      //Acquire token silent failure, and send an interactive request
      console.log(error);
      instance.acquireTokenRedirect(silentRequest);

    });

  }, []);

  const FabricNav = withRouter(({ history }) => (
    <div>


      <Nav
        /* 
                    onRenderGroupHeader={(group) => {
                        return <h3>{group.name}</h3>;
                    }}
                     */
        onLinkClick={(event, element) => {
          event.preventDefault();

          //setKeyUrl(element.key);

          //console.log(element.key);
          if (element.key === 'logout') {
            setCommand(element.key);
          } else if (element.key === 'user-profiles') {
            setCommand(element.key);
          } else if (element.key === 'password-reset') {
            setCommand(element.key);
          } else if (element.key === 'user-manual') {
            setCommand(element.key);
            window.open(element.url);
          } else {
            setKeyUrl(element.key);
            history.push(element.url);
          }
        }}
        groups={links}
        //selectedKey={keyUrl} 
        styles={navigationStyles}
      />
    </div>

  ));

  return (
    <div>
      {/* {!companySetting && <FabricNav />} */}
      {/* <Stack verticalAlign="center"> */}

      <FabricNav />
      {/* </Stack> */}
    </div>


  );
}

export default Navigation;

