import React, { useState, useEffect, useRef } from 'react';

import axios from 'axios';

import { useMsal, useAccount } from "@azure/msal-react";
import { silentRequest } from "../../../authConfig";

import { Editor } from '@tinymce/tinymce-react';
import { Controlled as CodeMirror } from 'react-codemirror2'

import { useHistory, useLocation } from 'react-router-dom';

import { 
    //TextField,     
    //mergeStyles, 
    PrimaryButton, 
    DefaultButton, 
    //IconButton, 
    //Checkbox, 
    Dropdown, 
    Stack, 
    getTheme, 
    //Shimmer,  
    //ShimmerElementType,    
    Spinner,
    //SpinnerSize,
    //MessageBarButton,
    MessageBar,
    MessageBarType,   
    //ChoiceGroup,
    //Separator,
    Icon
    
    
} from '@fluentui/react';

import { receiptTaxinvoice, taxInvoice, receiptTaxinvoiceAbb, receipt, debitNote, creditNote, quotation, invoice } from './example-etax-data';
//import { jsbeautify } from 'js-beautify';

require('codemirror/lib/codemirror.css');
////require('codemirror/mode/xml/xml');
require('codemirror/theme/material.css');

require('codemirror/mode/xml/xml');
//require('codemirror/mode/json/json');
require('codemirror/mode/javascript/javascript');
require('codemirror/mode/css/css');
require('codemirror/mode/jsx/jsx');
require('codemirror/mode/handlebars/handlebars');
require('codemirror/lib/codemirror.css');
require('codemirror/theme/dracula.css');
require('codemirror/theme/panda-syntax.css');
require('codemirror/theme/material.css');

//import Province from './address/Province';
//import District from './address/District';
//import SubDistrict from './address/SubDistrict';
//import Branch from './address/Branch';

// const API_URL = process.env.REACT_APP_API_URL;
const ADMIN_API_URL = process.env.REACT_APP_ADMIN_API_URL;

const theme = getTheme();

const stackTokens = { childrenGap: 15 };

const dropdownStyles = {
    dropdown: { width: 250 },
};

const stackStyles = { display: "flex", alignItems: "flex-end", height: "50px" };

//const iconProps = { iconName: 'Calendar' };
//const stackStyles = { root: { width: 800 } };
/* 
const _alertClicked = () => {
    alert('Clicked');
}
 */

//initializeIcons();


const PdfTemplate = () => {

    console.log('Re render');

    const history = useHistory();
    const location = useLocation();    

    var data = location.state;
    console.log(data);

    //const auth = Auth();
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});    

    //const [orgHtml, setOrgHtml] = useState('');
    const [html, setHtml] = useState('');
    const [documentType, setDocumentType] = useState({ key: '', text: '' });
    const [exampleData, setExampleData] = useState('');
    const [referenceUrl, setReferenceUrl] = useState('');
    const [chooseDocument, setChooseDocument] = useState(false);

    const [showMessageBarSuccess, setShowMessageBarSuccess] = useState(false);
    const [showMessageBarError, setShowMessageBarError] = useState(false);

    const [isLoadComplete, setIsLoadComplete] = useState(true);

    const [companyId, setCompanyId] = useState('');


    const handleChange = (content, editor) => {
/* 
        console.log(
          'Content was updated:',
          e.target.getContent()
        );
        setHtml(e.target.getContent());
         */
        console.log('editorChange!');
        //console.log(editor);
        console.log(editorRef.current.hasFocus());
        
        if (editorRef.current && editorRef.current.hasFocus()) {

            setHtml(content);

        }
    }
    
    //Set ref tiny editor
    const editorRef = useRef(null);
    const updateEditor = (content) => {
        //console.log(editorRef.current.hasFocus());
        if (editorRef.current && !editorRef.current.hasFocus()) {
        //console.log(editorRef.current.getContent());
            editorRef.current.setContent(content);
        }
    };

    const getTemplateData = (companyId, templateType) => {

        setIsLoadComplete(false);

        instance.acquireTokenSilent({...silentRequest, account: account}).then(tokenResponse => {

            let _apiEndpoint = ADMIN_API_URL + '/etax/templates/' + templateType + '/pdf/' + companyId

            if ((templateType === 'quotation') || (templateType === 'invoice')  || (templateType === 'billing-note') || (templateType === 'deliveryorder')) {
                _apiEndpoint = ADMIN_API_URL + '/documents/templates/' + templateType + '/pdf/' + companyId
            }   

            //axios.get(API_URL + '/etax/templates/' + templateType + '/pdf', 
            axios.get(_apiEndpoint, 
                {
                headers: { 
                    'Authorization': 'Bearer ' + tokenResponse.accessToken
                } 
                })
                .then((response) => {

                    console.log(response);

                    if (response.data) {

                        //console.log(JSON.stringify(response.data));
                        //console.log(response.data);
                        setHtml(response.data);
                        //setOrgHtml(response.data);

                        if (templateType === 'receipt-taxinvoice') {
                            setExampleData(JSON.stringify(receiptTaxinvoice, null, 2));
                            setReferenceUrl('https://developers.leceipt.com/etax/#create-a-receipt-tax-invoice-pdf-file');
                        } else if (templateType === 'receipt-taxinvoice-abb') {
                            setExampleData(JSON.stringify(receiptTaxinvoiceAbb, null, 2));
                            setReferenceUrl('https://developers.leceipt.com/etax/#create-a-receipt-abbreviated-tax-invoice-pdf-file');
                        } else if (templateType === 'receipt') {
                            setExampleData(JSON.stringify(receipt, null, 2));
                            setReferenceUrl('https://developers.leceipt.com/etax/#create-a-receipt-pdf-file');
                        } else if (templateType === 'invoice-taxinvoice') {
                            setExampleData(JSON.stringify(taxInvoice, null, 2));
                            setReferenceUrl('https://developers.leceipt.com/etax/#create-a-receipt-tax-invoice-pdf-file');
                        } else if (templateType === 'deliveryorder-taxinvoice') {
                            setExampleData(JSON.stringify(taxInvoice, null, 2));
                            setReferenceUrl('https://developers.leceipt.com/etax/#create-a-receipt-tax-invoice-pdf-file');
                        } else if (templateType === 'taxinvoice') {
                            setExampleData(JSON.stringify(taxInvoice, null, 2));
                            setReferenceUrl('https://developers.leceipt.com/etax/#create-a-receipt-tax-invoice-pdf-file');
                        } else if (templateType === 'debit-note') {
                            setExampleData(JSON.stringify(debitNote, null, 2));
                            setReferenceUrl('https://developers.leceipt.com/etax/#create-a-debit-note-pdf-file');
                        } else if (templateType === 'credit-note') {
                            setExampleData(JSON.stringify(creditNote, null, 2));
                            setReferenceUrl('https://developers.leceipt.com/etax/#create-a-credit-note-pdf-file');
                        }  else if (templateType === 'quotation') {
                            setExampleData(JSON.stringify(quotation, null, 2));
                            setReferenceUrl('https://developers.leceipt.com');
                        }  else if (templateType === 'invoice') {
                            setExampleData(JSON.stringify(invoice, null, 2));
                            setReferenceUrl('https://developers.leceipt.com');
                        }  else if (templateType === 'billing-note') {
                            setExampleData(JSON.stringify(invoice, null, 2));
                            setReferenceUrl('https://developers.leceipt.com');
                        }  else if (templateType === 'deliveryorder') {
                            setExampleData(JSON.stringify(invoice, null, 2));
                            setReferenceUrl('https://developers.leceipt.com');
                        }
                        

                        
                        
                    } else {
                        

                    }

                    setIsLoadComplete(true);
                    
                }, (error) => {
                    console.log(error);

                    setIsLoadComplete(true);
                    
                });
        }).catch(error => {           
    
            //Acquire token silent failure, and send an interactive request
            console.log(error);
            instance.acquireTokenRedirect(silentRequest);

        });

    }

    const deleteTemplate = () => {

        setIsLoadComplete(false);

        instance.acquireTokenSilent({...silentRequest, account: account}).then(tokenResponse => {

            let _apiEndpoint = ADMIN_API_URL + '/etax/templates/' + documentType.key + '/pdf/' + companyId

            if ((documentType.key === 'quotation') || (documentType.key === 'invoice') || (documentType.key === 'billing-note') || (documentType.key === 'deliveryorder')) {
                _apiEndpoint = ADMIN_API_URL + '/documents/templates/' + documentType.key + '/pdf/' + companyId
            }  
                
            //axios.delete(API_URL + '/etax/templates/' + documentType.key + '/pdf', 
            axios.delete(_apiEndpoint, 
                {
                headers: { 
                    'Authorization': 'Bearer ' + tokenResponse.accessToken
                } 
                })
                .then((response) => {

                    console.log(response);

                    if (response.data) {

                        //console.log(JSON.stringify(response.data));
                        console.log(response.data);   
                        
                        getTemplateData(companyId, documentType.key);

                        setShowMessageBarSuccess(true);
                        
                    } 

                    setIsLoadComplete(true);
                    
                }, (error) => {
                    console.log(error);
                    setShowMessageBarError(true);
                    setIsLoadComplete(true);
                    
                });
        }).catch(error => {           
    
            //Acquire token silent failure, and send an interactive request
            console.log(error);
            instance.acquireTokenRedirect(silentRequest);

        });
    }

    useEffect(() => {
        console.log('userEffect Call!');        

        if (data !== undefined && data.companyId !== '') {

            setCompanyId(data.companyId);

            if (data.htmlTemplate && data.htmlTemplate !== '') {
                console.log('data not null!');

                setDocumentType({ "key": data.documentType.key, "text": data.documentType.text });            
                setHtml(data.htmlTemplate);
                setExampleData(data.data);
                //setDocumentType({ "key": data.documentType.key, "text": data.documentType.text });

                if (data.documentType.key === 'receipt-taxinvoice') {
                    //setExampleData(JSON.stringify(receiptTaxinvoice, null, 2));
                    setReferenceUrl('https://developers.leceipt.com/etax/#create-a-receipt-tax-invoice-pdf-file');
                } else if (data.documentType.key === 'receipt-taxinvoice-abb') {
                    //setExampleData(JSON.stringify(receiptTaxinvoice, null, 2));
                    setReferenceUrl('https://developers.leceipt.com/etax/#create-a-receipt-tax-invoice-pdf-file');
                } else if (data.documentType.key === 'receipt') {
                    //setExampleData(JSON.stringify(receiptTaxinvoice, null, 2));
                    setReferenceUrl('https://developers.leceipt.com/etax/#create-a-receipt-tax-invoice-pdf-file');
                } else if (data.documentType.key === 'debit-note') {
                    //setExampleData(JSON.stringify(debitNote, null, 2));
                    setReferenceUrl('https://developers.leceipt.com/etax/#create-a-debit-note-pdf-file');
                } else if (data.documentType.key === 'credit-note') {
                    //setExampleData(JSON.stringify(creditNote, null, 2));
                    setReferenceUrl('https://developers.leceipt.com/etax/#create-a-credit-note-pdf-file');
                } 

            } else {
                setDocumentType({ "key": "receipt-taxinvoice", "text": "ใบเสร็จรับเงิน/ใบกำกับภาษี" });
                getTemplateData(data.companyId, 'receipt-taxinvoice');
            }

        } else {
            history.push({ pathname: '/accounts/prepaid', state: {} });
        }


    }, []);

    useEffect(() => {
        console.log('userEffect call documentType change!');   
        console.log('documentType: ', documentType);
        console.log('data: ', data);
        console.log('Choose document: ', chooseDocument);

        //if (data === undefined || data.htmlTemplate === '') {
        if (chooseDocument) {

            getTemplateData(companyId, documentType.key);

        }
        


    }, [documentType]);

    

    return (
        <Stack>            
            { showMessageBarSuccess && <MessageBar
/* 
                actions={
                <div>
                    <MessageBarButton>Yes</MessageBarButton>
                    <MessageBarButton>No</MessageBarButton>
                </div>
                }
                 */
                messageBarType={MessageBarType.success}
                isMultiline={false}
                onDismiss={()=> setShowMessageBarSuccess(false)}
                dismissButtonAriaLabel="Close"
            >
                รีเซ็ตรูปแบบเอกสารสำเร็จ!                           
            </MessageBar> }
            { showMessageBarError && <MessageBar
/* 
                actions={
                <div>
                    <MessageBarButton>Yes</MessageBarButton>
                    <MessageBarButton>No</MessageBarButton>
                </div>
                }
                 */
                messageBarType={MessageBarType.error}
                isMultiline={false}
                onDismiss={()=> setShowMessageBarError(false)}
                dismissButtonAriaLabel="Close"
            >
                รีเซ็ตรูปแบบเอกสารไม่สำเร็จ!                           
            </MessageBar> }
            <form /* onSubmit={handleSubmit} */>
            <h2 style={{ marginLeft: '0px', marginTop: '27px' }}><Icon iconName="FileTemplate" style={{ color: '#106ebe'}} /> รูปแบบเอกสาร</h2>
                {/* <h2 style={{ margin: '2%', color: '#0078D4' }}>รูปแบบเอกสาร</h2> */}
                {/* <br/> */}
                {/* <h2 style={{ margin: '2%' }}>ชำระเงินสำเร็จ</h2> */}
                {/* <div style={{ margin: '2%' }}>(ข้อมูลทั้งหมดข้างล่างนี้ ใช้สำหรับแสดงบนหัวเอกสารของท่าน)</div> */}
                {/* <center> */}
                {/* <div className="ms-Grid" dir="ltr" style={{ paddingLeft: 30, paddingRight: 30, paddingTop: 20, paddingBottom: 30, background: '#ffffff', margin: '2%', boxShadow: theme.effects.elevation4 }}>  */}
                <div style={{ width: '1000px', paddingLeft: 30, paddingRight: 30, paddingTop: 20, paddingBottom: 30, background: '#ffffff', margin: '0', boxShadow: theme.effects.elevation4 }}> 
                    {/* <div className="ms-Grid-row"> */}
                        {/* <h2 style={{ margin: '2%', color: '#0078D4' }}>รูปแบบเอกสาร</h2>
                        <Separator/> */}
                        <h3>บริษัท: {data.companyName}</h3>
                        {/* <br/> */}
                        ชื่อ: {data.firstName} {data.lastName}
                        <br/>
                        อีเมล: {data.email}
                        <br/>
                        companyId: {data.companyId}     
                        <br/>
                        oid: {data.oid}                                     
                        <br/>
                        <br/>
                        <br/>

                        <Stack horizontal style={stackStyles} tokens={{ childrenGap: 20 }} >
                        { isLoadComplete &&
                            <Dropdown                            
                                //placeholder="กรุณาเลือก"
                                label="ประเภทเอกสาร"
                                options={[
                                    { key: 'receipt-taxinvoice', text: 'ใบเสร็จรับเงิน/ใบกำกับภาษี' },                                
                                    { key: 'receipt-taxinvoice-abb', text: 'ใบเสร็จรับเงิน/ใบกำกับภาษีอย่างย่อ' },                                
                                    { key: 'receipt', text: 'ใบเสร็จรับเงิน' },
                                    { key: 'invoice-taxinvoice', text: 'ใบแจ้งหนี้/ใบกำกับภาษี' },
                                    { key: 'deliveryorder-taxinvoice', text: 'ใบส่งของ/ใบกำกับภาษี' },
                                    { key: 'taxinvoice', text: 'ใบกำกับภาษี' },
                                    { key: 'debit-note', text: 'ใบเพิ่มหนี้' },                                
                                    { key: 'credit-note', text: 'ใบลดหนี้' },  
                                    { key: 'quotation', text: 'ใบเสนอราคา' }, 
                                    { key: 'invoice', text: 'ใบแจ้งหนี้' },  
                                    { key: 'billing-note', text: 'ใบวางบิล' },                                
                                    { key: 'deliveryorder', text: 'ใบส่งของ' },
                                ]}
                                //required
                                styles={{dropdown: { width: 260 }}}
                                selectedKey={documentType.key}
                                onChange={ 
                                    (e, selectedOption) => {
                                        //console.log(selectedOption);
                                        //setChoosedProvince(selectedOption.key);
                                        setChooseDocument(true);
                                        setDocumentType(selectedOption);
                                        

                                        //console.log('Province changed', districtList);

                                        
                                    }                                 
                                                                                        
                                } 
                            />
                        }
                        { isLoadComplete && 
                            <PrimaryButton 
                                //type="submit"
                                //name="submit"

                                onClick={ () => { 
                                        //window.location.href = '/billing';                                  
                                        

                                        history.push({
                                            //pathname: '/billing',
                                            pathname: '/templates/pdf/preview',
                                            //search: '?update=true',  // query string
                                            state: {
                                                "htmlTemplate": html,
                                                "data": exampleData,
                                                "documentType": {
                                                    "key": documentType.key,
                                                    "text": documentType.text
                                                },                                            
                                                "companyId": data.companyId,
                                                "oid": data.oid,
                                                "companyName": data.companyName,
                                                "firstName": data.firstName,
                                                "lastName": data.lastName,                                          
                                                "email": data.email,
                                            }
                                        }); 


                                    } 
                                }  
                                
                                text="ดูตัวอย่างเอกสาร" 

                                iconProps={{ iconName: 'Preview' }}
                            />
                        }
                        { isLoadComplete && 
                            <DefaultButton 
                                //type="submit"
                                //name="submit"

                                onClick={ () => { 
                                        //window.location.href = '/billing';

                                        let confirm = window.confirm('กรุณายืนยัน การรีเซ็ตรูปแบบเอกสาร ' + documentType.text + ' (รูปแบบเอกสารจะกลับไปเป็นรูปแบบตั้งต้น)');

                                        if (confirm) {

                                            deleteTemplate();
                                        }

    /* 
                                        history.push({
                                            //pathname: '/billing',
                                            pathname: '/etax/templates/pdf/preview',
                                            //search: '?update=true',  // query string
                                            state: {
                                                "htmlTemplate": html,
                                                "data": exampleData,
                                                "documentType": {
                                                    "key": documentType.key,
                                                    "text": documentType.text
                                                }                                            
                                            }
                                        }); 
    */

                                    } 
                                }  
                                
                                text="รีเซ็ตเอกสาร" 

                                iconProps={{ 
                                    iconName: 'Refresh', 
    /* 
                                    styles: {
                                        root: { color: 'black' }
                                    } 
                                    */
                                }}
                                
                            />
                        }
                        { !isLoadComplete && <Spinner label="กำลังโหลดข้อมูล..." labelPosition="right" /> }
                        </Stack>
                        <br />
                        
                        <Editor
                            apiKey="31xz8igro2agp8bh7luzjvsnr0x852rg8bafgrh1rtsbmmoa"
                            onInit={(evt, editor) => editorRef.current = editor}
                            //initialValue="<p>Initial content</p>"
                            //initialValue={html}
                            value={html}
                            init={{
                                //mode : "textareas",
                                //theme : "advanced",
                                //force_br_newlines : false,
                                //force_p_newlines : false,
                                //forced_root_block : '',
                                //keep_styles: true,
                                //verify_html : false,
                                //verify_css_classes : true,
                                //cleanup : false,
                                //cleanup_on_startup : false,
                                //allow_conditional_comments: true,
                                //fullscreen_native: true,
                                //fullpage_default_font_size: '14px',
                                //fullpage_default_font_family: "'Sarabun', sans-serif;",
                                language: 'th_TH',
                                height: 500,
                                menubar: false,
                                //extended_valid_elements:"style,link[href|rel]",
                                extended_valid_elements:'style,page,page[size="A4"],page[size="A4"][layout="landscape"],page[size="A3"],page[size="A3"][layout="landscape"],page[size="A5"],page[size="A5"][layout="landscape"]',
                                //custom_elements:"style,link,~link"
                                custom_elements:'style,page,page[size="A4"],page[size="A4"][layout="landscape"],page[size="A3"],page[size="A3"][layout="landscape"],page[size="A5"],page[size="A5"][layout="landscape"]',
/* 
                            plugins: [
                                'advlist autolink lists link image', 
                                'charmap print preview anchor help',
                                'searchreplace visualblocks code codesample',
                                'insertdatetime media table paste wordcount'
                            ],
                             

                                plugins: [
                                    "advlist autolink autosave link image lists charmap print preview hr anchor pagebreak spellchecker",
                                    "searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking",
                                    "table contextmenu directionality emoticons template textcolor paste fullpage fullscreen"
                                ],
*/
                                plugins: [
                                    "advlist autolink autosave link image lists charmap print preview hr anchor pagebreak spellchecker",
                                    "searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking",
                                    "table contextmenu directionality emoticons template textcolor paste fullpage fullscreen"
                                ],


                                toolbar:
                                    'undo redo | formatselect | bold italic | \
                                    alignleft aligncenter alignright | \
                                    bullist numlist outdent indent | code fullscreen ',
                            
/*                             
                            setup: function (editor) {
                                editor.on("keyup", function (e) {
                                    setHtml(editor.getContent());
                                });
 
                                editor.on("change", function (e) {
                                    console.log(editor);
                                    setHtml(editor.getContent());
                                });
                                 
                            }
*/
/*                                
                            toolbar:
                                'undo redo | formatselect | bold italic | \
                                alignleft aligncenter alignright | \
                                bullist numlist outdent indent | help'
                            
 
                            plugins: 'a11ychecker advcode casechange formatpainter linkchecker autolink lists checklist media mediaembed pageembed permanentpen powerpaste table advtable tinycomments tinymcespellchecker',
                            toolbar: 'a11ycheck addcomment showcomments casechange checklist code formatpainter pageembed permanentpen table',
                            selector: 'textarea',
                            toolbar_mode: 'floating',
                            tinycomments_mode: 'embedded',
                            tinycomments_author: 'Author name',
                            
*/
                            }}
                            onEditorChange={handleChange}
                            //onChange={handleEditorChange}
                        />
                        <br />   
                        <h3>HTML Code</h3>
                        <CodeMirror
                            value={html}
                            options={{
                                mode: 'xml',
                                theme: 'material',
                                lineNumbers: true,
                                /* lineWrapping: true,
                                indentWithTabs: false,
                                tabSize: 2,
                                viewportMargin: Infinity, */
                                
                            }}

                            onBeforeChange={(editor, data, value) => {
                                console.log('onBeforeChange');
                                setHtml(value);
                                updateEditor(value);
                                
                            }}
                            
                            onChange={(editor, data, value) => {
                                console.log('onChange');
                                //setHtml(value);
                                //setHtml(value);
                                //updateEditor(value);
                                
                            }}
                            
/* 
                            onAfterChange={(editor, data, value) => {
                                console.log('onAfterChange');
                                setHtml(value);
                            }}
 
                            //onChange={(editor, data, value) => {
                            onKeyUp={(editor, event) => {
                                console.log('onChange');
                                console.log(editor);
                                //console.log('type code!');
                                //setHtml(value);
                                updateEditor();
                            }}

 */                           
                        />                     
                        
                        <br />
                        <h3>ตัวอย่างข้อมูล {documentType.text} <a href={referenceUrl} target="_blank"><small>(คำอธิบาย JSON Parameters)</small></a></h3>
                        
                        {/* <br /><br /> */}
                        <CodeMirror
                            value={exampleData}
                            options={{
                                //mode: 'javascript',
                                mode: {
                                    name: "javascript",
                                    json: true,
                                    statementIndent: 2
                                },
                                theme: 'material',
                                //lineNumbers: true,
                                lineNumbers: true,
                                lineWrapping: true,
                                indentWithTabs: false,
                                tabSize: 2,
                                viewportMargin: Infinity,
                            }}

                            onBeforeChange={(editor, data, value) => {
                                console.log('onBeforeChange');
                                setExampleData(value);
                            }}
                            
                            onChange={(editor, data, value) => {
                                console.log('onChange');
                                //setHtml(value);
                                //setHtml(value);
                                //updateEditor(value);
                                
                            }}
                            
/* 
                            onAfterChange={(editor, data, value) => {
                                console.log('onAfterChange');
                                setHtml(value);
                            }}
 
                            //onChange={(editor, data, value) => {
                            onKeyUp={(editor, event) => {
                                console.log('onChange');
                                console.log(editor);
                                //console.log('type code!');
                                //setHtml(value);
                                updateEditor();
                            }}

 */                           
                        />                        
                        
                        <br />
{/*                         <br />

                        <PrimaryButton 
                            //type="submit"
                            //name="submit"

                            onClick={ () => { 
                                    //window.location.href = '/billing';

                                    history.push({
                                        //pathname: '/billing',
                                        pathname: '/etax/templates/pdf/preview',
                                        //search: '?update=true',  // query string
                                        state: {
                                            "htmlTemplate": html,
                                            "data": exampleData,
                                            "documentType": {
                                                "key": documentType.key,
                                                "text": documentType.text
                                            }                                            
                                        }
                                    }); 


                                } 
                            }  
                            
                            text="ดูตัวอย่างเอกสาร" 
                        />
                         
                        <br />
                        
                       */}
                        
                        
                    {/* </div> */}
                    
                
                </div>
               {/*  </center> */}
                    
                <br/>
                <br/>
                
            </form>
            
        </Stack>
    );
}

export default PdfTemplate;