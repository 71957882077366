import React, { useState } from 'react';
//import { Stack } from '@fluentui/react';
import XMLViewer from 'react-xml-viewer';
//import './DigitalSignature.css';

//const format = require('xml-formatter');


export default function XmlAllViewer(props) {
  
    const { xml } = props;  
    /* 
    const customTheme = {
        "overflowBreak": true
    }
 */
    //let reader = new FileReader(); 

    //const formattedXml = format(xml);

    //console.log('Formatted Xml :', formattedXml)

    return (
        <div style={{ backgroundColor: 'white', width: '950px', padding: '25px' }}>
            {/* {formattedXml} */}            
            <XMLViewer xml={xml} collapsible={true} theme={{ "overflowBreak": true }} />
            
        </div>
    );
}