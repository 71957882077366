import React, { useState, useEffect } from "react";
import axios from "axios";

import { useMsal, useAccount } from "@azure/msal-react";
import { silentRequest } from "../../../../authConfig";
// import { useTranslation } from "react-i18next";

import {
    Stack,
    Icon,
    PrimaryButton,
    TextField,
    FocusTrapZone,
    Spinner,
    Link,
    Dialog,
    DialogType,
    DialogFooter,
    DefaultButton,
} from "@fluentui/react";

import { Buffer } from "buffer";

import { useHistory, useLocation } from "react-router-dom";

// const API_URL = process.env.REACT_APP_API_URL;
const ADMIN_API_URL = process.env.REACT_APP_ADMIN_API_URL;

// Processing dialog style
const spinnerStyles = {
    circle: {
        height: 56,
        width: 56,
        borderWidth: 4,
    },
};

const XmlDeliver = () => {
    // const { t } = useTranslation();

    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});

    const history = useHistory();
    const location = useLocation();

    const [data] = useState(location.state);

    const [companyId, setCompanyId] = useState("");

    const [user, setUser] = useState("");
    const [password, setPassword] = useState("");

    const [isLoading, setIsLoading] = useState(true);

    const [status, setStatus] = useState("");

    const [dialogConfirm, setDialogConfirm] = useState({
        isClick: false,
        header: "",
        message: "",
        onConfirm: null,
        onCancel: null,
    });

    // const companyId = sessionStorage.getItem("companyId") || "";

    useEffect(() => {
        console.log("useEffect data call!: ", data);

        if (data !== undefined) {
            //console.log('useEffect data call! not undefied.');

            console.log("data: ", data);
            setCompanyId(data.companyId);
        } else {
            history.push({ pathname: "/accounts/prepaid", state: {} });
        }
    }, [data]);

    const handleSubmit = (e) => {
        e.preventDefault();

        setIsLoading(true);

        console.log("user: ", user);

        //Encode base64
        let buff = Buffer.from(password);
        let base64pwd = buff.toString("base64");

        instance
            .acquireTokenSilent({ ...silentRequest, account: account })
            .then((tokenResponse) => {
                axios
                    .post(
                        ADMIN_API_URL + "/etax/users",
                        {
                            etax: user,
                            code: base64pwd,
                        },
                        {
                            headers: {
                                Authorization: "Bearer " + tokenResponse.accessToken,
                                "User-Oid": data.oid,
                                "User-Cid": data.companyId,
                            },
                        }
                    )
                    .then(
                        (response) => {
                            console.log(response);

                            if (response.data) {
                                if (response.data.loginSuccess) {
                                    setStatus("success");
                                } else {
                                    setStatus("fail");
                                }
                            }

                            setUser("");
                            setPassword("");
                            setIsLoading(false);
                        },
                        (error) => {
                            console.log(error);
                        }
                    );
            })
            .catch((error) => {
                //Acquire token silent failure, and send an interactive request
                console.log(error);
                instance.acquireTokenRedirect(silentRequest);
            });
    };

    const disconnect = () => {
        setIsLoading(true);

        instance
            .acquireTokenSilent({ ...silentRequest, account: account })
            .then((tokenResponse) => {
                axios
                    .patch(
                        ADMIN_API_URL + "/etax/users",
                        {
                            replace: "/disabled",
                            value: true,
                        },
                        {
                            headers: {
                                Authorization: "Bearer " + tokenResponse.accessToken,
                                "User-Oid": data.oid,
                                "User-Cid": data.companyId,
                            },
                        }
                    )
                    .then(
                        (response) => {
                            console.log(response);

                            if (response.data) {
                                if (response.data.disabled) {
                                    setStatus("");
                                }
                            }

                            setIsLoading(false);
                        },
                        (error) => {
                            console.log(error);
                        }
                    );
            })
            .catch((error) => {
                //Acquire token silent failure, and send an interactive request
                console.log(error);
                instance.acquireTokenRedirect(silentRequest);
            });
    };

    useEffect(() => {
        console.log("userEffect retrieve users data!");

        instance
            .acquireTokenSilent({ ...silentRequest, account: account })
            .then((tokenResponse) => {
                axios
                    .get(ADMIN_API_URL + "/etax/users", {
                        headers: {
                            Authorization: "Bearer " + tokenResponse.accessToken,
                            "User-Oid": data.oid,
                            "User-Cid": data.companyId,
                        },
                    })
                    .then((response) => {
                        console.log("Etax users: ", response);

                        if (response.data) {
                            if (!response.data.disabled && response.data.canLogin) {
                                setStatus("connect");
                            }
                        }

                        setIsLoading(false);
                    })
                    .catch((error) => {
                        console.log(error);

                        setIsLoading(false);
                    });
            })
            .catch((error) => {
                //Acquire token silent failure, and send an interactive request
                console.log(error);
                instance.acquireTokenRedirect(silentRequest);
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Stack>
            <h1 style={{ marginLeft: "0px", marginTop: "27px" }}>
                <Icon iconName="BulkUpload" style={{ color: "#106ebe" }} />{" "}
                {"การนำส่งไฟล์ XML (อัตโนมัติ)"}
            </h1>
            <h3 style={{ marginTop: "0px", marginBottom: "10px" }}>
                {"เชื่อมต่อ ระบบ Leceipt กับ e-Tax เพื่อนำส่งไฟล์ XML อัตโนมัติ"}
            </h3>
            <br />
            ชื่อบริษัท: {data.companyName}
            <br />
            ชื่อ: {data.firstName} {data.lastName}
            <br />
            อีเมล: {data.email}
            <br />
            <br />
            companyId: {data.companyId}
            <br />
            oid: {data.oid}
            <br />
            <br />
            <br />
            {status === "" && !isLoading && (
                <FocusTrapZone disabled={true}>
                    <Stack horizontalAlign="center">
                        <Stack
                            style={{
                                border: "1px solid rgb(134, 134, 134)",
                                width: "350px",
                                backgroundColor: "#FFFFFF",
                            }}
                        >
                            <br />
                            <br />

                            <Stack horizontal horizontalAlign="center">
                                <img src="/images/e-tax-logo.png" width="200px" alt="etaxlogo" />
                            </Stack>
                            <br />
                            <br />
                            <form onSubmit={handleSubmit}>
                                <Stack horizontal horizontalAlign="center">
                                    <TextField
                                        label={"ชื่อผู้ใช้งาน ระบบ e-Tax"}
                                        required
                                        onChange={(e) => {
                                            setUser(e.target.value);
                                        }}
                                        value={user}
                                        style={{ width: "250px" }}
                                    />
                                </Stack>

                                <Stack horizontal horizontalAlign="center">
                                    <TextField
                                        label={"รหัสผ่าน"}
                                        type="password"
                                        canRevealPassword
                                        revealPasswordAriaLabel={"แสดงรหัส"}
                                        required
                                        onChange={(e) => {
                                            setPassword(e.target.value);
                                        }}
                                        value={password}
                                        style={{ width: "220px" }}
                                    />
                                </Stack>

                                <br />
                                <br />
                                <Stack
                                    horizontal
                                    horizontalAlign="center"
                                    tokens={{ childrenGap: "30px" }}
                                >
                                    <PrimaryButton
                                        style={{ fontSize: "20px", height: "50px", width: "255px" }}
                                        type="submit"
                                    >
                                        {"เชื่อมต่อ"}
                                    </PrimaryButton>
                                </Stack>
                            </form>
                            <br />
                            <br />
                        </Stack>
                    </Stack>
                </FocusTrapZone>
            )}
            {status === "connect" && !isLoading && (
                <Stack horizontal horizontalAlign="center">
                    <Stack>
                        <br />
                        <Stack horizontal horizontalAlign="center">
                            <h1 style={{ margin: "0px", color: "#0078D4" }}>
                                {"เชื่อมต่อสำเร็จ!"}
                            </h1>
                        </Stack>

                        <br />
                        <br />
                        <Stack horizontal horizontalAlign="center">
                            <img
                                height="90px"
                                style={{ marginTop: "5px" }}
                                src="/images/xml-pc.png"
                                alt="xmlpc"
                            />
                            <img
                                height="70px"
                                width="100"
                                style={{ marginTop: "15px" }}
                                src="/images/arrow-right.svg"
                                alt="arrowright"
                            />
                            <img height="100px" src="/images/e-tax-logo.png" alt="etaxlogopng" />
                        </Stack>
                        <br />
                        <br />
                        <center>
                            <Link
                                onClick={() =>
                                    setDialogConfirm((prev) => ({
                                        ...prev,
                                        isClick: true,
                                        header: "ยกเลิกนำส่งไฟล์ XML อัตโนมัติ",
                                        message:
                                            "กรุณายืนยัน การยกเลิกนำส่งไฟล์ XML อัตโนมัติ (ไฟล์ที่สร้างก่อนยกเลิกจะยังคงนำส่งแบบอัตโนมัติ)",
                                        onConfirm: () => {
                                            disconnect();
                                            setDialogConfirm((prev) => ({
                                                ...prev,
                                                isClick: false,
                                            }));
                                        },
                                        onCancel: () =>
                                            setDialogConfirm((prev) => ({
                                                ...prev,
                                                isClick: false,
                                            })),
                                    }))
                                }
                            >
                                {"ยกเลิกการเชื่อมต่อ"}
                            </Link>
                            <br />
                        </center>
                    </Stack>
                </Stack>
            )}
            {isLoading && (
                <Stack>
                    <Stack horizontal horizontalAlign="center">
                        <br />
                        <br />
                        <div className="certificates-loading-center">
                            {password !== "" ? (
                                <Spinner
                                    label={"กำลังดำเนินการ ใช้เวลาประมาณ 2-5 นาที..."}
                                    labelPosition="down"
                                    styles={spinnerStyles}
                                />
                            ) : (
                                <Spinner
                                    label={"กำลังโหลด..."}
                                    labelPosition="down"
                                    styles={spinnerStyles}
                                />
                            )}
                        </div>
                        <br />
                    </Stack>
                </Stack>
            )}
            {status === "success" && (
                <Stack horizontal horizontalAlign="center">
                    <Stack
                        style={{
                            border: "1px solid rgb(134, 134, 134)",
                            width: "300px",
                            backgroundColor: "#FFFFFF",
                        }}
                    >
                        <br />
                        <br />
                        <Stack horizontal horizontalAlign="center">
                            <h1 style={{ margin: "0px", color: "#0078D4" }}>
                                {"เชื่อมต่อสำเร็จ!"}
                            </h1>
                        </Stack>
                        <br />
                        <Stack horizontal horizontalAlign="center">
                            <img height="120" src="/images/success03.gif" alt="success03gif" />
                        </Stack>
                        <br />
                        <br />
                        <Stack horizontal horizontalAlign="center">
                            <PrimaryButton
                                style={{ fontSize: "20px", height: "50px" }}
                                onClick={() => {
                                    setStatus("connect");
                                }}
                            >
                                {"รายละเอียด"}
                            </PrimaryButton>
                        </Stack>
                        <br />
                        <br />
                    </Stack>
                </Stack>
            )}
            {status === "fail" && (
                <Stack horizontal horizontalAlign="center">
                    <Stack
                        style={{
                            border: "1px solid rgb(134, 134, 134)",
                            width: "300px",
                            backgroundColor: "#FFFFFF",
                        }}
                    >
                        <br />
                        <br />
                        <Stack horizontal horizontalAlign="center">
                            <h1 style={{ margin: "0px", color: "#F25139" }}>{"ไม่สำเร็จ!"}</h1>
                        </Stack>
                        <br />
                        <Stack horizontal horizontalAlign="center">
                            <img height="120" src="/images/fail01.gif" alt="fail01gif" />
                        </Stack>
                        <br />
                        <br />
                        <Stack horizontal horizontalAlign="center">
                            <PrimaryButton
                                style={{ fontSize: "20px", height: "50px" }}
                                onClick={() => {
                                    setStatus("");
                                }}
                            >
                                {"ลองอีกครั้ง"}
                            </PrimaryButton>
                        </Stack>
                        <br />
                        <br />
                    </Stack>
                </Stack>
            )}
            <br />
            <br />
            <center>
                <br />
                <br />
                <span style={{ color: "gray" }}>
                    {"ระบบจะนำส่งไฟล์ XML อัตโนมัติ เฉพาะไฟล์ที่สร้างหลังจากทำการเชื่อมต่อ"}
                </span>
                <br />
                <br />
                <span style={{ color: "gray", fontWeight: "bold" }}>
                    {"ไฟล์ที่สร้างก่อนการเชื่อมต่อจะไม่ได้นำส่งอัตโนมัติ"}
                </span>
            </center>
            <Dialog
                hidden={!dialogConfirm.isClick}
                onDismiss={dialogConfirm.onCancel}
                styles={{ main: { minHeight: 0 } }}
                dialogContentProps={{
                    type: DialogType.largeHeader,
                    title: dialogConfirm.header,
                    styles: {
                        header: { textAlign: "center" },
                        title: { paddingRight: 24 },
                        subText: { textAlign: "center" },
                    },
                    subText: dialogConfirm.message,
                }}
            >
                <DialogFooter styles={{ actionsRight: { textAlign: "center" } }}>
                    <PrimaryButton onClick={dialogConfirm.onConfirm} text={"ยืนยัน"} />
                    <DefaultButton onClick={dialogConfirm.onCancel} text={"ยกเลิก"} />
                </DialogFooter>
            </Dialog>
        </Stack>
    );
};

export default XmlDeliver;
