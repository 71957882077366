//import { HighContrastSelectorWhite } from '@fluentui/react';
import React, { useState, useEffect } from 'react';
//import { withRouter } from 'react-router-dom';
//import { CommandBar, ICommandBarItemProps, IButtonProps } from '@fluentui/react';
import { 
    Persona, 
    PersonaInitialsColor, 
    PersonaSize, 
    createTheme,
    Customizer,  
    CommandButton,
    IconButton,
    Stack
} from '@fluentui/react';

//import Auth from './adapters/Auth-test01';
import { useLocation, useHistory } from "react-router-dom";

import axios from 'axios';

import { useMsal, useAccount } from "@azure/msal-react";
import { silentRequest } from "../../authConfig";
import { profileEditingRequest, passwordResetRequest } from "../../authConfig";

import { ActionButton } from '@fluentui/react/lib/Button';

const API_URL = process.env.REACT_APP_API_URL;

const headerStyles = {    
        //width: '208',
        //height: '10vh',
        //width: 185,
        height: 70,
        width: '100%',
        //minWidth: '1377px',
        //width: '100%',
        //width: '100vw',
        //overflow: 'auto',
        //boxSizing: 'border-box',
        //border: '1px solid #eee',
        //overflowY: 'auto',
        //paddingTop: '10vh',    
        //background: '#FFFFFF',
        //background: '#106ebe',
        background: '#0078D4',
        //background: 'green',
        border: 0,
        paddingLeft: '12px',
        //paddingTop: '1%',
        paddingTop: '15px',
        margin: 0,
        color: '#FFFFFF',
        fontSize: 26,
        
}

// For color persona text to white
const HeaderTheme = createTheme({
    palette: {
      "themePrimary": "#0078d4",
      "themeLighterAlt": "#eff6fc",
      "themeLighter": "#deecf9",
      "themeLight": "#c7e0f4",
      "themeTertiary": "#71afe5",
      "themeSecondary": "#2b88d8",
      "themeDarkAlt": "#106ebe",
      "themeDark": "#005a9e",
      "themeDarker": "#004578",
      "neutralLighterAlt": "#0b0b0b",
      "neutralLighter": "#151515",
      "neutralLight": "#252525",
      "neutralQuaternaryAlt": "#2f2f2f",
      "neutralQuaternary": "#373737",
      "neutralTertiaryAlt": "#595959",
      "neutralTertiary": "#c8c8c8",
      "neutralSecondary": "#d0d0d0",
      "neutralPrimaryAlt": "#dadada",
      "neutralPrimary": "#ffffff",
      "neutralDark": "#f4f4f4",
      "black": "#f8f8f8",
      "white": "#000000",
      "primaryBackground": "#000000",
      "primaryText": "#ffffff",
      "bodyBackground": "#000000",
      "bodyText": "#ffffff",
      "disabledBackground": "#151515",
      "disabledText": "#595959"
    }
});



/* 
const userInformation = () => {
    const auth = new Auth();
    if (auth.isLoggedIn()) {
        //console.log(auth.currentUser());
        given_name = JSON.parse(auth.currentUser()).given_name;
        //console.log(given_name);
    }
}
 */


const Header = (props) => {
    const history = useHistory();

    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});

    const [companyName, setCompanyName] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');

    const [command, setCommand] = useState('');

    const { accountData } = props;
    const { companyData } = props;

    const menuProps = {
        items: [   
            {
                name: 'ผู้ใช้งาน',
                url: '',
                key: 'user-profiles',
                iconProps: {
                    iconName: 'PlayerSettings',
                    styles: {
                        root: {
                            fontSize: 20,
                            color: '#106ebe',
                        }
                    }
                },
                onClick: (event, element) => {
                    //console.log('user-profiles!');
                    console.log(element.key);
                    setCommand(element.key);
                }
                
            },                    
            {
                name: 'เปลี่ยนรหัสผ่าน',
                url: '',
                key: 'password-reset',
                iconProps: {
                    iconName: 'Permissions',
                    styles: {
                        root: {
                            fontSize: 20,
                            color: '#106ebe',
                        }
                    }
                },
                onClick: (event, element) => {
                    //console.log('user-profiles!');
                    console.log(element.key);
                    setCommand(element.key);
                }
            },   
                            
            {
        
                name: 'ออกจากระบบ',
                url: '',
                key: 'logout',        
                iconProps: {
                    iconName: 'Leave',
                    styles: {
                        root: {
                            fontSize: 20,
                            color: '#106ebe',
                        }
                    }
                },
                onClick: (event, element) => {
                    //console.log('user-profiles!');
                    console.log(element.key);
                    setCommand(element.key);
                }
            }
        ]
    /* 
        items: [
          {
            key: 'emailMessage',
            text: 'Email message',
            iconProps: { iconName: 'Mail' },
          },
          {
            key: 'calendarEvent',
            text: 'Calendar event',
            iconProps: { iconName: 'Calendar' },
          },
        ],
        */ 
        // By default, the menu will be focused when it opens. Uncomment the next line to prevent this.
        // shouldFocusOnMount: false
      };


    //let given_name = '';
    //initializeIcons();
    //userInformation();
  /*   const insertUser = () => {
        console.log('Insert user!');
    } */

    useEffect(() => {
        if (command === 'logout') {

            let confirmLogout = window.confirm('กรุณายืนยันการออกจากระบบ');

            if (confirmLogout) {

                instance.logout();

            } else {
                setCommand('');
            }

        } else if (command === 'user-profiles') {

            instance.loginRedirect(profileEditingRequest);

            //instance.acquireTokenRedirect(profileEditingRequest);
/* 
            history.push({
                //pathname: '/etax/signatures/register/companies',
                pathname: '/profiles/update',
                //search: '?update=true',  // query string
                state: {}
            })
 */
        } else if (command === 'password-reset') {

            instance.loginRedirect(passwordResetRequest);

        }  
    }, [command]);

    useEffect(() => {
        console.log('userEffect Call!');

        if (accountData && accountData.profiles.name) {
            setName(accountData.profiles.name);
            setEmail(accountData.profiles.emails[0]);
        }


    }, []);

    return (    

        <Stack horizontal style={headerStyles}> 
        
            <Stack vertical style={{ width: '310px' }}>
                <Stack horizontal horizontalAlign="space-between">
                    {/* <IconButton iconProps={{ iconName: 'GlobalNavButton' }} title="ย่อ/ขยายเมนู" checked={true} /> */}
                    <ActionButton onClick={() => {
                            history.push({
                                pathname: '/',
                                //search: '?update=true',  // query string
                                state: {}
                            });
                        }}>
                        {/* <img src="/images/leceipt-logo.png" width="200px"/> */}
                        <img src="/images/leceipt-logo.png" height="55px"/>
                        
                    </ActionButton>
                </Stack>
            </Stack>
          
            <Stack vertical style={{ width: '100%', paddingLeft: '50px', background: '#0078D4', margin: 0 }}>
                <Stack horizontal horizontalAlign="space-between" disableShrink={true}  >
                    <Stack vertical >
                        <span style={{ marginTop: '2px' }}>Admin</span>
                    </Stack>
                
                    {/* {accountData.profiles.name} */}

                    <CommandButton menuProps={menuProps} style={{ paddingRight: '2%' }}>
                        <Customizer settings={{ theme: HeaderTheme }}>
                            <Persona secondaryText={name} initialsColor={PersonaInitialsColor.darkGreen} initialsTextColor="white" text={email} size={PersonaSize.size40} />
                        </Customizer>
                    </CommandButton>

                </Stack>
            </Stack>


        </Stack>
    );
}

export default Header;