import React, { useState, useEffect } from 'react';

import axios from 'axios';
import { useMsal } from '@azure/msal-react';
import { silentRequest } from '../../../authConfig';

import {
  DetailsListLayoutMode,
  ShimmeredDetailsList,
  SelectionMode,
//   Calendar,
//   DateRangeType,
  Stack,
  Text,
  Selection,
  CheckboxVisibility,
  DefaultButton,
  PrimaryButton,
  ContextualMenu,
//   TooltipHost,
  Dialog,
  DialogFooter,
  DialogType,
  DatePicker,
  TextField,
  Dropdown,
  Spinner,
  SpinnerSize,
  CommandBar,
//   SearchBox,
  MessageBarType,
  Link,
  MessageBar,
} from '@fluentui/react';

// import { Pagination } from '@fluentui/react-experiments/lib/Pagination';
import { Pagination } from "@fluentui/react-experiments/lib/Pagination";
import paginate from 'jw-paginate';

const homeStyles = {
  border: 0,
  margin: 0,
  width: '100%',
  background: '#FAF9F8',
};

// const API_URL = process.env.REACT_APP_API_URL;
const ADMIN_API_URL = process.env.REACT_APP_ADMIN_API_URL;

const onFormatDate = (date, format) => {
  if (format === 'CE') {
    return `${date.getDate()}`.padStart(2, '0') + '/' + `${date.getMonth() + 1}`.padStart(2, '0') + '/' + date.getFullYear();
  } else {
    return `${date.getDate()}`.padStart(2, '0') + '/' + `${date.getMonth() + 1}`.padStart(2, '0') + '/' + (date.getFullYear() + 543);
  }
};

const padLeadingZeros = (num, size) => {
  var s = num + "";
  while (s.length < size) s = "0" + s;
  return s;
};

const packagesOptions = [
  {
    key: "",
    text: "ไม่ระบุ"
  },
  {
    key: "LECEIPT_MONTH_300",
    text: "รายเดือน 300 เอกสาร/เดือน"
  },
  {
    key: "LECEIPT_MONTH_500",
    text: "รายเดือน 500 เอกสาร/เดือน"
  },
  {
    key: "LECEIPT_MONTH_1000",
    text: "รายเดือน 1000 เอกสาร/เดือน"
  },
  {
    key: "LECEIPT_MONTH_3000",
    text: "รายเดือน 3000 เอกสาร/เดือน"
  },
  {
    key: "LECEIPT_MONTH_10000",
    text: "รายเดือน 10000 เอกสาร/เดือน"
  },
  {
    key: "LECEIPT_YEAR_300",
    text: "รายปี 300 เอกสาร/เดือน"
  },
  {
    key: "LECEIPT_YEAR_500",
    text: "รายปี 500 เอกสาร/เดือน"
  },
  {
    key: "LECEIPT_YEAR_1000",
    text: "รายปี 1,000 เอกสาร/เดือน"
  },
  {
    key: "LECEIPT_YEAR_3000",
    text: "รายปี 3,000 เอกสาร/เดือน"
  },
  {
    key: "LECEIPT_YEAR_10000",
    text: "รายปี 10,000 เอกสาร/เดือน"
  },
]

const companiesDetailEndpoint = `${ADMIN_API_URL}/companies-details?billingsType=subscription&from=2023-01-01&to=2024-04-30`
const companiesEndpoint = `${ADMIN_API_URL}/companies`

const CreateStatement = () => {
  // console.log('Home render!!!');

  const { instance } = useMsal();
  const account = instance.getAllAccounts()[0];

  const [documents, setDocuments] = useState([]);
  const [items, setItems] = useState([]);
  const [loadDataComplete, setLoadDataComplete] = useState(false);
  const [getNewDocuments, setGetNewDocuments] = useState(true);
  const [pageData, setPageData] = useState({});
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(sessionStorage.getItem('currentPage') * 1 || 1);

  const [dialogCreateStatement, setDialogCreateStatement] = useState({
    isClick: false,
    fromDate: null,
    toDate: null,
    suffix: "",
    packageId: "",
    onSubmit: () => null
  });

  const [isCreateDocument, setIsCreateDocument] = useState(false);
  const [isCreateDocumentComplete, setIsCreateDocumentComplete] = useState(false);

  const [selectedItems, setSelectedItems] = useState([]);
  const [selection] = useState(
    new Selection({
      onSelectionChanged: () => {
        let _selectedItems = selection.getSelection();

        setSelectedItems(_selectedItems);
      },
    })
  );

  const [fromDateStatement, setFromDateStatement] = useState(null)
  const [toDateStatement, setToDateStatement] = useState(null)
  const [suffix, setSuffix] = useState("")
  const [packageId, setPackageId] = useState("")

  const [copySuccess, setCopySuccess] = useState(false);

  const getFromDMY = (day, month, year) => {
    let _firstDay = new Date(year, month, day);

    return formatDate(_firstDay);
  };

  const getToDMY = (day, month, year) => {
    let _lastDay = new Date(year, month, day + 1);

    return formatDate(_lastDay);
  };

  const DayPickerStrings = {
    months: [
      "มกราคม",
      "กุมภาพันธ์",
      "มีนาคม",
      "เมษายน",
      "พฤษภาคม",
      "มิถุนายน",
      "กรกฎาคม",
      "สิงหาคม",
      "กันยายน",
      "ตุลาคม",
      "พฤศจิกายน",
      "ธันวาคม",
    ],

    shortMonths: [
      'ม.ค.',
      'ก.พ.',
      'มี.ค.',
      'เม.ย.',
      'พ.ค.',
      'มิ.ย.',
      'ก.ค.',
      'ส.ค.',
      'ก.ย.',
      'ต.ค.',
      'พ.ย.',
      'ธ.ค.',
    ],

    days: ['อาทิตย์', 'จันทร์', 'อังคาร', 'พุธ', 'พฤหัสบดี', 'ศุกร์', 'เสาร์'],

    shortDays: ['อา.', 'จ.', 'อ.', 'พ.', 'พฤ.', 'ศ.', 'ส.'],

    goToToday: 'ไปวันที่ปัจจุบัน',
    prevMonthAriaLabel: 'ไปเดือนที่แล้ว',
    nextMonthAriaLabel: 'ไปเดือนถัดไป',
    prevYearAriaLabel: 'ไปปีที่แล้ว',
    nextYearAriaLabel: 'ไปปีถัดไป',
    closeButtonAriaLabel: 'ปิด',
    monthPickerHeaderAriaLabel: '{0}, เลือกเพื่อเปลี่ยนปี',
    yearPickerHeaderAriaLabel: '{0}, เลือกเพื่อเปลี่ยนเดือน',

    isRequiredErrorMessage: 'กรุณาเลือกวันที่',

    invalidInputErrorMessage: 'รูปแบบวันที่ไม่ถูกต้อง',
  };


  const formatDate = (d) => {
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    let year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  };

  const [pageDropdown, setPageDropdown] = useState([]);

  const commandBarItems = [
    {
      key: 'pageSize ' + pageSize,
      text: pageSize + ' ' + "ออเดอร์/หน้า",
      cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
      iconProps: { iconName: 'NumberedList' },
      disabled: !loadDataComplete || isCreateDocument,
      subMenuProps: {
        items: [
          {
            key: 'pageSize ' + 10,
            text: '10 ' + "ออเดอร์/หน้า",
            iconProps: { iconName: 'NumberedList' },
            onClick: () => {
              console.log('10 ออเดอร์/หน้า');
              setPageSize(10);
              sessionStorage.setItem('pageSize', 10);

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setCurrentPage(1);
            },
          },
          {
            key: 'pageSize ' + 20,
            text: '20 ' + "ออเดอร์/หน้า",
            iconProps: { iconName: 'NumberedList' },
            onClick: () => {
              console.log('20 ออเดอร์/หน้า');
              setPageSize(20);
              sessionStorage.setItem('pageSize', 20);

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setCurrentPage(1);
            },
          },
          {
            key: 'pageSize ' + 30,
            text: '30 ' + "ออเดอร์/หน้า",
            iconProps: { iconName: 'NumberedList' },
            onClick: () => {
              console.log('30 ออเดอร์/หน้า');
              setPageSize(30);
              sessionStorage.setItem('pageSize', 30);

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setCurrentPage(1);
            },
          },
          {
            key: 'pageSize ' + 40,
            text: '40 ' + "ออเดอร์/หน้า",
            iconProps: { iconName: 'NumberedList' },
            onClick: () => {
              console.log('40 ออเดอร์/หน้า');
              setPageSize(40);
              sessionStorage.setItem('pageSize', 40);

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setCurrentPage(1);
            },
          },
          {
            key: 'pageSize ' + 50,
            text: '50 ' + "ออเดอร์/หน้า",
            iconProps: { iconName: 'NumberedList' },
            onClick: () => {
              console.log('50 ออเดอร์/หน้า');
              setPageSize(50);
              sessionStorage.setItem('pageSize', 50);

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setCurrentPage(1);
            },
          },
        ],
      },
    },
    {
      key: currentPage,
      text: 'หน้า ' + currentPage,
      iconProps: { iconName: 'Page' },
      disabled: !loadDataComplete || isCreateDocument,
      subMenuProps: {
        items: pageDropdown,
      },
    },
    {
      key: 'refresh',
      text: "รีเฟรช",
      iconProps: { iconName: 'Refresh' },
      disabled: !loadDataComplete || isCreateDocument,
      onClick: () => {
        console.log('Refresh');

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);

        setCurrentPage(1);

      },
    },
  ];

  useEffect(() => {
    console.log('userEffect getNewDocuments Call!');

    if (getNewDocuments) {
      instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then(async (tokenResponse) => {
        const companiesDetailResponse = await axios
                                        .get(companiesDetailEndpoint, {
                                          headers: {
                                            Authorization: 'Bearer ' + tokenResponse.accessToken,
                                            // 'cid': companyId,
                                          },
                                        })

        console.log("companiesDetailResponse", companiesDetailResponse)

        const companies = companiesDetailResponse.data

        setDocuments(companies)

        let _pageDropdown = [];

        for (let i = 1; i <= Math.ceil(companies.length / pageSize); i++) {
          _pageDropdown.push({
            key: i,
            text: "หน้า " + i,
            iconProps: { iconName: 'Page' },
            onClick: () => {
              console.log('Selected page: ' + i);
              setCurrentPage(i);
            },
          });
        }

        setPageDropdown(_pageDropdown);

        setPageData(paginate(companies.length, currentPage, pageSize, 10));

        const companiesIdList = [];
        for (let i = (currentPage - 1) * pageSize; i < Math.min(currentPage * pageSize, companies.length); i++) {
          companiesIdList.push(companies[i].id)
        }

        const companiesResponse = await axios
                                        .get(`${companiesEndpoint}?companiesIdList=${companiesIdList}`, {
                                          headers: {
                                            Authorization: 'Bearer ' + tokenResponse.accessToken,
                                            // 'cid': companyId,
                                          },
                                        })

        console.log("companiesResponse", companiesResponse)

        setItems(companiesResponse.data.map(_companyData => ({..._companyData, companiesDetails: companies.find(_companyDetail => _companyDetail.id === _companyData.id) || null})))

        setGetNewDocuments(false)
        setLoadDataComplete(true)

      }).catch(error => {
        console.log("error getNewDocuments", error)
      })
      
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getNewDocuments]);

  useEffect(() => {
    console.log("items", items)
  }, [items])
  

  useEffect(() => {
    if (documents.length > 0) {
      setItems([])
      setLoadDataComplete(false)

      const companiesIdList = [];

      for (let i = (currentPage - 1) * pageSize; i < Math.min(currentPage * pageSize, documents.length); i++) {
        companiesIdList.push(documents[i].id)
      }

      instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then(async (tokenResponse) => {
        const companiesResponse = await axios.get(`${companiesEndpoint}?companiesIdList=${companiesIdList}`, {
                                          headers: {
                                            Authorization: 'Bearer ' + tokenResponse.accessToken,
                                            // 'cid': companyId,
                                          },
                                        })

        console.log("companiesResponse", companiesResponse)

        setItems(companiesResponse.data.map(_companyData => ({..._companyData, companiesDetails: documents.find(_companyDetail => _companyDetail.id === _companyData.id) || null})))
        
        setGetNewDocuments(false)
        setLoadDataComplete(true)
      }).catch(error => {
        console.log("error getNewDocuments", error)
      })
    }
  }, [currentPage])

  useEffect(() => {
    console.log("isCreateDocumentComplete useEffect: ", isCreateDocumentComplete);

    if (loadDataComplete && !isCreateDocument) {
        let _items = selection.getItems();

        let _selectedItems = selection.getSelection();

        if (isCreateDocumentComplete) {
            setIsCreateDocumentComplete(false);

            for (let i = 0; i < _items.length; i++) {
                if (_items[i].statementStatus !== "") {
                    _items[i].statementStatus = "";
                }
            }
        } else {
            for (let i = 0; i < _items.length; i++) {
                if (_items[i].statementStatus !== "") {
                    _items[i].statementStatus = "";
                }
            }

            for (let i = 0; i < _selectedItems.length; i++) {
                for (let j = 0; j < _items.length; j++) {
                    if (_items[j].id === _selectedItems[i].id) {
                        _items[j].statementStatus = "pending";
                    }
                }
            }
        }

        setItems(_items);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [selectedItems]);

  const onPageChange = (selectedPageIndex) => {
    // console.log('selectedPageIndex: ', selectedPageIndex);
    setCurrentPage(selectedPageIndex + 1);
  };

  const columns = [
    {
      key: 'column1',
      name: "ชื่อบริษัท",
      fieldName: 'name',
      minWidth: 120,
      maxWidth: 280,
      isRowHeader: true,
      isResizable: true,
      data: 'string',
      onRender: (item) => {
        return <span>{item.name}</span>;
      },
      // isPadded: true,
    },
    {
      key: 'column2',
      name: "cid",
      fieldName: 'id',
      minWidth: 50,
      maxWidth: 50,
      isRowHeader: true,
      isResizable: true,
      data: 'string',
      onRender: (item) => {
        return (
          <div data-selection-disabled={true}>
            <Link onClick={() => {
              navigator.clipboard.writeText(item.id);
              setCopySuccess(true);
              window.scrollTo(0, 0);
            }}>
              {item.id}
            </Link>
          </div>
        );
      },
      // isPadded: true,
    },
    {
      key: 'column3',
      name: "อีเมล",
      fieldName: 'email',
      minWidth: 70,
      maxWidth: 200,
      isRowHeader: true,
      isResizable: true,
      isCollapsible: true,
      data: 'string',
      onRender: (item) => {
        return <span>{item.email}</span>
      },
      // isPadded: true,
    },
    {
      key: 'column4',
      name: "เบอร์โทร",
      fieldName: 'phone',
      minWidth: 70,
      maxWidth: 100,
      isRowHeader: true,
      isResizable: true,
      isCollapsible: true,
      data: 'string',
      onRender: (item) => {
        return <span>{item.phone}</span>
      },
      // isPadded: true,
    },
    {
      key: 'column5',
      name: "แพ็กเกจ",
      fieldName: 'package',
      minWidth: 120,
      maxWidth: 280,
      isRowHeader: true,
      isResizable: true,
      isCollapsible: true,
      data: 'string',
      onRender: (item) => {
        return <span>{item.companiesDetails?.subscriptions?.plan?.id ? packagesOptions.find(_option => _option.key === item.companiesDetails?.subscriptions?.plan?.id).text : "-"}</span>
      },
      // isPadded: true,
    },
    // {
    //   key: 'column4',
    //   name: "วันที่เริ่ม",
    //   fieldName: 'from',
    //   minWidth: 70,
    //   maxWidth: 150,
    //   isRowHeader: true,
    //   isResizable: true,
    //   data: 'number',
    //   onRender: (item) => {
    //     return <span>{item.companiesDetails?.subscriptions?.from ? toThaiDateString(item.companiesDetails?.subscriptions?.from) : "none"}</span>;
    //   },
    //   // isPadded: true,
    // },
    // {
    //   key: 'column5',
    //   name: "วันที่สิ้นสุด",
    //   fieldName: 'from',
    //   minWidth: 70,
    //   maxWidth: 150,
    //   isRowHeader: true,
    //   isResizable: true,
    //   data: 'number',
    //   onRender: (item) => {
    //     return <span>{item.companiesDetails?.subscriptions?.to ? toThaiDateString(item.companiesDetails?.subscriptions?.to) : "none"}</span>;
    //   },
    //   // isPadded: true,
    // },
    {
      key: "column11",
      name: "",
      fieldName: "download",
      minWidth: 90,
      maxWidth: 100,
      isResizable: true,
      data: "string",
      onColumnClick: "",
      onRender: (item) => {
        if (isCreateDocument || isCreateDocumentComplete) {
          if (item.statementStatus === "pending") {
            return <div style={{ height: 32}}>
              <span style={{ color: "#106EBE" }}>รอดำเนินการ</span>

            </div>
          } else if (item.statementStatus === "processing") {
            return (
              <Stack horizontal styles={{ root: { height: 32 }}}>
                <Spinner label="กำลังสร้าง..." labelPosition="right" />
              </Stack>
            )
          } else if (item.statementStatus === "successful") {
              return <div style={{ height: 32}}>
                <span style={{ color: "green" }}>สำเร็จ</span>

              </div>
          } else if (item.statementStatus === "fail") {
              return <div style={{ height: 32}}>
                <span style={{ color: "red" }}>ไม่สำเร็จ</span>

              </div>
          } else {
            return <div style={{ height: 32}} />
          }
        } else {
          return (
            <div data-selection-disabled={true}>
              <DefaultButton
                text="เลือก"
                disabled={isCreateDocument}
                menuProps={{
                  items: [
                    {
                      key: "create",
                      text: "สร้าง Statement",
                      iconProps: { iconName: "OpenFile" },
                      onClick: () => {
                        // setPackageId(item.companiesDetails?.subscriptions?.plan?.id ? item.companiesDetails?.subscriptions?.plan?.id : "")
  
                        // if (item.companiesDetails?.subscriptions?.from) {
                        //   setFromDate(new Date(item.companiesDetails?.subscriptions?.from))
                        //   setToDate(new Date(item.companiesDetails?.subscriptions?.to))
  
                        //   setCurrentPeriod(true)
                        // }
  
                        setDialogCreateStatement((prev) => ({
                          ...prev,
                          isClick: true,
                          header: "สร้าง Statement",
                          packageId: item.companiesDetails?.subscriptions?.plan?.id,
                          onSubmit: function () {
                            setIsCreateDocument(true)
                            handleCreateStatement([item], { fromDate: this.fromDate, toDate: this.toDate, suffix: this.suffix, packageId: this.packageId })

                            setDialogCreateStatement((prev) => ({
                              ...prev,
                              isClick: false,
                              onSubmit: () => null
                            }))
                          }
                        }))
                      },
                    }
                  ],
                  shouldFocusOnMount: true,
                  directionalHintFixed: true,
                }}
                // Optional callback to customize menu rendering
                menuAs={(props) => <ContextualMenu {...props} />}
              />
            </div>
          );

        }
      },
    }
  ];

  async function handleCreateStatement(_items, statementQuery) {
    const { fromDate, toDate, suffix, packageId } = statementQuery

    console.log("_items", _items)
    // console.log("toDate", toDate)
    // console.log("suffix", suffix)

    if (suffix) {
      // setDialogCreateStatement(prev => ({...prev, isClick: false}))

      setItems(prevItems => {
        const newItems = [...prevItems]
        _items.forEach(_item => {
          const updatedItemsIndex = prevItems.findIndex(_prevItem => _prevItem.id === _item.id)

          if (updatedItemsIndex > -1) {
            if (newItems[updatedItemsIndex].id === _items[0].id) {
              newItems[updatedItemsIndex].statementStatus = "processing"
            } else {
              newItems[updatedItemsIndex].statementStatus = "pending"

            }
          }
        })
  
        return newItems
      })

      let _countingNumberLength = suffix.length;
      let _countingNumber = Number(suffix);

      for (let i = 0; i < _items.length; i++) {
        const updatedItemsIndex = items.findIndex(item => item.id === _items[i].id)
        const newItems = [...items]

        newItems[updatedItemsIndex].statementStatus = "processing"

        setItems(newItems)

        try {

          const _from = getFromDMY(fromDate.getDate(), fromDate.getMonth(), fromDate.getFullYear());
          const _to = getToDMY(toDate.getDate(), toDate.getMonth(), toDate.getFullYear());
          // const _from = ""
          // const _to = ""
          

          let _suffix = padLeadingZeros(Number(_countingNumber), _countingNumberLength);
          _countingNumber = padLeadingZeros(Number(_countingNumber) + 1, _countingNumberLength);
          setSuffix(_countingNumber);

          const tokenResponse =  await instance.acquireTokenSilent({ ...silentRequest, account: account })
    
          const response = await axios.get(`http://localhost:7071/api/Statement_create?cid=${newItems[updatedItemsIndex].id}&from=${_from}&to=${_to}&suffix=${_suffix}&packageId=${packageId}&accessToken=${tokenResponse.accessToken}`, {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
              'cid': newItems[updatedItemsIndex].id,
              'Content-Type': 'application/json',
            },
            timeout: 300000
          })
      
          console.log("response", response)

          if (response.status === 200) {
            newItems[updatedItemsIndex].statementStatus = "successful"
          } else {
            newItems[updatedItemsIndex].statementStatus = "fail"
          }
  
          setItems(newItems)
    
        } catch (error) {
          console.log("error handleCreateStatement", error);

          newItems[updatedItemsIndex].statementStatus = "fail"
        }
      }

      setFromDateStatement(null)
      setToDateStatement(null)
      setSuffix("")
      setPackageId("")

      setIsCreateDocumentComplete(true);
      setIsCreateDocument(false);
    }
  }

  async function test() {
    // const tokenResponse =  await instance.acquireTokenSilent({ ...silentRequest, account: account })

    // // const _from = from
    // const _from = "2024-05-02"
    // // const _to = to
    // const _to = "2024-06-02"

    // const _packageId = "LECEIPT_MONTH_300"
  
    // try {
    //   const response = await axios.get(`http://localhost:7071/api/Statement_createV2?cid=${companyId}&from=${_from}&to=${_to}&suffix=${2}&packageId=${_packageId}&accessToken=${tokenResponse.accessToken}`, {
    //     headers: {
    //       Authorization: 'Bearer ' + tokenResponse.accessToken,
    //       'cid': companyId,
    //       'Content-Type': 'application/json',
    //     }
    //   })
  
    //   console.log("response", response)
      
    // } catch (error) {
    //   console.log("error", error)
    // }
  }

  const isCompactMode = false;

  return (
    <div style={homeStyles}>
      {copySuccess && 
        <MessageBar
          messageBarType={MessageBarType.success}
          isMultiline={false}
          onDismiss={() => {setCopySuccess(false)}}
        >
          Copy สำเร็จ!               
        </MessageBar>
      }
      <Stack>
        <center>
          <h2 style={{ marginLeft: '0px', marginTop: '25px' }}>สร้าง Statement</h2>
        </center>
      </Stack>

      {(selectedItems.length > 0 || isCreateDocument) && (
          <Stack vertical horizontalAlign="center"  tokens={{ childrenGap: "10px" }}>
              
              <Stack horizontalAlign="center" styles={{ root: { width: 288 }}} tokens={{ childrenGap: "10px" }}>
                <DatePicker
                  id='fromDate'
                  label='วันที่เริ่ม'
                  styles={{ root: { width: '100%' } }}
                  placeholder="เลือก"
                  strings={DayPickerStrings}
                  value={fromDateStatement}
                  maxDate={toDateStatement}
                  formatDate={onFormatDate}
                  onSelectDate={(_date) => {
                    setFromDateStatement(_date);

                    // setTimeRangeError(false);
                    // if (Math.ceil(Math.abs(_date - toDate) / (1000 * 60 * 60 * 24)) > 30) setTimeRangeError(true);
                  }}
                  disabled={isCreateDocument}
                />
                <DatePicker
                  id='toDate'
                  styles={{ root: { width: '100%' } }}
                  label="วันที่สิ้นสุด"
                  placeholder="เลือก"
                  strings={DayPickerStrings}
                  value={toDateStatement}
                  minDate={fromDateStatement}
                  formatDate={onFormatDate}
                  onSelectDate={(_date) => {
                    setToDateStatement(_date);

                    // setTimeRangeError(false);
                    // if (Math.ceil(Math.abs(_date - toDate) / (1000 * 60 * 60 * 24)) > 30) setTimeRangeError(true);
                  }}
                  disabled={isCreateDocument}
                />
                <TextField
                  name="suffix"
                  label="เลขท้ายเอกสาร"
                  type='number'
                  styles={{ root: { width: "100%" } }}
                  onChange={(e) => {
                    setSuffix(e.target.value);
                  }}
                  value={suffix}
                  validateOnLoad={false}
                  validateOnFocusOut
                  required
                  disabled={isCreateDocument}
                />
                <Dropdown
                  placeholder="เลือกแพ็กเกจ"
                  label="แพ็กเกจ"
                  // options={Object.keys(packagesList).map(_key => ({ key: _key, text: packagesList[_key].package_name_abb }))}
                  options={packagesOptions}
                  styles={{ root: { width: "100%" } }}
                  selectedKey={packageId}
                  onChange={(e, selectedOption) => {
                    setPackageId(selectedOption.key);
                  }}
                  required
                  disabled={isCreateDocument}
                />
              </Stack>
              
              {suffix.length > 0 ? (
                  <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: "10px" }}>
                      {!isCreateDocument ? (
                          <h4>
                              เลขที่เอกสารเริ่มต้นที่
                              {suffix}
                          </h4>
                      ) : (
                          <h4>
                              เลขที่เอกสารต่อไป
                              {suffix}
                          </h4>
                      )}
                  </Stack>
              ) : (
                  <br />
              )}
          </Stack>
      )}

      {selectedItems.length > 0 && !isCreateDocument && (
        <Stack>
          <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: '10px' }}>
            <div>
              <br />
              <PrimaryButton
                iconProps={{ iconName: 'Upload' }}
                text="สร้าง Statement"
                // disabled={!selectedItems.length}
                onClick={() => {
                  // setDialogCreateStatement((prev) => ({
                  //   ...prev,
                  //   isClick: true,
                  //   header: "สร้าง Statement",

                  // }));

                  setIsCreateDocument(true)
                  handleCreateStatement(selectedItems, { fromDate: fromDateStatement, toDate: toDateStatement, suffix, packageId })
                }}
              />
            </div>
          </Stack>
        </Stack>
      )}

      {isCreateDocument && (
        <div>
          <Spinner label="กำลังสร้าง Statement" size={SpinnerSize.large} />
        </div>
      )}

      <br />
      <Stack horizontal horizontalAlign="center">
        <Stack
          vertical
          style={{
            width: '98%',
            maxWidth: '1350px',
          }}
        >
          <Stack 
            horizontal 
            // className="ms-bgColor-white" 
            styles={{
                root: {
                  backgroundColor: 'white',
                },
            }}
            horizontalAlign="space-between"
          >
            <CommandBar items={commandBarItems} styles={{ root: { width: 750 }}} 
            />
          </Stack>
          {items.length ? (
            <ShimmeredDetailsList
              items={items || []}
              enableShimmer={!loadDataComplete}
              compact={isCompactMode}
              columns={columns}
              layoutMode={DetailsListLayoutMode.justified}
              isHeaderVisible={true}
              selection={selection}
              selectionMode={!loadDataComplete ? SelectionMode.none : SelectionMode.multiple}
              checkboxVisibility={CheckboxVisibility.always}
              selectionPreservedOnEmptyClick={true}
            />
          ) : (
            <ShimmeredDetailsList
              items={[{}]}
              enableShimmer={!loadDataComplete}
              compact={isCompactMode}
              columns={columns}
              layoutMode={DetailsListLayoutMode.justified}
              isHeaderVisible={true}
              selection={selection}
              selectionMode={!loadDataComplete ? SelectionMode.none : SelectionMode.multiple}
              checkboxVisibility={CheckboxVisibility.always}
              selectionPreservedOnEmptyClick={true}
              onRenderRow={() => {
                return (
                  <Stack verticalAlign="center" horizontalAlign="center" styles={{ root: { backgroundColor: '#FFFFFF', height: 55 } }}>
                    <Text>ไม่พบ</Text>
                  </Stack>
                );
              }}
            />
          )}
        </Stack>
      </Stack>
      <br />
      <center>
        {!(documents.length === 0 || documents.length < pageSize) && loadDataComplete && (
          <Pagination
            selectedPageIndex={currentPage - 1}
            pageCount={pageData.totalPages}
            onPageChange={onPageChange}
            format
            firstPageIconProps={{
              iconName: 'DoubleChevronLeft',
            }}
            previousPageIconProps={{ iconName: 'ChevronLeft' }}
            nextPageIconProps={{ iconName: 'ChevronRight' }}
            lastPageIconProps={{
              iconName: 'DoubleChevronRight',
            }}
          />
        )}

        <br />
        <br />
      </center>

      <Dialog
        hidden={!dialogCreateStatement.isClick}
        onDismiss={() =>
          setDialogCreateStatement((prev) => ({
            ...prev,
            isClick: false,
          }))
        }
        styles={{ main: { minHeight: 0 } }}
        dialogContentProps={{
          type: DialogType.largeHeader,
          title: "สร้าง Statement",
          styles: { header: { textAlign: 'center' }, title: { paddingRight: 24 }, subText: { textAlign: 'center' } },
        }}
      >
        <Stack horizontalAlign='center'>
          <DatePicker
            id='fromDate'
            label='วันที่เริ่ม'
            styles={{ root: { width: '100%' } }}
            placeholder="เลือก"
            strings={DayPickerStrings}
            value={dialogCreateStatement.fromDate}
            maxDate={dialogCreateStatement.toDate}
            formatDate={onFormatDate}
            onSelectDate={(_date) => {
              setDialogCreateStatement((prev) => ({
                ...prev,
                fromDate: _date
              }))
              // setFromDate(_date);

              // setCurrentPeriod(false)
              // setTimeRangeError(false);
              // if (Math.ceil(Math.abs(_date - toDate) / (1000 * 60 * 60 * 24)) > 30) setTimeRangeError(true);
            }}
          />
          <DatePicker
            id='toDate'
            styles={{ root: { width: '100%' } }}
            label="วันที่สิ้นสุด"
            placeholder="เลือก"
            strings={DayPickerStrings}
            value={dialogCreateStatement.toDate}
            minDate={dialogCreateStatement.fromDate}
            formatDate={onFormatDate}
            onSelectDate={(_date) => {
              setDialogCreateStatement((prev) => ({
                ...prev,
                toDate: _date
              }))
              // setToDate(_date);

              // setCurrentPeriod(false)
              // setTimeRangeError(false);
              // if (Math.ceil(Math.abs(_date - toDate) / (1000 * 60 * 60 * 24)) > 30) setTimeRangeError(true);
            }}
          />
          <TextField
            name="suffix"
            label="เลขท้ายเอกสาร"
            type='number'
            styles={{ root: { width: "100%" } }}
            onChange={(e) => {
              // setSuffix(e.target.value);
              setDialogCreateStatement((prev) => ({
                ...prev,
                suffix: e.target.value
              }))
            }}
            value={dialogCreateStatement.suffix}
            validateOnLoad={false}
            validateOnFocusOut
            required
          />
          <Dropdown
            placeholder="เลือกแพ็กเกจ"
            label="แพ็กเกจ"
            // options={Object.keys(packagesList).map(_key => ({ key: _key, text: packagesList[_key].package_name_abb }))}
            options={packagesOptions}
            styles={{ root: { width: "100%" } }}
            selectedKey={dialogCreateStatement.packageId}
            onChange={(e, selectedOption) => {
              // setPackageId(selectedOption.key);
              setDialogCreateStatement((prev) => ({
                ...prev,
                packageId: selectedOption.key
              }))
            }}
            required
          />
        </Stack>
        <DialogFooter styles={{ actionsRight: { textAlign: 'center' } }}>
          <PrimaryButton text="ยืนยัน" onClick={() => dialogCreateStatement.onSubmit()} />
          <DefaultButton
            onClick={() =>
              setDialogCreateStatement((prev) => ({
                ...prev,
                isClick: false,
                onSubmit: () => null
              }))
            }
            text="ยกเลิก"
          />
        </DialogFooter>
      </Dialog>
    </div>
  );
};

export default CreateStatement;
