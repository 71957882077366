import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import axios from "axios";

import { useMsal, useAccount, AuthError } from "@azure/msal-react";
import { silentRequest } from "../../../../authConfig";

// import * as signalR from "@microsoft/signalr";

import paginate from "jw-paginate";

import { Pagination } from "@fluentui/react-experiments/lib/Pagination";

import CountUp from "react-countup";

import {
    //TextField,
    //DetailsList,
    DetailsListLayoutMode,
    ShimmeredDetailsList,
    Selection,
    SelectionMode,
    //IColumn,
    mergeStyleSets,
    TooltipHost,
    //Fabric,
    ActionButton,
    // Icon,
    //ProgressIndicator,
    //Label,
    Spinner,
    SpinnerSize,
    CommandBar,
    Stack,
    DocumentCard,
    //DocumentCardActivity,
    DocumentCardTitle,
    DocumentCardDetails,
    DocumentCardLogo,
    //DocumentCardStatus,
    DocumentCardType,
    Text,
    DefaultButton,
    ContextualMenu,
    ContextualMenuItemType,
    CheckboxVisibility,
    //Pagination
    //initializeIcons
} from "@fluentui/react";
/* 
import {
    getFileTypeIconProps,
    //FileIconType
} from "@uifabric/file-type-icons";
 */
const API_URL = process.env.REACT_APP_API_URL;
const ADMIN_API_URL = process.env.REACT_APP_ADMIN_API_URL;

const homeStyles = {
    //width: '208',
    //height: '10vh',
    //width: 185,
    //height: 70,
    //boxSizing: 'border-box',
    //border: '1px solid #eee',
    //overflowY: 'auto',
    //paddingTop: '10vh',
    //background: '#FFFFFF',
    //background: '#106ebe',
    border: 0,
    /* paddingTop: '2%',
    paddingLeft: '2%',
    paddingRight: '2%', */
    margin: 0,
    //minHeight: '100vh',
    width: "100%",
    background: "#FAF9F8",
};

//ProgressIndicator
//const centerItem = { display: "flex", alignItems: "center", justifyContent: "center" };
//Progress Indicator Setting
//const intervalDelay = 100;
//const intervalIncrement = 0.01;

const classNames = mergeStyleSets({
    fileIconHeaderIcon: {
        padding: 0,
        fontSize: "16px",
    },
    fileIconCell: {
        textAlign: "center",
        selectors: {
            "&:before": {
                content: ".",
                display: "inline-block",
                verticalAlign: "middle",
                height: "100%",
                width: "0px",
                visibility: "hidden",
            },
        },
    },
    fileIconImg: {
        verticalAlign: "middle",
        maxHeight: "16px",
        maxWidth: "16px",
    },
    controlWrapper: {
        display: "flex",
        flexWrap: "wrap",
    },
    exampleToggle: {
        display: "inline-block",
        marginBottom: "10px",
        marginRight: "30px",
    },
    selectionDetails: {
        marginBottom: "20px",
    },
});
/* 
const shimmeredDetailsListProps = {
renderedWindowsAhead: 0,
renderedWindowsBehind: 0,
};
*/

const cardStyles = {
    cardStyles: {
        root: {
            background: "white",
            //padding: 20,
            //marginRight: '50%',
            //marginRight: '40px',
            //borderTop: '5px solid #0078d4',
            borderLeft: "5px solid #0078d4",
            width: "30%",
            minWidth: "315px",
            //maxWidth: '415px',
            maxWidth: "424px",
            height: "130px",
            //margin: 'auto',
            //padding: '0 10% 0 10% ',
            //display: 'flex',
            //alignItems: 'center',
            //justifyContent: 'center',
        },
    },
    header: {
        root: {
            fontSize: 20,
            fontWeight: "bold",
            //paddingTop: '5%',
            paddingTop: "20px",
            textAlign: "center",
            //color: '#696969'
        },
    },
    amount: {
        root: {
            fontSize: 30,
            //paddingBottom: 20,
            //paddingBottom: '8%',
            paddingBottom: "25px",
            //paddingTop: '20%',
            //paddingRight: '20%',
            textAlign: "center",
        },
    },
};

function formatDateTime(date) {
    //let d = new Date(date).toLocaleString("en-GB", {timeZone: "Asia/Bangkok"}),
    let d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear(),
        hour = "" + d.getHours(),
        minute = "" + d.getMinutes(),
        second = "" + d.getSeconds();

    //console.log(d.toString());

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    if (hour.length < 2) hour = "0" + hour;
    if (minute.length < 2) minute = "0" + minute;
    if (second.length < 2) second = "0" + second;

    return [year, month, day, hour, minute, second].join("");
}

const Xml = (props) => {
    console.log("Home render!!!");

    const history = useHistory();

    const { instance, accounts } = useMsal();
    //const account = useAccount(accounts[0] || {});
    const account = instance.getAllAccounts()[0];

    //let token = b2cauth.getAccessToken();
    //console.log('Bearer', token);

    //const token = props.token;
    //const token = props.token;
    //

    //const api_key = props.api_key;
    //console.log('Api-Key:', props.api_key);

    //const items = [];
    const [documents, setDocuments] = useState([]);
    const [items, setItems] = useState([]);
    const [loadDataComplete, setLoadDataComplete] = useState(false);
    const [getNewDocuments, setGetNewDocuments] = useState(true);
    const [totalProcessingDocuments, setTotalProcessingDocuments] = useState(0);
    //const [clickDelete, setClickDelete] = useState(false);
    const [pageData, setPageData] = useState({});
    const [pageSize, setPageSize] = useState(
        sessionStorage.getItem("uploadXml-pageSize") * 1 || 10
    );
    const [currentPage, setCurrentPage] = useState(
        sessionStorage.getItem("uploadXml-currentPage") * 1 || 1
    );
    /* 
    const [pageData, setPageData] = useState({
      totalItems: '',
      currentPage: '',
      pageSize: '',
      totalPages: '',
      startPage: '',
      endPage: '',
      startIndex: '',
      endIndex: '',
      pages: [] 
    });
 */
    //const [percentComplete, setPercentComplete] = useState(0);

    //CommandBars Items
    const [documentType, setDocumentType] = useState(
        (sessionStorage.getItem("uploadXml-documentType-key") && {
            key: sessionStorage.getItem("uploadXml-documentType-key"),
            text: sessionStorage.getItem("uploadXml-documentType-text"),
        }) || {
            key: "ALL",
            text: "เอกสารทุกประเภท",
        }
    );
    const [rdStatusType, setRdStatusType] = useState(
        (sessionStorage.getItem("uploadXml-rdStatusType-key") && {
            key: sessionStorage.getItem("uploadXml-rdStatusType-key"),
            text: sessionStorage.getItem("uploadXml-rdStatusType-text"),
        }) || { key: "allRdStatus", text: "สถานะนำส่งทั้งหมด" }
    );

    const [createdByFilter, setCreatedByFilter] = useState(
        (sessionStorage.getItem("uploadXml-createdByFilter-key") && {
            key: sessionStorage.getItem("uploadXml-createdByFilter-key"),
            text: sessionStorage.getItem("uploadXml-createdByFilter-text"),
        }) || {
            key: "allCreatedBy",
            text: "บริษัททั้งหมด",
        }
    );

    const [createdByFilterItems, setCreatedByFilterItems] = useState([
        {
            key: "allCreatedBy",
            text: "บริษัททั้งหมด",
            iconProps: { iconName: "CityNext2" },
            onClick: () => {
                console.log("บริษัททั้งหมด");
                setCreatedByFilter({
                    key: "allCreatedBy",
                    text: "บริษัททั้งหมด",
                });
                sessionStorage.setItem("uploadXml-createdByFilter-key", "allCreatedBy");
                sessionStorage.setItem("uploadXml-createdByFilter-text", "บริษัททั้งหมด");

                setLoadDataComplete(false);
                setItems([]);
                setGetNewDocuments(true);

                setTotalDocuments(0);
                setTotalVat(0);
                setTotalGrand(0);
            },
            //['data-automation-id']: 'newEmailButton', // optional
        },
    ]);

    //Display Summary
    const [totalDocuments, setTotalDocuments] = useState(0);
    const [totalVat, setTotalVat] = useState(0);
    const [totalGrand, setTotalGrand] = useState(0);

    const [totalPending, setTotalPending] = useState(0);
    const [totalSuccessful, setTotalSuccessful] = useState(0);
    const [totalFail, setTotalFail] = useState(0);

    //const [isUploadXml, setIsUploadXml] = useState([]);

    const [primarySort, setPrimarySort] = useState(
        sessionStorage.getItem("uploadXml-primarySort") || "documentDate"
    );
    const [isSortedDescendingCreatedTime, setIsSortedDescendingCreatedTime] =
        useState(() => {
            if (
                sessionStorage.getItem("uploadXml-sort-createdTime") &&
                sessionStorage.getItem("uploadXml-sort-createdTime") === "false"
            ) {
                return false;
            } else {
                return true;
            }
        });
    const [isSortedDescendingNumber, setIsSortedDescendingNumber] = useState(
        () => {
            if (
                sessionStorage.getItem("uploadXml-sort-number") &&
                sessionStorage.getItem("uploadXml-sort-number") === "true"
            ) {
                return true;
            } else {
                return false;
            }
        }
    );
    const [isSortedDescendingDocumentDate, setIsSortedDescendingDocumentDate] =
        useState(() => {
            if (
                sessionStorage.getItem("uploadXml-sort-documentDate") &&
                sessionStorage.getItem("uploadXml-sort-documentDate") === "true"
            ) {
                return true;
            } else {
                return false;
            }
        });
    const [isSortedDescendingCustomerName, setIsSortedDescendingCustomerName] =
        useState(() => {
            if (
                sessionStorage.getItem("uploadXml-sort-customerName") &&
                sessionStorage.getItem("uploadXml-sort-customerName") === "true"
            ) {
                return true;
            } else {
                return false;
            }
        });
    const [
        isSortedDescendingCustomerPhone,
        setIsSortedDescendingCustomerPhone,
    ] = useState(() => {
        if (
            sessionStorage.getItem("uploadXml-sort-customerPhone") &&
            sessionStorage.getItem("uploadXml-sort-customerPhone") === "true"
        ) {
            return true;
        } else {
            return false;
        }
    });
    const [isSortedDescendingVatTotal, setIsSortedDescendingVatTotal] =
        useState(() => {
            if (
                sessionStorage.getItem("uploadXml-sort-vatTotal") &&
                sessionStorage.getItem("uploadXml-sort-vatTotal") === "true"
            ) {
                return true;
            } else {
                return false;
            }
        });
    const [isSortedDescendingGrandTotal, setIsSortedDescendingGrandTotal] =
        useState(() => {
            if (
                sessionStorage.getItem("uploadXml-sort-grandTotal") &&
                sessionStorage.getItem("uploadXml-sort-grandTotal") === "true"
            ) {
                return true;
            } else {
                return false;
            }
        });
    const [isSortedDescendingName, setIsSortedDescendingName] = useState(() => {
        if (
            sessionStorage.getItem("uploadXml-sort-name") &&
            sessionStorage.getItem("uploadXml-sort-name") === "true"
        ) {
            return true;
        } else {
            return false;
        }
    });

    const getMonth = (goBack) => {
        let monthNames = [
            "มกราคม",
            "กุมภาพันธ์",
            "มีนาคม",
            "เมษายน",
            "พฤษภาคม",
            "มิถุนายน",
            "กรกฎาคม",
            "สิงหาคม",
            "กันยายน",
            "ตุลาคม",
            "พฤศจิกายน",
            "ธันวาคม",
        ];

        let d = new Date();
        //console.log('new Date(): ', d);

        let adjustDate = new Date(d.getFullYear(), d.getMonth() - goBack, 1);

        //d.setMonth(d.getMonth() - goBack);
        //console.log(d.toLocaleDateString());
        //console.log(d.getMonth());

        let year = adjustDate.getFullYear() + 543;

        return {
            month: adjustDate.getMonth(),
            year: adjustDate.getFullYear(),
            key: adjustDate.getMonth() + 1,
            text: monthNames[adjustDate.getMonth()] + " " + year,
        };
        //return monthNames[d.getMonth()] + ' ' + year;
        //let m = d.getMonth();
        /* 
      let d;
      let year;
      let _month = [];
      

      for (let i = 0; i < 6; i++) {
        d = new Date();
        d.setMonth(d.getMonth() - i);
        year = d.getFullYear() + 543;
        _month.push(monthNames[d.getMonth()] + ' ' + year);
      }
*/

        //console.log(_month);

        //return _month;
    };

    const [queryMonth, setQueryMonth] = useState(
        (sessionStorage.getItem("uploadXml-queryMonth-key") && {
            key: sessionStorage.getItem("uploadXml-queryMonth-key"),
            text: sessionStorage.getItem("uploadXml-queryMonth-text"),
        }) || { key: getMonth(0).year + "" + getMonth(0).key, text: "เดือนนี้" }
    );

    const getFrom = (month, year) => {
        //let date = new Date();
        let _firstDay = new Date(year, month, 1);
        //let _lastDay = new Date(year, month + 1, 1);
        console.log("First day: ", formatDate(_firstDay));

        return formatDate(_firstDay);
        //setLastDay(formatDate(_lastDay));

        //console.log('Last day: ', formatDate(_lastDay));
    };

    const getTo = (month, year) => {
        //let date = new Date();
        //let _firstDay = new Date(year, month, 1);
        let _lastDay = new Date(year, month + 1, 1);
        console.log("Last day: ", formatDate(_lastDay));

        return formatDate(_lastDay);
        //setLastDay(formatDate(_lastDay));

        //console.log('Fist day: ', formatDate(_firstDay));
    };

    const formatDate = (d) => {
        //let d = new Date(date),
        let month = "" + (d.getMonth() + 1);
        let day = "" + d.getDate();
        let year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        return [year, month, day].join("-");
    };
/* 
    function convertDateToUTC() {
        var date = new Date();
        var now_utc = Date.UTC(
            date.getUTCFullYear(),
            date.getUTCMonth(),
            date.getUTCDate(),
            date.getUTCHours(),
            date.getUTCMinutes(),
            date.getUTCSeconds()
        );

        return new Date(now_utc).toISOString();
    }
 */
    const [from, setFrom] = useState(
        sessionStorage.getItem("uploadXml-from") ||
            getFrom(getMonth(0).month, getMonth(0).year)
    );
    const [to, setTo] = useState(
        sessionStorage.getItem("uploadXml-to") ||
            getTo(getMonth(0).month, getMonth(0).year)
    );

    const [isProcessDocument, setIsProcessDocument] = useState(false);
    const [isProcessDocumentComplete, setIsProcessDocumentComplete] =
        useState(false);

    const [selectedItems, setSelectedItems] = useState([]);

    const [selection] = useState(
        new Selection({
            onSelectionChanged: () => {
                console.log("isCreateDocument: ", isProcessDocument);
                console.log(
                    "isCreateDocumentComplete: ",
                    isProcessDocumentComplete
                );
                let _selectedItems = selection.getSelection();

                setSelectedItems(_selectedItems);
            },
        })
    );

    // const [role, setRole] = useState('');
    const [isAdmin, setIsAdmin] = useState(false);

    const [pageDropdown, setPageDropdown] = useState([]);
    const [dateType, setDateType] = useState(
        (sessionStorage.getItem("uploadXml-dateType-key") && {
            key: sessionStorage.getItem("uploadXml-dateType-key"),
            text: sessionStorage.getItem("uploadXml-dateType-text"),
        }) || { key: "created", text: "วันที่สร้างเอกสาร" }
    );

    const resetSort = () => {
        if (dateType.key === "document") {
            setPrimarySort("documentDate");
            setIsSortedDescendingDocumentDate(true);
            setIsSortedDescendingCreatedTime(false);

            sessionStorage.setItem("uploadXml-primarySort", "documentDate");
            sessionStorage.setItem("uploadXml-sort-documentDate", "true");
            sessionStorage.setItem("uploadXml-sort-createdTime", "false");
        } else {
            setPrimarySort("createdTime");
            setIsSortedDescendingCreatedTime(true);
            setIsSortedDescendingDocumentDate(false);

            sessionStorage.setItem("uploadXml-primarySort", "createdTime");
            sessionStorage.setItem("uploadXml-sort-createdTime", "true");
            sessionStorage.setItem("uploadXml-sort-documentDate", "false");
        }

        setIsSortedDescendingNumber(false);
        setIsSortedDescendingCustomerName(false);
        setIsSortedDescendingCustomerPhone(false);
        setIsSortedDescendingVatTotal(false);
        setIsSortedDescendingGrandTotal(false);
        setIsSortedDescendingName(false);

        sessionStorage.setItem("uploadXml-sort-number", "false");
        sessionStorage.setItem("uploadXml-sort-customerName", "false");
        sessionStorage.setItem("uploadXml-sort-customerPhone", "false");
        sessionStorage.setItem("uploadXml-sort-vatTotal", "false");
        sessionStorage.setItem("uploadXml-sort-grandTotal", "false");
        sessionStorage.setItem("uploadXml-sort-name", "false");
    };

    const commandBarItems = [
        {
            key: documentType.key,
            text: documentType.text,
            cacheKey: "myCacheKey", // changing this key will invalidate this item's cache
            iconProps: { iconName: "Page" },
            disabled: !loadDataComplete || isProcessDocument,
            subMenuProps: {
                items: [
                    {
                        key: "ALL",
                        text: "เอกสารทุกประเภท",
                        iconProps: { iconName: "Page" },
                        onClick: () => {
                            console.log("เอกสารทุกประเภท");
                            setDocumentType({
                                key: "ALL",
                                text: "เอกสารทุกประเภท",
                            });
                            sessionStorage.setItem("uploadXml-documentType-key", "ALL");
                            sessionStorage.setItem(
                                "uploadXml-documentType-text",
                                "เอกสารทุกประเภท"
                            );

                            setLoadDataComplete(false);
                            setItems([]);
                            setGetNewDocuments(true);

                            setTotalDocuments(0);
                            setTotalVat(0);
                            setTotalGrand(0);

                            setCurrentPage(1);

                            resetSort();

                        },
                        //['data-automation-id']: 'newEmailButton', // optional
                    },
                    {
                        key: "RECEIPT-TAXINVOICE",
                        text: "ใบเสร็จรับเงิน/ใบกำกับภาษี",
                        iconProps: { iconName: "Page" },
                        onClick: () => {
                            console.log("ใบเสร็จรับเงิน/ใบกำกับภาษี");
                            setDocumentType({
                                key: "RECEIPT-TAXINVOICE",
                                text: "ใบเสร็จรับเงิน/ใบกำกับภาษี",
                            });
                            sessionStorage.setItem(
                                "uploadXml-documentType-key",
                                "RECEIPT-TAXINVOICE"
                            );
                            sessionStorage.setItem(
                                "uploadXml-documentType-text",
                                "ใบเสร็จรับเงิน/ใบกำกับภาษี"
                            );

                            setLoadDataComplete(false);
                            setItems([]);
                            setGetNewDocuments(true);

                            setTotalDocuments(0);
                            setTotalVat(0);
                            setTotalGrand(0);

                            setCurrentPage(1);

                            resetSort();
                        },
                        //['data-automation-id']: 'newEmailButton', // optional
                    },
                    /* 
            {
              key: 'RECEIPT-TAXINVOICE-ABB',
              text: 'ใบเสร็จรับเงิน/ใบกำกับภาษีอย่างย่อ',
              iconProps: { iconName: 'Page' },
              onClick: () => {
                console.log('ใบเสร็จรับเงิน/ใบกำกับภาษีอย่างย่อ');
                setDocumentType({ key: 'RECEIPT-TAXINVOICE-ABB', text: 'ใบเสร็จรับเงิน/ใบกำกับภาษีอย่างย่อ' });

                setLoadDataComplete(false);
                setItems([]);
                setGetNewDocuments(true);

                setTotalDocuments(0);
                setTotalVat(0);
                setTotalGrand(0);
              },
              //['data-automation-id']: 'newEmailButton', // optional
            },
  */
                    {
                        key: "RECEIPT",
                        text: "ใบเสร็จรับเงิน",
                        iconProps: { iconName: "Page" },
                        onClick: () => {
                            console.log("ใบเสร็จรับเงิน");
                            setDocumentType({
                                key: "RECEIPT",
                                text: "ใบเสร็จรับเงิน",
                            });
                            sessionStorage.setItem(
                                "uploadXml-documentType-key",
                                "RECEIPT"
                            );
                            sessionStorage.setItem(
                                "uploadXml-documentType-text",
                                "ใบเสร็จรับเงิน"
                            );

                            setLoadDataComplete(false);
                            setItems([]);
                            setGetNewDocuments(true);

                            setTotalDocuments(0);
                            setTotalVat(0);
                            setTotalGrand(0);

                            setCurrentPage(1);

                            resetSort();
                        },
                    },
                    {
                        key: "INVOICE-TAXINVOICE",
                        text: "ใบแจ้งหนี้/ใบกำกับภาษี",
                        iconProps: { iconName: "Page" },
                        onClick: () => {
                            console.log("ใบแจ้งหนี้/ใบกำกับภาษี");
                            setDocumentType({
                                key: "INVOICE-TAXINVOICE",
                                text: "ใบแจ้งหนี้/ใบกำกับภาษี",
                            });
                            sessionStorage.setItem(
                                "uploadXml-documentType-key",
                                "INVOICE-TAXINVOICE"
                            );
                            sessionStorage.setItem(
                                "uploadXml-documentType-text",
                                "ใบแจ้งหนี้/ใบกำกับภาษี"
                            );

                            setLoadDataComplete(false);
                            setItems([]);
                            setGetNewDocuments(true);

                            setTotalDocuments(0);
                            setTotalVat(0);
                            setTotalGrand(0);

                            setCurrentPage(1);

                            resetSort();
                        },
                    },
                    {
                        key: "DELIVERYORDER-TAXINVOICE",
                        text: "ใบส่งของ/ใบกำกับภาษี",
                        iconProps: { iconName: "Page" },
                        onClick: () => {
                            console.log("ใบส่งของ/ใบกำกับภาษี");
                            setDocumentType({
                                key: "DELIVERYORDER-TAXINVOICE",
                                text: "ใบส่งของ/ใบกำกับภาษี",
                            });
                            sessionStorage.setItem(
                                "uploadXml-documentType-key",
                                "DELIVERYORDER-TAXINVOICE"
                            );
                            sessionStorage.setItem(
                                "uploadXml-documentType-text",
                                "ใบส่งของ/ใบกำกับภาษี"
                            );

                            setLoadDataComplete(false);
                            setItems([]);
                            setGetNewDocuments(true);

                            setTotalDocuments(0);
                            setTotalVat(0);
                            setTotalGrand(0);

                            setCurrentPage(1);

                            resetSort();
                        },
                    },
                    {
                        key: "TAXINVOICE",
                        text: "ใบกำกับภาษี",
                        iconProps: { iconName: "Page" },
                        onClick: () => {
                            console.log("ใบกำกับภาษี");
                            setDocumentType({
                                key: "TAXINVOICE",
                                text: "ใบกำกับภาษี",
                            });
                            sessionStorage.setItem(
                                "uploadXml-documentType-key",
                                "TAXINVOICE"
                            );
                            sessionStorage.setItem(
                                "uploadXml-documentType-text",
                                "ใบกำกับภาษี"
                            );

                            setLoadDataComplete(false);
                            setItems([]);
                            setGetNewDocuments(true);

                            setTotalDocuments(0);
                            setTotalVat(0);
                            setTotalGrand(0);

                            setCurrentPage(1);

                            resetSort();
                        },
                    },
                    {
                        key: "DEBIT-NOTE",
                        text: "ใบเพิ่มหนี้",
                        iconProps: { iconName: "Page" },
                        onClick: () => {
                            console.log("ใบเพิ่มหนี้");
                            setDocumentType({
                                key: "DEBIT-NOTE",
                                text: "ใบเพิ่มหนี้",
                            });
                            sessionStorage.setItem(
                                "uploadXml-documentType-key",
                                "DEBIT-NOTE"
                            );
                            sessionStorage.setItem(
                                "uploadXml-documentType-text",
                                "ใบเพิ่มหนี้"
                            );

                            setLoadDataComplete(false);
                            setItems([]);
                            setGetNewDocuments(true);

                            setTotalDocuments(0);
                            setTotalVat(0);
                            setTotalGrand(0);

                            setCurrentPage(1);

                            resetSort();
                        },
                    },
                    {
                        key: "CREDIT-NOTE",
                        text: "ใบลดหนี้",
                        iconProps: { iconName: "Page" },
                        onClick: () => {
                            console.log("ใบลดหนี้");
                            setDocumentType({
                                key: "CREDIT-NOTE",
                                text: "ใบลดหนี้",
                            });
                            sessionStorage.setItem(
                                "uploadXml-documentType-key",
                                "CREDIT-NOTE"
                            );
                            sessionStorage.setItem(
                                "uploadXml-documentType-text",
                                "ใบลดหนี้"
                            );

                            setLoadDataComplete(false);
                            setItems([]);
                            setGetNewDocuments(true);

                            setTotalDocuments(0);
                            setTotalVat(0);
                            setTotalGrand(0);

                            setCurrentPage(1);

                            resetSort();
                        },
                    },
                ],
            },
        },
        {
            key: rdStatusType.key,
            text: rdStatusType.text,
            cacheKey: "myCacheKey", // changing this key will invalidate this item's cache
            iconProps: { iconName: "Page" },
            disabled: !loadDataComplete || isProcessDocument,
            subMenuProps: {
                items: [
                    {
                        key: "allRdStatus",
                        text: "สถานะนำส่งทั้งหมด",
                        iconProps: { iconName: "Page" },
                        onClick: () => {
                            console.log("สถานะนำส่งทั้งหมด");
                            setRdStatusType({
                                key: "allRdStatus",
                                text: "สถานะนำส่งทั้งหมด",
                            });
                            sessionStorage.setItem(
                                "uploadXml-rdStatusType-key",
                                "allRdStatus"
                            );
                            sessionStorage.setItem(
                                "uploadXml-rdStatusType-text",
                                "สถานะนำส่งทั้งหมด"
                            );

                            setLoadDataComplete(false);
                            setItems([]);
                            setGetNewDocuments(true);

                            setTotalDocuments(0);
                            setTotalVat(0);
                            setTotalGrand(0);

                            setCurrentPage(1);

                            resetSort();
                        },
                        //['data-automation-id']: 'newEmailButton', // optional
                    },
                    {
                        key: "pending",
                        text: "รอส่งอัตโนมัติ",
                        iconProps: { iconName: "Page" },
                        onClick: () => {
                            console.log("รอส่งอัตโนมัติ");
                            setRdStatusType({
                                key: "pending",
                                text: "รอส่งอัตโนมัติ",
                            });
                            sessionStorage.setItem(
                                "uploadXml-rdStatusType-key",
                                "pending"
                            );
                            sessionStorage.setItem(
                                "uploadXml-rdStatusType-text",
                                "รอส่งอัตโนมัติ"
                            );

                            setLoadDataComplete(false);
                            setItems([]);
                            setGetNewDocuments(true);

                            setTotalDocuments(0);
                            setTotalVat(0);
                            setTotalGrand(0);

                            setCurrentPage(1);

                            resetSort();
                        },
                        //['data-automation-id']: 'newEmailButton', // optional
                    },
                    {
                        key: "successful",
                        text: "นำส่งสำเร็จ",
                        iconProps: { iconName: "Page" },
                        onClick: () => {
                            console.log("นำส่งสำเร็จ");
                            setRdStatusType({
                                key: "successful",
                                text: "นำส่งสำเร็จ",
                            });
                            sessionStorage.setItem(
                                "uploadXml-rdStatusType-key",
                                "successful"
                            );
                            sessionStorage.setItem(
                                "uploadXml-rdStatusType-text",
                                "นำส่งสำเร็จ"
                            );

                            setLoadDataComplete(false);
                            setItems([]);
                            setGetNewDocuments(true);

                            setTotalDocuments(0);
                            setTotalVat(0);
                            setTotalGrand(0);

                            setCurrentPage(1);

                            resetSort();
                        },
                        //['data-automation-id']: 'newEmailButton', // optional
                    },
                    {
                        key: "fail",
                        text: "นำส่งไม่สำเร็จ",
                        iconProps: { iconName: "Page" },
                        onClick: () => {
                            console.log("นำส่งไม่สำเร็จ");
                            setRdStatusType({
                                key: "fail",
                                text: "นำส่งไม่สำเร็จ",
                            });
                            sessionStorage.setItem("uploadXml-rdStatusType-key", "fail");
                            sessionStorage.setItem(
                                "uploadXml-rdStatusType-text",
                                "นำส่งไม่สำเร็จ"
                            );

                            setLoadDataComplete(false);
                            setItems([]);
                            setGetNewDocuments(true);

                            setTotalDocuments(0);
                            setTotalVat(0);
                            setTotalGrand(0);

                            setCurrentPage(1);

                            resetSort();
                        },
                    },
                    {
                        key: "null",
                        text: "ไม่นำส่ง",
                        iconProps: { iconName: "Page" },
                        onClick: () => {
                            console.log("ไม่นำส่ง");
                            setRdStatusType({ key: "null", text: "ไม่นำส่ง" });
                            sessionStorage.setItem("uploadXml-rdStatusType-key", "null");
                            sessionStorage.setItem(
                                "uploadXml-rdStatusType-text",
                                "ไม่นำส่ง"
                            );

                            setLoadDataComplete(false);
                            setItems([]);
                            setGetNewDocuments(true);

                            setTotalDocuments(0);
                            setTotalVat(0);
                            setTotalGrand(0);

                            setCurrentPage(1);

                            resetSort();
                        },
                    },
                ],
            },
        },
        {
            key: createdByFilter.key,
            text: createdByFilter.text,
            cacheKey: "myCacheKey", // changing this key will invalidate this item's cache
            iconProps: { iconName: "CityNext2" },
            disabled:
                !loadDataComplete ||
                isProcessDocument ||
                createdByFilterItems.length <= 1,
            subMenuProps: {
                items: createdByFilterItems,
            },
        },
        {
            key: dateType.key,
            text: dateType.text,
            cacheKey: "myCacheKey", // changing this key will invalidate this item's cache
            iconProps: { iconName: "CalendarSettings" },
            disabled: !loadDataComplete || isProcessDocument,
            subMenuProps: {
                items: [
                    {
                        key: "created",
                        text: "วันที่สร้างเอกสาร",
                        iconProps: { iconName: "CalendarSettings" },
                        onClick: () => {
                            console.log("วันที่สร้างเอกสาร");
                            setDateType({
                                key: "created",
                                text: "วันที่สร้างเอกสาร",
                            });
                            sessionStorage.setItem("uploadXml-dateType-key", "created");
                            sessionStorage.setItem(
                                "uploadXml-dateType-text",
                                "วันที่สร้างเอกสาร"
                            );

                            setLoadDataComplete(false);
                            setItems([]);
                            setGetNewDocuments(true);
                            // setPreviousSearch('');
                            // setSearch('');

                            setTotalDocuments(0);
                            setTotalVat(0);
                            setTotalGrand(0);

                            setCurrentPage(1);

                            setPrimarySort("createdTime");
                            setIsSortedDescendingCreatedTime(true);

                            setIsSortedDescendingNumber(false);
                            setIsSortedDescendingDocumentDate(false);
                            setIsSortedDescendingCustomerName(false);
                            setIsSortedDescendingCustomerPhone(false);
                            setIsSortedDescendingVatTotal(false);
                            setIsSortedDescendingGrandTotal(false);
                            setIsSortedDescendingName(false);
                        },
                    },
                    {
                        key: "document",
                        text: "วันที่บนเอกสาร",
                        iconProps: { iconName: "CalendarSettings" },
                        onClick: () => {
                            console.log("วันที่บนเอกสาร");
                            setDateType({
                                key: "document",
                                text: "วันที่บนเอกสาร",
                            });
                            sessionStorage.setItem("uploadXml-dateType-key", "document");
                            sessionStorage.setItem(
                                "uploadXml-dateType-text",
                                "วันที่บนเอกสาร"
                            );

                            setLoadDataComplete(false);
                            setItems([]);
                            setGetNewDocuments(true);
                            // setPreviousSearch('');
                            // setSearch('');

                            setTotalDocuments(0);
                            setTotalVat(0);
                            setTotalGrand(0);

                            setCurrentPage(1);

                            setPrimarySort("documentDate");
                            setIsSortedDescendingDocumentDate(true);

                            setIsSortedDescendingCreatedTime(false);
                            setIsSortedDescendingNumber(false);
                            setIsSortedDescendingCustomerName(false);
                            setIsSortedDescendingCustomerPhone(false);
                            setIsSortedDescendingVatTotal(false);
                            setIsSortedDescendingGrandTotal(false);
                            setIsSortedDescendingName(false);
                        },
                    },
                ],
            },
        },
        {
            key: queryMonth.key,
            text: queryMonth.text,
            iconProps: { iconName: "Calendar" },
            disabled: !loadDataComplete || isProcessDocument,
            //href: 'https://developer.microsoft.com/en-us/fluentui',
            subMenuProps: {
                items: [
                    {
                        key: getMonth(0).year + "" + getMonth(0).key,
                        text: getMonth(0).text,
                        iconProps: { iconName: "Calendar" },
                        onClick: () => {
                            console.log("Selected month: ", getMonth(0).key);
                            setQueryMonth({
                                key: getMonth(0).year + "" + getMonth(0).key,
                                text: getMonth(0).text,
                            });
                            sessionStorage.setItem(
                                "uploadXml-queryMonth-key",
                                getMonth(0).year + "" + getMonth(0).key
                            );
                            sessionStorage.setItem(
                                "uploadXml-queryMonth-text",
                                getMonth(0).text
                            );
                            //setFirstLastDay(getMonth(0).month, getMonth(0).year);
                            setFrom(
                                getFrom(getMonth(0).month, getMonth(0).year)
                            );
                            setTo(getTo(getMonth(0).month, getMonth(0).year));
                            sessionStorage.setItem(
                                "uploadXml-from",
                                getFrom(getMonth(0).month, getMonth(0).year)
                            );
                            sessionStorage.setItem(
                                "uploadXml-to",
                                getTo(getMonth(0).month, getMonth(0).year)
                            );

                            setLoadDataComplete(false);
                            setItems([]);
                            setGetNewDocuments(true);

                            setTotalDocuments(0);
                            setTotalVat(0);
                            setTotalGrand(0);

                            setCurrentPage(1);

                            resetSort();
                        },
                        //['data-automation-id']: 'newEmailButton', // optional
                    },
                    {
                        key: getMonth(1).year + "" + getMonth(1).key,
                        text: getMonth(1).text,
                        iconProps: { iconName: "Calendar" },
                        onClick: () => {
                            console.log("Selected month: ", getMonth(1).key);
                            setQueryMonth({
                                key: getMonth(1).year + "" + getMonth(1).key,
                                text: getMonth(1).text,
                            });
                            sessionStorage.setItem(
                                "uploadXml-queryMonth-key",
                                getMonth(1).year + "" + getMonth(1).key
                            );
                            sessionStorage.setItem(
                                "uploadXml-queryMonth-text",
                                getMonth(1).text
                            );
                            //setFirstLastDay(getMonth(1).month, getMonth(1).year);
                            setFrom(
                                getFrom(getMonth(1).month, getMonth(1).year)
                            );
                            setTo(getTo(getMonth(1).month, getMonth(1).year));
                            sessionStorage.setItem(
                                "uploadXml-from",
                                getFrom(getMonth(1).month, getMonth(1).year)
                            );
                            sessionStorage.setItem(
                                "uploadXml-to",
                                getTo(getMonth(1).month, getMonth(1).year)
                            );

                            setLoadDataComplete(false);
                            setItems([]);
                            setGetNewDocuments(true);

                            setTotalDocuments(0);
                            setTotalVat(0);
                            setTotalGrand(0);

                            setCurrentPage(1);

                            resetSort();
                        },
                        //['data-automation-id']: 'newEmailButton', // optional
                    },
                    {
                        key: getMonth(2).year + "" + getMonth(2).key,
                        text: getMonth(2).text,
                        iconProps: { iconName: "Calendar" },
                        onClick: () => {
                            console.log("Selected month: ", getMonth(2).key);
                            setQueryMonth({
                                key: getMonth(2).year + "" + getMonth(2).key,
                                text: getMonth(2).text,
                            });
                            sessionStorage.setItem(
                                "uploadXml-queryMonth-key",
                                getMonth(2).year + "" + getMonth(2).key
                            );
                            sessionStorage.setItem(
                                "uploadXml-queryMonth-text",
                                getMonth(2).text
                            );
                            //setFirstLastDay(getMonth(2).month, getMonth(2).year);
                            setFrom(
                                getFrom(getMonth(2).month, getMonth(2).year)
                            );
                            setTo(getTo(getMonth(2).month, getMonth(2).year));
                            sessionStorage.setItem(
                                "uploadXml-from",
                                getFrom(getMonth(2).month, getMonth(2).year)
                            );
                            sessionStorage.setItem(
                                "uploadXml-to",
                                getTo(getMonth(2).month, getMonth(2).year)
                            );

                            setLoadDataComplete(false);
                            setItems([]);
                            setGetNewDocuments(true);

                            setTotalDocuments(0);
                            setTotalVat(0);
                            setTotalGrand(0);

                            setCurrentPage(1);

                            resetSort();
                        },
                        //['data-automation-id']: 'newEmailButton', // optional
                    },
                    {
                        key: getMonth(3).year + "" + getMonth(3).key,
                        text: getMonth(3).text,
                        iconProps: { iconName: "Calendar" },
                        onClick: () => {
                            console.log("Selected month: ", getMonth(3).key);
                            setQueryMonth({
                                key: getMonth(3).year + "" + getMonth(3).key,
                                text: getMonth(3).text,
                            });
                            sessionStorage.setItem(
                                "uploadXml-queryMonth-key",
                                getMonth(3).year + "" + getMonth(3).key
                            );
                            sessionStorage.setItem(
                                "uploadXml-queryMonth-text",
                                getMonth(3).text
                            );
                            //setFirstLastDay(getMonth(3).month, getMonth(3).year);
                            setFrom(
                                getFrom(getMonth(3).month, getMonth(3).year)
                            );
                            setTo(getTo(getMonth(3).month, getMonth(3).year));
                            sessionStorage.setItem(
                                "uploadXml-from",
                                getFrom(getMonth(3).month, getMonth(3).year)
                            );
                            sessionStorage.setItem(
                                "uploadXml-to",
                                getTo(getMonth(3).month, getMonth(3).year)
                            );

                            setLoadDataComplete(false);
                            setItems([]);
                            setGetNewDocuments(true);

                            setTotalDocuments(0);
                            setTotalVat(0);
                            setTotalGrand(0);

                            setCurrentPage(1);

                            resetSort();
                        },
                        //['data-automation-id']: 'newEmailButton', // optional
                    },
                    {
                        key: getMonth(4).year + "" + getMonth(4).key,
                        text: getMonth(4).text,
                        iconProps: { iconName: "Calendar" },
                        onClick: () => {
                            console.log("Selected month: ", getMonth(4).key);
                            setQueryMonth({
                                key: getMonth(4).year + "" + getMonth(4).key,
                                text: getMonth(4).text,
                            });
                            sessionStorage.setItem(
                                "uploadXml-queryMonth-key",
                                getMonth(4).year + "" + getMonth(4).key
                            );
                            sessionStorage.setItem(
                                "uploadXml-queryMonth-text",
                                getMonth(4).text
                            );
                            //setFirstLastDay(getMonth(4).month, getMonth(4).year);
                            setFrom(
                                getFrom(getMonth(4).month, getMonth(4).year)
                            );
                            setTo(getTo(getMonth(4).month, getMonth(4).year));
                            sessionStorage.setItem(
                                "uploadXml-from",
                                getFrom(getMonth(4).month, getMonth(4).year)
                            );
                            sessionStorage.setItem(
                                "uploadXml-to",
                                getTo(getMonth(4).month, getMonth(4).year)
                            );

                            setLoadDataComplete(false);
                            setItems([]);
                            setGetNewDocuments(true);

                            setTotalDocuments(0);
                            setTotalVat(0);
                            setTotalGrand(0);

                            setCurrentPage(1);

                            resetSort();
                        },
                        //['data-automation-id']: 'newEmailButton', // optional
                    },
                    {
                        key: getMonth(5).year + "" + getMonth(5).key,
                        text: getMonth(5).text,
                        iconProps: { iconName: "Calendar" },
                        onClick: () => {
                            console.log(getMonth(5).key);
                            setQueryMonth({
                                key: getMonth(5).year + "" + getMonth(5).key,
                                text: getMonth(5).text,
                            });
                            sessionStorage.setItem(
                                "uploadXml-queryMonth-key",
                                getMonth(5).year + "" + getMonth(5).key
                            );
                            sessionStorage.setItem(
                                "uploadXml-queryMonth-text",
                                getMonth(5).text
                            );
                            //setFirstLastDay(getMonth(5).month, getMonth(5).year);
                            setFrom(
                                getFrom(getMonth(5).month, getMonth(5).year)
                            );
                            setTo(getTo(getMonth(5).month, getMonth(5).year));
                            sessionStorage.setItem(
                                "uploadXml-from",
                                getFrom(getMonth(5).month, getMonth(5).year)
                            );
                            sessionStorage.setItem(
                                "uploadXml-to",
                                getTo(getMonth(5).month, getMonth(5).year)
                            );

                            setLoadDataComplete(false);
                            setItems([]);
                            setGetNewDocuments(true);

                            setTotalDocuments(0);
                            setTotalVat(0);
                            setTotalGrand(0);

                            setCurrentPage(1);

                            resetSort();
                        },
                        //['data-automation-id']: 'newEmailButton', // optional
                    },
                ],
            },
        },
        {
            key: pageSize,
            text: pageSize + " เอกสาร/หน้า",
            cacheKey: "myCacheKey", // changing this key will invalidate this item's cache
            iconProps: { iconName: "NumberedList" },
            disabled: !loadDataComplete || isProcessDocument,
            subMenuProps: {
                items: [
                    {
                        key: 10,
                        text: "10 เอกสาร/หน้า",
                        iconProps: { iconName: "NumberedList" },
                        onClick: () => {
                            console.log("10 เอกสาร/หน้า");
                            setPageSize(10);
                            sessionStorage.setItem("uploadXml-pageSize", 10);

                            setLoadDataComplete(false);
                            setItems([]);
                            setGetNewDocuments(true);

                            setTotalDocuments(0);
                            setTotalVat(0);
                            setTotalGrand(0);

                            // setCurrentPage(1);
                            
                        },
                    },
                    {
                        key: 20,
                        text: "20 เอกสาร/หน้า",
                        iconProps: { iconName: "NumberedList" },
                        onClick: () => {
                            console.log("20 เอกสาร/หน้า");
                            setPageSize(20);
                            sessionStorage.setItem("uploadXml-pageSize", 20);

                            setLoadDataComplete(false);
                            setItems([]);
                            setGetNewDocuments(true);

                            setTotalDocuments(0);
                            setTotalVat(0);
                            setTotalGrand(0);

                            // setCurrentPage(1);
                        },
                    },
                    {
                        key: 30,
                        text: "30 เอกสาร/หน้า",
                        iconProps: { iconName: "NumberedList" },
                        onClick: () => {
                            console.log("30 เอกสาร/หน้า");
                            setPageSize(30);
                            sessionStorage.setItem("uploadXml-pageSize", 30);

                            setLoadDataComplete(false);
                            setItems([]);
                            setGetNewDocuments(true);

                            setTotalDocuments(0);
                            setTotalVat(0);
                            setTotalGrand(0);

                            // setCurrentPage(1);
                        },
                    },
                    {
                        key: 40,
                        text: "40 เอกสาร/หน้า",
                        iconProps: { iconName: "NumberedList" },
                        onClick: () => {
                            console.log("40 เอกสาร/หน้า");
                            setPageSize(40);
                            sessionStorage.setItem("uploadXml-pageSize", 40);

                            setLoadDataComplete(false);
                            setItems([]);
                            setGetNewDocuments(true);

                            setTotalDocuments(0);
                            setTotalVat(0);
                            setTotalGrand(0);

                            // setCurrentPage(1);
                        },
                    },
                    {
                        key: 50,
                        text: "50 เอกสาร/หน้า",
                        iconProps: { iconName: "NumberedList" },
                        onClick: () => {
                            console.log("50 เอกสาร/หน้า");
                            setPageSize(50);
                            sessionStorage.setItem("uploadXml-pageSize", 50);

                            setLoadDataComplete(false);
                            setItems([]);
                            setGetNewDocuments(true);

                            setTotalDocuments(0);
                            setTotalVat(0);
                            setTotalGrand(0);

                            // setCurrentPage(1);
                        },
                    },
                    {
                        key: 100,
                        text: "100 เอกสาร/หน้า",
                        iconProps: { iconName: "NumberedList" },
                        onClick: () => {
                            console.log("100 เอกสาร/หน้า");
                            setPageSize(100);
                            sessionStorage.setItem("uploadXml-pageSize", 100);

                            setLoadDataComplete(false);
                            setItems([]);
                            setGetNewDocuments(true);

                            setTotalDocuments(0);
                            setTotalVat(0);
                            setTotalGrand(0);

                            // setCurrentPage(1);
                        },
                    },
                ],
            },
        },
        {
            key: currentPage,
            text: "หน้า " + currentPage,
            iconProps: { iconName: "Page" },
            disabled: !loadDataComplete || isProcessDocument,
            subMenuProps: {
                items: pageDropdown,
            },
        },
        /* 
      {
        key: 'download',
        text: 'ดาวน์โหลด',
        iconProps: { iconName: 'Download' },
        disabled: !loadDataComplete || isProcessDocument,
        onClick: () => {
          console.log('Download');
          downloadZip();
        },
      },
       */
        {
            key: "upload-xml",
            text: "นำส่งไฟล์ XML",
            iconProps: { iconName: "Upload" },
            disabled:
                !loadDataComplete ||
                isProcessDocument ||
                selectedItems.length == 0 ||
                !isAdmin,
            onClick: () => {
                console.log("Upload");
                console.log("selectionItems: ", selectedItems);

                let confirm = window.confirm(
                    "กรุณายืนยัน กานำส่งไฟล์ XML เอกสารที่เลือก จำนวน " +
                        selectedItems.length +
                        " เอกสาร"
                );

                if (confirm && isAdmin) {
                    setIsProcessDocument(true);
                    uploadXmlSelectedDocuments();
                    // deleteSelectedDocuments();
                }
                /* 
                setLoadDataComplete(false);
                setDocuments([]);
                setItems([]);
                setGetNewDocuments(true);

                setTotalDocuments(0);
                setTotalVat(0);
                setTotalGrand(0);
    */
            },
        },
        {
            key: "refresh",
            text: "รีเฟรช",
            iconProps: { iconName: "Refresh" },
            disabled: !loadDataComplete || isProcessDocument,
            onClick: () => {
                console.log("Refresh");

                setLoadDataComplete(false);
                setDocuments([]);
                setItems([]);
                setGetNewDocuments(true);

                setTotalDocuments(0);
                setTotalVat(0);
                setTotalGrand(0);

                // setCurrentPage(1);

                // resetSort();
            },
        },
    ];

    const toThaiDateString = (isoDateTime) => {
        let date = "";
        date = new Date(isoDateTime);

        //console.log(dateTime.toLocaleDateString() + ' ' + dateTime.toLocaleTimeString());
        /* 
      let monthNames = [
        "มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน",
        "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม.",
        "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"
      ];
 */
        let year = date.getFullYear() + 543;
        //let month = monthNames[date.getMonth()];
        let month = (date.getMonth() + 1).toString().padStart(2, "0");
        let numOfDay = date.getDate().toString().padStart(2, "0");

        let hour = date.getHours().toString().padStart(2, "0");
        let minutes = date.getMinutes().toString().padStart(2, "0");
        let second = date.getSeconds().toString().padStart(2, "0");

        return `${numOfDay}/${month}/${year} ${hour}:${minutes}:${second} น.`;
    };

    const formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return "0 Bytes";

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return (
            parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
        );
    };
    /* 
    useEffect(() => {
      if (account) {
          instance.acquireTokenSilent({
              scopes: ["User.Read"],
              account: account
          }).then((response) => {
              if(response) {
                  callMsGraph(response.accessToken).then((result) => setApiData(result));
              }
          });
      }
    }, [account, instance]);
         */

    const getTotalProcessingDocuments = () => {
        console.log("Get total processing document...");
        instance
            .acquireTokenSilent({ ...silentRequest, account: account })
            .then((tokenResponse) => {
                //axios.get('https://etax.azure-api.net/company/b5cf38c9-5e52-4bd3-b737-caf5c6d30635')
                //axios.get('https://etax.azure-api.net/company/' + userId)
                //console.log(tokenResponse);
                //setUserFirstName(tokenResponse.idTokenClaims.given_name);

                //axios.get(API_URL + '/queues/processing/total',
                axios
                    .get(ADMIN_API_URL + "/etax/jobs/xml/processing/total", {
                        headers: {
                            Authorization:
                                "Bearer " + tokenResponse.accessToken,
                        },
                    })
                    .then(
                        (response) => {
                            console.log(
                                "Total processing jobs response: ",
                                response
                            );

                            if (response.data[0]) {
                                //console.log(JSON.stringify(response.data));
                                console.log(
                                    "Total processing jobs: ",
                                    response.data[0]
                                );

                                setTotalProcessingDocuments(response.data[0]);
                            } else {
                                setTotalProcessingDocuments(0);
                            }
                        },
                        (error) => {
                            console.log(error);
                        }
                    );
            })
            .catch((error) => {
                //Acquire token silent failure, and send an interactive request
                console.log(error);
                instance.acquireTokenRedirect(silentRequest);

                /* 
          if (error.errorMessage.indexOf("interaction_required") !== -1) {
              instance.acquireTokenRedirect(silentRequest);
          }
 */
            });
    };

    const initial = async () => {

        await instance.acquireTokenSilent({...silentRequest, account: account}).then(async tokenResponse => {
            console.log('tokenResponse: ', tokenResponse);

            await axios.get(ADMIN_API_URL + '/roles', 
                {
                headers: { 
                    'Authorization': 'Bearer ' + tokenResponse.accessToken
                } 
                })
                .then((response) => {                   

                    console.log(response);

                    if (response.data && response.data.roles) {

                        // setRole(response.data.roles);

                        if (response.data.roles === 'admin') {

                            setIsAdmin(true);
                            // console.log('remove array: ', removeByAttr(commandBarItems, 'key', 'delete'));
                        } else {
                            setIsAdmin(false);

                        }
                        
                    } else {
                        history.push({
                            pathname: '/maintenance',
                            state: {},
                        });
                    }

                    // setIsLoadRolesComplete(true);

                }, (error) => {
                    console.log(error);
                
                });      

        }).catch(error => {
           
            //Acquire token silent failure, and send an interactive request
            console.log(error);
            instance.acquireTokenRedirect(silentRequest);

        });

    }

    useEffect(() => {
        console.log("userEffect Call!");

        initial();

        //console.log('UTC now: ', convertDateToUTC());
    }, []);

    useEffect(() => {
        console.log("userEffect getNewDocuments Call!");

        //let certificateName = {};

        //token = b2cauth.getAccessToken();

        //b2cauth.run();
        if (getNewDocuments) {
            instance
                .acquireTokenSilent({ ...silentRequest, account: account })
                .then((tokenResponse) => {
                    // Do something with the tokenResponse
                    console.log("Token response: ", tokenResponse);

                    //axios.get('https://ws.leceipt.com/documents',
                    //axios.get(API_URL + '/documents?type=ETAX&subtype=' + documentType.key + '&fileextension=xml&from=' + from + '&to=' + to,
                    // axios.get(ADMIN_API_URL + '/etax/documents/' + documentType.key + '/xml?from=' + from + '&to=' + to + '&rdStatus=' + rdStatusType.key,
                    axios
                        .get(
                            ADMIN_API_URL +
                                "/etax/documents/" +
                                documentType.key +
                                "/xml?from=" +
                                from +
                                "&to=" +
                                to +
                                "&dateType=" +
                                dateType.key,
                            {
                                headers: {
                                    Authorization:
                                        "Bearer " + tokenResponse.accessToken,
                                },
                            }
                        )
                        .then(
                            (response) => {
                                console.log(response);
                                console.log(
                                    "Docs number: ",
                                    response.data.length
                                );

                                let _createdByFilterItems = [
                                    {
                                        key: "allCreatedBy",
                                        text: "บริษัททั้งหมด",
                                        iconProps: { iconName: "CityNext2" },
                                        onClick: () => {
                                            console.log("บริษัททั้งหมด");
                                            setCreatedByFilter({
                                                key: "allCreatedBy",
                                                text: "บริษัททั้งหมด",
                                            });
                                            sessionStorage.setItem(
                                                "uploadXml-createdByFilter-key",
                                                "allCreatedBy"
                                            );
                                            sessionStorage.setItem(
                                                "uploadXml-createdByFilter-text",
                                                "บริษัททั้งหมด"
                                            );

                                            setLoadDataComplete(false);
                                            setItems([]);
                                            setGetNewDocuments(true);

                                            setTotalDocuments(0);
                                            setTotalVat(0);
                                            setTotalGrand(0);
                                        },
                                        //['data-automation-id']: 'newEmailButton', // optional
                                    },
                                ];

                                let _docs = [];

                                if (response.data.length > 0) {
                                    response.data.forEach((_doc) => {
                                        // if ((_createdByFilterItems.length > 0) &&
                                        if (
                                            !_createdByFilterItems.some(
                                                (_d) => {
                                                    return (
                                                        _d.key ===
                                                        _doc.createdBy
                                                    );
                                                }
                                            )
                                        ) {
                                            /* 
                            _createdByFilterItems.push({
                                createdBy: _doc.createdBy,
                                companyName: _doc.data.company.name,
                                companyEmail: _doc.data.company.email
                            });
    */
                                            _createdByFilterItems.push({
                                                key: _doc.createdBy,
                                                text:
                                                    _doc.data.company.name +
                                                    " (" +
                                                    _doc.data.company.email +
                                                    ")",
                                                iconProps: {
                                                    iconName: "CityNext2",
                                                },
                                                onClick: () => {
                                                    console.log(
                                                        "บริษัททั้งหมด"
                                                    );
                                                    setCreatedByFilter({
                                                        key: _doc.createdBy,
                                                        text:
                                                            _doc.data.company
                                                                .name +
                                                            " (" +
                                                            _doc.data.company
                                                                .email +
                                                            ")",
                                                    });

                                                    sessionStorage.setItem(
                                                        "uploadXml-createdByFilter-key",
                                                        _doc.createdBy
                                                    );
                                                    sessionStorage.setItem(
                                                        "uploadXml-createdByFilter-text",
                                                        _doc.data.company.name +
                                                            " (" +
                                                            _doc.data.company
                                                                .email +
                                                            ")"
                                                    );

                                                    setLoadDataComplete(false);
                                                    setItems([]);
                                                    setGetNewDocuments(true);

                                                    setTotalDocuments(0);
                                                    setTotalVat(0);
                                                    setTotalGrand(0);

                                                    setCurrentPage(1);
                                                },
                                                //['data-automation-id']: 'newEmailButton', // optional
                                            });
                                        } else {
                                            // _createdByFilterItems.push({
                                            //     createdBy: _doc.createdBy,
                                            //     companyName: _doc.data.company.name
                                            // });
                                        }
                                    });

                                    setCreatedByFilterItems(
                                        _createdByFilterItems
                                    );

                                    console.log(
                                        "_createdByFilterItems: ",
                                        _createdByFilterItems
                                    );

                                    if (rdStatusType.key !== "allRdStatus") {
                                        if (rdStatusType.key === "null") {
                                            _docs = response.data.filter(
                                                (_doc) => {
                                                    return (
                                                        _doc.deliveries.rd
                                                            .status === null
                                                    );
                                                }
                                            );
                                        } else {
                                            _docs = response.data.filter(
                                                (_doc) => {
                                                    return (
                                                        _doc.deliveries.rd
                                                            .status ===
                                                        rdStatusType.key
                                                    );
                                                }
                                            );
                                        }
                                    } else {
                                        _docs = response.data;
                                    }

                                    if (
                                        createdByFilter.key !== "allCreatedBy"
                                    ) {
                                        _docs = _docs.filter((_doc) => {
                                            return (
                                                _doc.createdBy ===
                                                createdByFilter.key
                                            );
                                        });
                                    }
                                }

                                let _pageDropdown = [];

                                for (
                                    let i = 1;
                                    i <= Math.ceil(_docs.length / pageSize);
                                    i++
                                ) {
                                    _pageDropdown.push({
                                        key: i,
                                        text: "หน้า " + i,
                                        iconProps: { iconName: "Page" },
                                        onClick: () => {
                                            console.log("Selected page: " + i);
                                            setCurrentPage(i);
                                        },
                                    });
                                }

                                setPageDropdown(_pageDropdown);

                                if (
                                    currentPage >
                                    Math.ceil(_docs.length / pageSize)
                                ) {
                                    // setLoadDataComplete(false);
                                    setCurrentPage(1);
                                }

                                let _totalPending = 0;
                                let _totalSuccessful = 0;
                                let _totalFail = 0;

                                _docs.forEach((_doc) => {
                                    if (
                                        _doc.deliveries &&
                                        _doc.deliveries.rd &&
                                        _doc.deliveries.rd.status
                                    ) {
                                        switch (_doc.deliveries.rd.status) {
                                            case "pending":
                                                _totalPending += 1;
                                                break;
                                            case "successful":
                                                _totalSuccessful += 1;
                                                break;
                                            case "fail":
                                                _totalFail += 1;
                                                break;
                                            default:
                                                break;
                                        }
                                    }
                                });

                                setTotalPending(_totalPending);
                                setTotalSuccessful(_totalSuccessful);
                                setTotalFail(_totalFail);

                                setTotalDocuments(_docs.length);

                                //Sorting
                                if (_docs && _docs.length > 0) {
                                    if (primarySort === "createdTime") {
                                        if (isSortedDescendingCreatedTime) {
                                            _docs.sort(function (a, b) {
                                                // Turn your strings into dates, and then subtract them
                                                // to get a value that is either negative, positive, or zero.
                                                return (
                                                    new Date(b.createdTime) -
                                                    new Date(a.createdTime)
                                                );
                                            });
                                        } else {
                                            _docs.sort(function (a, b) {
                                                // Turn your strings into dates, and then subtract them
                                                // to get a value that is either negative, positive, or zero.
                                                return (
                                                    new Date(a.createdTime) -
                                                    new Date(b.createdTime)
                                                );
                                            });
                                        }
                                    } else if (primarySort === "number") {
                                        if (isSortedDescendingNumber) {
                                            _docs.sort((a, b) => {
                                                return (
                                                    /[A-Za-z]/.test(
                                                        b.data.number
                                                    ) -
                                                        /[A-Za-z]/.test(
                                                            a.data.number
                                                        ) ||
                                                    (b.data.number.toUpperCase() <
                                                    a.data.number.toUpperCase()
                                                        ? -1
                                                        : b.data.number.toUpperCase() >
                                                          a.data.number.toUpperCase()
                                                        ? 1
                                                        : 0)
                                                );
                                            });
                                        } else {
                                            _docs.sort((a, b) => {
                                                return (
                                                    /[A-Za-z]/.test(
                                                        a.data.number
                                                    ) -
                                                        /[A-Za-z]/.test(
                                                            b.data.number
                                                        ) ||
                                                    (a.data.number.toUpperCase() <
                                                    b.data.number.toUpperCase()
                                                        ? -1
                                                        : a.data.number.toUpperCase() >
                                                          b.data.number.toUpperCase()
                                                        ? 1
                                                        : 0)
                                                );
                                            });
                                        }
                                    } else if (primarySort === "documentDate") {
                                        if (isSortedDescendingDocumentDate) {
                                            _docs.sort(function (a, b) {
                                                // Turn your strings into dates, and then subtract them
                                                // to get a value that is either negative, positive, or zero.
                                                return (
                                                    new Date(b.data.date) -
                                                    new Date(a.data.date)
                                                );
                                            });
                                        } else {
                                            _docs.sort(function (a, b) {
                                                // Turn your strings into dates, and then subtract them
                                                // to get a value that is either negative, positive, or zero.
                                                return (
                                                    new Date(a.data.date) -
                                                    new Date(b.data.date)
                                                );
                                            });
                                        }
                                    } else if (primarySort === "customerName") {
                                        if (isSortedDescendingCustomerName) {
                                            _docs.sort((a, b) => {
                                                if (
                                                    b.data.customer.name <
                                                    a.data.customer.name
                                                ) {
                                                    return -1;
                                                }
                                                if (
                                                    b.data.customer.name >
                                                    a.data.customer.name
                                                ) {
                                                    return 1;
                                                }
                                                return 0;
                                            });
                                        } else {
                                            _docs.sort((a, b) => {
                                                if (
                                                    a.data.customer.name <
                                                    b.data.customer.name
                                                ) {
                                                    return -1;
                                                }
                                                if (
                                                    a.data.customer.name >
                                                    b.data.customer.name
                                                ) {
                                                    return 1;
                                                }
                                                return 0;
                                            });
                                        }
                                    } else if (
                                        primarySort === "customerPhone"
                                    ) {
                                        if (isSortedDescendingCustomerPhone) {
                                            _docs.sort((a, b) => {
                                                if (
                                                    b.data.customer.phone <
                                                    a.data.customer.phone
                                                ) {
                                                    return -1;
                                                }
                                                if (
                                                    b.data.customer.phone >
                                                    a.data.customer.phone
                                                ) {
                                                    return 1;
                                                }
                                                return 0;
                                            });
                                        } else {
                                            _docs.sort((a, b) => {
                                                if (
                                                    a.data.customer.phone <
                                                    b.data.customer.phone
                                                ) {
                                                    return -1;
                                                }
                                                if (
                                                    a.data.customer.phone >
                                                    b.data.customer.phone
                                                ) {
                                                    return 1;
                                                }
                                                return 0;
                                            });
                                        }
                                    } else if (primarySort === "vatTotal") {
                                        if (isSortedDescendingVatTotal) {
                                            _docs.sort(function (a, b) {
                                                return (
                                                    b.data.vatTotal -
                                                    a.data.vatTotal
                                                );
                                            });
                                        } else {
                                            _docs.sort(function (a, b) {
                                                return (
                                                    a.data.vatTotal -
                                                    b.data.vatTotal
                                                );
                                            });
                                        }
                                    } else if (primarySort === "grandTotal") {
                                        if (isSortedDescendingGrandTotal) {
                                            _docs.sort(function (a, b) {
                                                return (
                                                    b.data.grandTotal -
                                                    a.data.grandTotal
                                                );
                                            });
                                        } else {
                                            _docs.sort(function (a, b) {
                                                return (
                                                    a.data.grandTotal -
                                                    b.data.grandTotal
                                                );
                                            });
                                        }
                                    } else if (primarySort === "name") {
                                        if (isSortedDescendingName) {
                                            _docs.sort((a, b) => {
                                                if (b.data.name < a.data.name) {
                                                    return -1;
                                                }
                                                if (b.data.name > a.data.name) {
                                                    return 1;
                                                }
                                                return 0;
                                            });
                                        } else {
                                            _docs.sort((a, b) => {
                                                if (a.data.name < b.data.name) {
                                                    return -1;
                                                }
                                                if (a.data.name > b.data.name) {
                                                    return 1;
                                                }
                                                return 0;
                                            });
                                        }
                                    }
                                }

                                //set page
                                console.log(
                                    "Page data: ",
                                    paginate(_docs.length, 1, 5, 10)
                                );
                                setPageData(
                                    paginate(
                                        _docs.length,
                                        currentPage,
                                        pageSize,
                                        10
                                    )
                                );

                                setDocuments(_docs);

                                const _items = [];

                                //   let _isUploadXml = [];

                                if (_docs.length > 0) {
                                    for (
                                        let i = (currentPage - 1) * pageSize;
                                        i <
                                        Math.min(
                                            currentPage * pageSize,
                                            _docs.length
                                        );
                                        i++
                                    ) {
                                        let documentName = "";
                                        //let fileSize = formatBytes(_docs[i].fileSize);
                                        let fileSize = formatBytes(
                                            _docs[i].file.size
                                        );
                                        let createdTime = toThaiDateString(
                                            _docs[i].createdTime
                                        );
                                        let rdDeliveredTime = "";
                                        if (_docs[i].deliveries.rd.time) {
                                            rdDeliveredTime = toThaiDateString(
                                                _docs[i].deliveries.rd.time
                                            );
                                        }

                                        let rdRefNumber = "";
                                        if (_docs[i].deliveries.rd.refNumber) {
                                            rdRefNumber =
                                                _docs[i].deliveries.rd
                                                    .refNumber;
                                        }

                                        //let certificateId = _docs[i].certificateId;

                                        //if (_docs[i].type === 'ETAX') {
                                        if (_docs[i].product === "etax") {
                                            documentName =
                                                _docs[i].data.name +
                                                " " +
                                                _docs[i].data.number;
                                        }

                                        let _amountTotal = 0;
                                        let _vatTotal = 0;
                                        let _grandTotal = 0;

                                        if (_docs[i].data.amountTotal) {
                                            _amountTotal =
                                                _docs[i].data.amountTotal;
                                        }

                                        if (_docs[i].data.vatTotal) {
                                            _vatTotal = _docs[i].data.vatTotal;
                                        }

                                        if (_docs[i].data.grandTotal) {
                                            _grandTotal =
                                                _docs[i].data.grandTotal;
                                        }

                                        let _rdStatus = "-";

                                        if (
                                            _docs[i].deliveries.rd.status ===
                                            "pending"
                                        ) {
                                            _rdStatus = "รอส่งอัตโนมัติ";
                                        } else if (
                                            _docs[i].deliveries.rd.status ===
                                            "successful"
                                        ) {
                                            _rdStatus = "นำส่งสำเร็จ";
                                        } else if (
                                            _docs[i].deliveries.rd.status ===
                                            "fail"
                                        ) {
                                            _rdStatus = "นำส่งไม่สำเร็จ";
                                        }

                                        let _rdStatusResponse = "-";

                                        if (
                                            _docs[i].deliveries &&
                                            _docs[i].deliveries.rd &&
                                            _docs[i].deliveries.rd.response
                                        ) {
                                            if (
                                                _docs[i].deliveries.rd.response
                                                    .status === true
                                            ) {
                                                _rdStatusResponse = "สำเร็จ";
                                            } else if (
                                                _docs[i].deliveries.rd.response
                                                    .status === false
                                            ) {
                                                _rdStatusResponse = "ไม่สำเร็จ";
                                            }
                                        }

                                        _items.push({
                                            key: _docs[i].id,
                                            //fileName: _docs[i].fileName + '.' + _docs[i].fileExtension,
                                            fileName:
                                                _docs[i].file.name +
                                                "." +
                                                _docs[i].file.extension,
                                            //name: documentName,
                                            name: _docs[i].data.name,
                                            number: _docs[i].data.number,
                                            //value: _docs[i].fileName,
                                            value: _docs[i].file.name,
                                            //iconName: 'https://static2.sharepointonline.com/files/fabric/assets/item-types/16/xml.svg',
                                            //fileExtension: _docs[i].fileExtension,
                                            fileExtension:
                                                _docs[i].file.extension,
                                            createdBy: _docs[i].createdBy,
                                            createdTime: createdTime,
                                            createdTimeValue:
                                                _docs[i].createdTime,
                                            fileSize: fileSize,
                                            //fileSizeRaw: _docs[i].fileSize,
                                            fileSizeRaw: _docs[i].file.size,
                                            type: _docs[i].type,
                                            signature:
                                                _docs[i].signatures[0]
                                                    .certificateName,
                                            product: _docs[i].product,
                                            number: _docs[i].data.number,
                                            customerName:
                                                _docs[i].data.customer.name,
                                            companyName:
                                                _docs[i].data.company.name,
                                            //signature: certificateName[certificateId],
                                            //deliveryStatus: 'รอระบบนำส่ง'
                                            amountTotal: _amountTotal,
                                            vatTotal: _vatTotal,
                                            grandTotal: _grandTotal,
                                            rdStatus: _rdStatus,
                                            rdStatusResponse: _rdStatusResponse,
                                            rdDeliveredTime: rdDeliveredTime,
                                            rdDeliveredTimeValue:
                                                _docs[i].deliveries.rd.time,
                                            rdRefNumber,
                                            rdRefNumber,
                                            documentDate: _docs[i].data.dateBE,
                                            isUploadXml: false,
                                        });

                                        //   _isUploadXml.push(false);
                                    }

                                    let _totalVat = 0;
                                    let _totalGrand = 0;

                                    for (let i = 0; i < _docs.length; i++) {
                                        _totalVat =
                                            _totalVat + _docs[i].data.vatTotal;
                                        _totalGrand =
                                            _totalGrand +
                                            _docs[i].data.grandTotal;
                                    }

                                    setTotalVat(_totalVat);
                                    setTotalGrand(_totalGrand);

                                    //setLoadDataComplete(true);
                                    //setGetNewDocuments(false);
                                }

                                console.log("Docs data", _items);
                                setItems(_items);

                                setLoadDataComplete(true);
                                setGetNewDocuments(false);
                                // setCurrentPage(1);

                                //   setIsUploadXml(_isUploadXml);
                            },
                            (error) => {
                                console.log(error);
                            }
                        );
                })
                .catch((error) => {
                    /* 
        if (error instanceof InteractionRequiredAuthError) {
            // fallback to interaction when silent call fails
            return instance.acquireTokenRedirect(tokenRequest)
        }
 */

                    //Acquire token silent failure, and send an interactive request
                    console.log(error);
                    instance.acquireTokenRedirect(silentRequest);

                    /*         
        //if (error.errorMessage.indexOf("interaction_required") !== -1) {
        if (error.errorMessage.includes("AADB2C90077")) {
            //console.log('access token error');
            instance.acquireTokenRedirect(silentRequest);
        }

        console.log(account.username);
        return instance.ssoSilent({
          silentRequest,
          loginHint: account.username
        });


        const authError = error as AuthError;
        if (error instanceof InteractionRequiredAuthError
            || authError.errorMessage.includes("AADB2C90077")) {
            // fallback to interaction when silent call fails
            return this.msalApp.acquireTokenRedirect(silentRequest);
        }
 */
                });
        }
    }, [getNewDocuments]);

    useEffect(() => {
        if (documents.length > 0) {
            const _items = [];

            for (
                let i = (currentPage - 1) * pageSize;
                i < Math.min(currentPage * pageSize, documents.length);
                i++
            ) {
                let documentName = "";
                //let fileSize = formatBytes(documents[i].fileSize);
                let fileSize = formatBytes(documents[i].file.size);
                let createdTime = toThaiDateString(documents[i].createdTime);
                let rdDeliveredTime = "";
                if (documents[i].deliveries.rd.time) {
                    rdDeliveredTime = toThaiDateString(
                        documents[i].deliveries.rd.time
                    );
                }
                let rdRefNumber = "";
                if (documents[i].deliveries.rd.refNumber) {
                    rdRefNumber = documents[i].deliveries.rd.refNumber;
                }

                //let certificateId = documents[i].certificateId;

                //if (documents[i].type === 'ETAX') {
                if (documents[i].product === "etax") {
                    documentName =
                        documents[i].data.name + " " + documents[i].data.number;
                }

                let _amountTotal = 0;
                let _vatTotal = 0;
                let _grandTotal = 0;

                if (documents[i].data.amountTotal) {
                    _amountTotal = documents[i].data.amountTotal;
                }

                if (documents[i].data.vatTotal) {
                    _vatTotal = documents[i].data.vatTotal;
                }

                if (documents[i].data.grandTotal) {
                    _grandTotal = documents[i].data.grandTotal;
                }

                let _rdStatus = "-";

                if (documents[i].deliveries.rd.status === "pending") {
                    _rdStatus = "รอส่งอัตโนมัติ";
                } else if (documents[i].deliveries.rd.status === "successful") {
                    _rdStatus = "นำส่งสำเร็จ";
                } else if (documents[i].deliveries.rd.status === "fail") {
                    _rdStatus = "นำส่งไม่สำเร็จ";
                }

                let _rdStatusResponse = "-";

                if (
                    documents[i].deliveries &&
                    documents[i].deliveries.rd &&
                    documents[i].deliveries.rd.response
                ) {
                    if (documents[i].deliveries.rd.response.status === true) {
                        _rdStatusResponse = "สำเร็จ";
                    } else if (
                        documents[i].deliveries.rd.response.status === false
                    ) {
                        _rdStatusResponse = "ไม่สำเร็จ";
                    }
                }

                _items.push({
                    key: documents[i].id,
                    //fileName: documents[i].fileName + '.' + documents[i].fileExtension,
                    fileName:
                        documents[i].file.name +
                        "." +
                        documents[i].file.extension,
                    //name: documentName,
                    name: documents[i].data.name,
                    number: documents[i].data.number,
                    //value: documents[i].fileName,
                    value: documents[i].file.name,
                    //iconName: 'https://static2.sharepointonline.com/files/fabric/assets/item-types/16/xml.svg',
                    //fileExtension: documents[i].fileExtension,
                    fileExtension: documents[i].file.extension,
                    createdBy: documents[i].createdBy,
                    createdTime: createdTime,
                    createdTimeValue: documents[i].createdTime,
                    fileSize: fileSize,
                    //fileSizeRaw: documents[i].fileSize,
                    fileSizeRaw: documents[i].file.size,
                    type: documents[i].type,
                    signature: documents[i].signatures[0].certificateName,
                    product: documents[i].product,
                    customerName: documents[i].data.customer.name,
                    companyName: documents[i].data.company.name,
                    //signature: certificateName[certificateId],
                    //deliveryStatus: 'รอระบบนำส่ง'
                    amountTotal: _amountTotal,
                    vatTotal: _vatTotal,
                    grandTotal: _grandTotal,
                    rdStatus: _rdStatus,
                    rdStatusResponse: _rdStatusResponse,
                    rdDeliveredTime: rdDeliveredTime,
                    rdDeliveredTimeValue: documents[i].deliveries.rd.time,
                    rdRefNumber: rdRefNumber,
                    documentDate: documents[i].data.dateBE,
                    isUploadXml: false,
                });
            }

            console.log("Docs data", _items);

            setItems(_items);
            //setLoadDataComplete(true);
            //setGetNewDocuments(false);

            sessionStorage.setItem("uploadXml-currentPage", currentPage);
        }
        // setLoadDataComplete(true);
    }, [currentPage]);
    /*
    useEffect(() => {
      if (!loadDataComplete) {
          const id = setInterval(() => {
              setPercentComplete((intervalIncrement + percentComplete) % 1);
             
              if (html != '') {
                  setPercentComplete((intervalIncrement + 100) % 1);
                  console.log('100%');
              }
              
          }, intervalDelay);
          return () => {
              clearInterval(id);
          };
      }
    });*/

    useEffect(() => {
        console.log(
            "isProcessDocumentComplete useEffect: ",
            isProcessDocumentComplete
        );

        if (loadDataComplete && !isProcessDocument) {
            let _items = selection.getItems();
            console.log("_items: ", _items);

            let _selectedItems = selection.getSelection();
            console.log("_selectedItems: ", _selectedItems);

            if (isProcessDocumentComplete) {
                setIsProcessDocumentComplete(false);

                // let _items = selection.getItems();
                //let _keySelected = selection.getSelection()[0].key
                //console.log('_keySelected: ', _keySelected);
                for (let i = 0; i < _items.length; i++) {
                    if (_items[i].status != "") {
                        _items[i].status = "";
                    }
                }
                //selection.setAllSelected(true);
                //selection.setKeySelected(_keySelected, true, true);
                //setItems([...selection.getItems()]);
                // setItems([..._items]);
                //selection.setKeySelected(_keySelected, true, false);
            } else {
                for (let i = 0; i < _items.length; i++) {
                    if (_items[i].status != "") {
                        _items[i].status = "";
                    }
                }

                for (let i = 0; i < _selectedItems.length; i++) {
                    // console.log("select item key: ", _selectedItems[i].key);
                    // _selectedItems[i].status = 'pending';

                    for (let j = 0; j < _items.length; j++) {
                        //console.log('item key: ', _items);
                        if (_items[j].key === _selectedItems[i].key) {
                            // console.log("set pending item key: ", _items[j].key);
                            _items[j].status = "pending";
                            // console.log('_items: ', _items);

                            // _selectedItems[i].status = "pending";
                        }
                        /* else {
                        _items[j].status = "";
                    } */
                    }
                }
            }

            setItems(_items);
            //setSelectedItems(_selectedItems);
        }
    }, [selectedItems]);

    useEffect(() => {
        console.log("Items: ", items);
    }, [items]);

    const download = (event, item) => {
        //console.log('Event ', event);
        //token = token;

        //b2cauth.run();

        instance
            .acquireTokenSilent({ ...silentRequest, account: account })
            .then((tokenResponse) => {
                //axios.get(API_URL + '/documents/' + item.key, {
                axios
                    .get(
                        ADMIN_API_URL +
                            "/etax/files/" +
                            item.key +
                            "?extension=xml",
                        {
                            headers: {
                                Authorization:
                                    "Bearer " + tokenResponse.accessToken,
                            },
                            responseType: "blob",
                        }
                    )
                    .then(
                        (response) => {
                            console.log(response.headers);

                            const time = formatDateTime(item.createdTimeValue);

                            //console.log('item: ', item);

                            // let fileName = time + '-' + item.type + '-' + item.number.replace(/\//g,'') + '.' + item.fileExtension;
                            let fileName =
                                time +
                                "-" +
                                item.type +
                                "-" +
                                item.number.replace(/\//g, "") +
                                ".xml";

                            console.log("file name: ", fileName);

                            const url = window.URL.createObjectURL(
                                new Blob([response.data])
                            );
                            const link = document.createElement("a");
                            link.href = url;
                            link.setAttribute("download", fileName); //or any other extension
                            document.body.appendChild(link);
                            link.click();
                            link.remove();
                        },
                        (error) => {
                            console.log(error);
                        }
                    );
            })
            .catch((error) => {
                /* 
        if (error instanceof InteractionRequiredAuthError) {
            // fallback to interaction when silent call fails
            return instance.acquireTokenRedirect(tokenRequest)
        }
  */

                //Acquire token silent failure, and send an interactive request
                console.log(error);
                instance.acquireTokenRedirect(silentRequest);

                /* 
        //if (error.errorMessage.indexOf("interaction_required") !== -1) {
        if (error.errorMessage.includes("AADB2C90077")) {
          instance.acquireTokenRedirect(silentRequest);
        }
 */
            });
    };

    const deleteFile = (event, item) => {
        //console.log('Event ', event);
        //token = b2cauth.getAccessToken();
        //b2cauth.run();

        let confirm = window.confirm(
            "กรุณายืนยัน การลบไฟล์ XML เลขที่ " + item.number
        );

        if (confirm && isAdmin) {
            setLoadDataComplete(false);

            instance
                .acquireTokenSilent({ ...silentRequest, account: account })
                .then((tokenResponse) => {
                    //axios.delete(API_URL + '/documents/' + item.key,
                    axios
                        .delete(ADMIN_API_URL + "/etax/documents/" + item.key, {
                            headers: {
                                Authorization:
                                    "Bearer " + tokenResponse.accessToken,
                            },
                        })
                        .then(
                            (response) => {
                                console.log(response);
                                // setLoadDataComplete(false);
                                setGetNewDocuments(true);
                            },
                            (error) => {
                                console.log(error);
                            }
                        );
                })
                .catch((error) => {
                    //Acquire token silent failure, and send an interactive request
                    console.log(error);
                    instance.acquireTokenRedirect(silentRequest);
                });
        }
    };

    const reCreateFile = (event, item) => {
        let confirm = window.confirm(
            "กรุณายืนยัน การสร้างไฟล์ XML เลขที่ " +
                item.number +
                " ใหม่แทนที่ไฟล์เดิม"
        );

        if (confirm) {
            // setLoadDataComplete(false);

            instance
                .acquireTokenSilent({ ...silentRequest, account: account })
                .then((tokenResponse) => {
                    //axios.delete(API_URL + '/documents/' + item.key,
                    axios
                        .post(
                            ADMIN_API_URL + "/etax/xml-recreate/" + item.key,
                            {},
                            {
                                headers: {
                                    Authorization:
                                        "Bearer " + tokenResponse.accessToken,
                                },
                            }
                        )
                        .then(
                            (response) => {
                                console.log(
                                    "Finish reCreateXmlFile " +
                                        item.number +
                                        ": ",
                                    response
                                );
                                // setLoadDataComplete(false);
                                // setGetNewDocuments(true);
                            },
                            (error) => {
                                console.log(error);
                            }
                        );
                })
                .catch((error) => {
                    //Acquire token silent failure, and send an interactive request
                    console.log(error);
                    instance.acquireTokenRedirect(silentRequest);
                });
        }
    };

    const openFile = (event, item) => {
        history.push({
            pathname: "/etax/documents/xml/view",
            state: {
                item: item,
                backUrl: "/etax/documents/xml",
                //documentId: item.key,
                //certificateName: item.signature
            },
        });
    };

    const uploadXml = (event, item) => {
        let _items = [];
        _items = items;
        //console.log("Items: ", _items);

        //Find index of specific object using findIndex method.
        let objIndex = _items.findIndex((obj) => obj.key == item.key);
        //console.log("Items index update: ", objIndex);

        //Log object to Console.
        //console.log("Before update: ", _items[objIndex]);

        //Update object's name property.
        //_items[objIndex].isUploadXml = true;
        _items[objIndex].rdStatus = "กำลังนำส่ง...";

        //Log object to console again.
        //console.log("After update: ", _items[objIndex])

        setItems([..._items]);

        // setTimeout(function () {

        //     _items[objIndex].rdStatus = "นำส่งสำเร็จ";
        //     setItems([..._items]);

        // }, 5000);
        instance
            .acquireTokenSilent({ ...silentRequest, account: account })
            .then((tokenResponse) => {
                axios
                    .post(
                        ADMIN_API_URL + "/etax/xml/upload",
                        {
                            userOid: item.createdBy,
                            documentId: item.key,
                        },
                        {
                            headers: {
                                Authorization:
                                    "Bearer " + tokenResponse.accessToken,
                            },
                        }
                    )
                    .then(
                        async (response) => {
                            console.log("Upload XML response: ", response);
                            _items[objIndex].rdStatus = "นำส่งสำเร็จ";
                            setItems([..._items]);
                        },
                        (error) => {
                            console.log(error);
                        }
                    );
            })
            .catch((error) => {
                //Acquire token silent failure, and send an interactive request
                console.log(error);
                instance.acquireTokenRedirect(silentRequest);
            });

        // instance.acquireTokenSilent({...silentRequest, account: account}).then(tokenResponse => {

        //   //axios.get(API_URL + '/documents/' + item.key, {
        //   axios.get(ADMIN_API_URL + '/etax/files/' + item.key, {
        //       headers: {
        //         'Authorization': 'Bearer ' + tokenResponse.accessToken
        //       },
        //       responseType: 'blob'
        //     })
        //     .then((response) => {
        //       console.log(response.headers);

        //       const time = formatDateTime(item.createdTimeValue);

        //       //console.log('item: ', item);

        //       let fileName = time + '-' + item.type + '-' + item.number.replace(/\//g,'') + '.' + item.fileExtension;

        //       console.log('file name: ', fileName);

        //       const url = window.URL.createObjectURL(new Blob([response.data]));
        //       const link = document.createElement('a');
        //       link.href = url;
        //       link.setAttribute('download', fileName); //or any other extension
        //       document.body.appendChild(link);
        //       link.click();
        //       link.remove();

        //     }, (error) => {
        //       console.log(error);
        //     });

        // }).catch(error => {

        //   //Acquire token silent failure, and send an interactive request
        //   console.log(error);
        //   instance.acquireTokenRedirect(silentRequest);

        // });
    };

    const onPageChange = (selectedPageIndex) => {
        console.log("selectedPageIndex: ", selectedPageIndex);
        setCurrentPage(selectedPageIndex + 1);
    };

    const downloadZip = () => {
        //console.log('Event ', event);
        //token = token;

        //b2cauth.run();

        //console.log(API_URL + '/etax/files/' + documentType.key + '/xml?from=' + from + '&to=' + to);

        instance
            .acquireTokenSilent({ ...silentRequest, account: account })
            .then((tokenResponse) => {
                /* 
        axios
          .get(API_URL + '/etax/documents?' + 
            '&type=ETAX' +
            '&subType=' + documentType.key +
            '&from=' + from +
            '&to=' + to +
            '&fileExtension=xml',    
      */
                //axios.get(API_URL + '/etax/documents/' + documentType.key + '/xml?from=' + from + '&to=' + to,
                axios
                    .get(
                        API_URL +
                            "/etax/files/" +
                            documentType.key +
                            "/xml?from=" +
                            from +
                            "&to=" +
                            to,
                        {
                            headers: {
                                Authorization:
                                    "Bearer " + tokenResponse.accessToken,
                            },
                            responseType: "blob",
                        }
                    )
                    .then(
                        (response) => {
                            console.log(response.headers);
                            /*
            let headerLine = response.headers['Content-Disposition'];
            let startFileNameIndex = headerLine.indexOf('"') + 1
            let endFileNameIndex = headerLine.lastIndexOf('"');
            let filename = headerLine.substring(startFileNameIndex, endFileNameIndex);

            console.log('File name: ', filename);
*/
                            let zipFileName =
                                from.substr(0, 4) +
                                from.substr(5, 2) +
                                "-" +
                                documentType.key +
                                "-XML-Leceipt.zip";

                            console.log("Zip file name: ", zipFileName);

                            const url = window.URL.createObjectURL(
                                new Blob([response.data])
                            );
                            const link = document.createElement("a");
                            link.href = url;
                            link.setAttribute("download", zipFileName); //or any other extension
                            document.body.appendChild(link);
                            link.click();
                            link.remove();
                        },
                        (error) => {
                            console.log(error);
                        }
                    );
            })
            .catch((error) => {
                /* 
        if (error instanceof InteractionRequiredAuthError) {
            // fallback to interaction when silent call fails
            return instance.acquireTokenRedirect(tokenRequest)
        }
  */

                //Acquire token silent failure, and send an interactive request
                console.log(error);
                instance.acquireTokenRedirect(silentRequest);

                /* 
        //if (error.errorMessage.indexOf("interaction_required") !== -1) {
        if (error.errorMessage.includes("AADB2C90077")) {
          instance.acquireTokenRedirect(silentRequest);
        }
 */
            });
    };

    /* 
    const items = [
        {
            key: '1',
            name: 'file name',
            value: 'file name',
            iconName: 'https://static2.sharepointonline.com/files/fabric/assets/item-types/16/xml.svg',
            fileExtension: 'xml',
            modifiedBy: 'Krit',
            createdTime: '13/02/2021',
            createdTimeValue: 1613195285881,
            fileSize: 123,
            fileSizeRaw: 12345,
            type: 'e-Tax',
            signature: 'บริษัท ทดสอบ จำกัด',
            deliveryStatus: 'รอระบบนำส่ง',

        }
    ];
 */
    /* 
    const onRenderItemColumn = () => {
      if (column.fieldName === 'fieldName') {
        return <Link data-selection-invoke={true}>{"Edit"}</Link>;
      }
      return item[column.fieldName];

    }
 */

    const uploadXmlSelectedDocuments = async () => {
        let _items = selection.getItems();

        await instance
            .acquireTokenSilent({ ...silentRequest, account: account })
            .then(async (tokenResponse) => {
                for (let i = 0; i < selectedItems.length; i++) {
                    console.log(
                        "Upload XML selected document: ",
                        selectedItems[i]
                    );

                    for (let j = 0; j < _items.length; j++) {
                        //console.log('item key: ', _items);
                        if (_items[j].key === selectedItems[i].key) {
                            console.log("item key: ", _items[j].key);
                            _items[j].status = "uploading";
                            //_selectedItems[i].status = "pending";
                            //setItems([]);
                            //_items.sort();
                            console.log("items: ", _items);
                            setItems([..._items]);
                            /* 
                        await new Promise(resolve => {                        
                            setTimeout(resolve, 1500)                        
                        });
    */

                            console.log("item: ", _items[j]);
                            // let detail = await getInvoiceDetail(_items[j].number);

                            const promiseDelete = await new Promise(
                                async (resolve) => {
                                    axios
                                        .post(
                                            ADMIN_API_URL + "/etax/xml/upload",
                                            {
                                                userOid: _items[j].createdBy,
                                                documentId: _items[j].key,
                                            },
                                            {
                                                headers: {
                                                    Authorization:
                                                        "Bearer " +
                                                        tokenResponse.accessToken,
                                                },
                                            }
                                        )
                                        .then(
                                            async (response) => {
                                                console.log(
                                                    "Upload XML response: ",
                                                    response
                                                );

                                                let count = 0;

                                                let interval =
                                                    await setInterval(
                                                        async () => {
                                                            count += 1;

                                                            if (
                                                                response.data &&
                                                                response.data
                                                                    .data &&
                                                                response.data
                                                                    .data
                                                                    .length >
                                                                    0 &&
                                                                response.data
                                                                    .data[0]
                                                                    .status ===
                                                                    true
                                                            ) {
                                                                //array[i].status = 'สำเร็จ';
                                                                _items[
                                                                    j
                                                                ].rdStatus =
                                                                    "นำส่งสำเร็จ";
                                                                _items[
                                                                    j
                                                                ].status =
                                                                    "uploaded";

                                                                setItems([
                                                                    ..._items,
                                                                ]);

                                                                clearInterval(
                                                                    interval
                                                                );

                                                                resolve(true);
                                                            } else if (
                                                                count === 10
                                                            ) {
                                                                // array[i].status = 'ไม่สำเร็จ';
                                                                _items[
                                                                    j
                                                                ].rdStatus =
                                                                    "นำส่งไม่สำเร็จ";
                                                                _items[
                                                                    j
                                                                ].status =
                                                                    "fail";

                                                                setItems([
                                                                    ..._items,
                                                                ]);

                                                                clearInterval(
                                                                    interval
                                                                );

                                                                resolve(false);
                                                            }
                                                        },
                                                        1000
                                                    );
                                            },
                                            (error) => {
                                                console.log(
                                                    "Error upload XML response: ",
                                                    error
                                                );
                                                _items[j].status = "fail";
                                                setItems([..._items]);
                                                resolve(false);
                                            }
                                        );
                                }
                            ); //promise

                            Promise.all([promiseDelete]).then((value) => {
                                console.log(i, value);
                            });
                        } //if
                    } //for j
                } //for i
            })
            .catch((error) => {
                //Acquire token silent failure, and send an interactive request
                console.log(error);
                //instance.acquireTokenRedirect(silentRequest);
            });

        setIsProcessDocumentComplete(true);
        setIsProcessDocument(false);
    };

    const columns = [
        {
            key: "column1",
            name: "วันที่สร้าง",
            fieldName: "createdTime",
            minWidth: 70,
            maxWidth: 125,
            isResizable: true,
            isSorted: primarySort === "createdTime" ? true : false,
            isSortedDescending: isSortedDescendingCreatedTime,
            isSortedAscending: !isSortedDescendingCreatedTime,
            onColumnClick: () => {
                console.log("Sort document date!");
                setPrimarySort("createdTime");
                setIsSortedDescendingCreatedTime(
                    !isSortedDescendingCreatedTime
                );
                
                setLoadDataComplete(false);
                setDocuments([]);
                setItems([]);
                setGetNewDocuments(true);

                setTotalDocuments(0);
                setTotalVat(0);
                setTotalGrand(0);

                sessionStorage.setItem("uploadXml-primarySort", "createdTime");
                sessionStorage.setItem(
                    "uploadXml-sort-createdTime",
                    !isSortedDescendingCreatedTime === true ? "true" : "false"
                );
                
                sessionStorage.setItem("uploadXml-sort-number", "false");
                sessionStorage.setItem("uploadXml-sort-documentDate", "false");
                sessionStorage.setItem("uploadXml-sort-customerName", "false");
                sessionStorage.setItem("uploadXml-sort-customerPhone", "false");
                sessionStorage.setItem("uploadXml-sort-vatTotal", "false");
                sessionStorage.setItem("uploadXml-sort-grandTotal", "false");
                sessionStorage.setItem("uploadXml-sort-name", "false");
    
            },
            data: "string",
            onRender: (item) => {
                return <span>{item.createdTime}</span>;
            },
            //isPadded: true,
        },
        {
            key: "column2",
            name: "เลขที่",
            fieldName: "number",
            minWidth: 80,
            maxWidth: 150,
            isRowHeader: true,
            isResizable: true,
            isSorted: primarySort === "number" ? true : false,
            isSortedDescending: isSortedDescendingNumber,
            isSortedAscending: !isSortedDescendingNumber,
            onColumnClick: () => {
                console.log("Sort document number!");
                setPrimarySort("number");
                setIsSortedDescendingNumber(!isSortedDescendingNumber);

                setLoadDataComplete(false);
                setDocuments([]);
                setItems([]);
                setGetNewDocuments(true);
                // setPreviousSearch("");
                // setSearch("");

                setTotalDocuments(0);
                setTotalVat(0);
                setTotalGrand(0);

                sessionStorage.setItem("uploadXml-primarySort", "number");
                sessionStorage.setItem(
                    "uploadXml-sort-number",
                    !isSortedDescendingNumber === true ? "true" : "false"
                );

                sessionStorage.setItem("uploadXml-sort-createdTime", "false");
                sessionStorage.setItem("uploadXml-sort-documentDate", "false");
                sessionStorage.setItem("uploadXml-sort-customerName", "false");
                sessionStorage.setItem("uploadXml-sort-customerPhone", "false");
                sessionStorage.setItem("uploadXml-sort-vatTotal", "false");
                sessionStorage.setItem("uploadXml-sort-grandTotal", "false");
                sessionStorage.setItem("uploadXml-sort-name", "false");
            },
            data: "string",
            onRender: (item) => {
                return (
                    <span data-selection-disabled={true}>
                        <TooltipHost content={item.key}>
                            <ActionButton
                                // title="ดาวน์โหลด"
                                title="Copy document id"
                                className={classNames.fileIconImg}
                                onClick={(event) => {
                                    // download(event, item);

                                    // copy document id to clipboard
                                    navigator.clipboard.writeText(item.key);

                                    /* Alert the copied text */
                                    alert("Copied document id: " + item.key);
                                }}
                            >
                                {item.number}
                            </ActionButton>
                        </TooltipHost>
                    </span>
                );
            },
            //isPadded: true,
        },
        {
            key: "column3",
            name: "วันที่บนเอกสาร",
            fieldName: "documentDate",
            minWidth: 70,
            maxWidth: 70,
            isRowHeader: true,
            isResizable: true,
            isSorted: primarySort === "documentDate" ? true : false,
            isSortedDescending: isSortedDescendingDocumentDate,
            isSortedAscending: !isSortedDescendingDocumentDate,
            onColumnClick: () => {
                console.log("Sort documentDate!");
                setPrimarySort("documentDate");
                setIsSortedDescendingDocumentDate(
                    !isSortedDescendingDocumentDate
                );

                setLoadDataComplete(false);
                setDocuments([]);
                setItems([]);
                setGetNewDocuments(true);
                // setPreviousSearch("");
                // setSearch("");

                setTotalDocuments(0);
                setTotalVat(0);
                setTotalGrand(0);

                sessionStorage.setItem("uploadXml-primarySort", "documentDate");
                sessionStorage.setItem(
                    "uploadXml-sort-documentDate",
                    !isSortedDescendingDocumentDate === true ? "true" : "false"
                );

                sessionStorage.setItem("uploadXml-sort-createdTime", "false");
                sessionStorage.setItem("uploadXml-sort-number", "false");
                sessionStorage.setItem("uploadXml-sort-customerName", "false");
                sessionStorage.setItem("uploadXml-sort-customerPhone", "false");
                sessionStorage.setItem("uploadXml-sort-vatTotal", "false");
                sessionStorage.setItem("uploadXml-sort-grandTotal", "false");
                sessionStorage.setItem("uploadXml-sort-name", "false");
            },
            data: "string",
            onRender: (item) => {
                return (
                    <TooltipHost content={item.documentDate}>
                        <span>{item.documentDate}</span>
                    </TooltipHost>
                );
            },
            //isPadded: true,
        },
        {
            key: "column4",
            name: "ผู้ขาย",
            fieldName: "companyName",
            minWidth: 80,
            maxWidth: 250,
            isRowHeader: true,
            isResizable: true,
            //isSorted: true,
            //isSortedDescending: true,
            onColumnClick: "",
            data: "string",
            onRender: (item) => {
                return (
                    <TooltipHost content={item.companyName}>
                        <span>{item.companyName}</span>
                    </TooltipHost>
                );
            },
            //isPadded: true,
        },
        {
            key: "column5",
            name: "ผู้ซื้อ",
            fieldName: "customerName",
            minWidth: 80,
            maxWidth: 250,
            isCollapsible: true,
            isRowHeader: true,
            isResizable: true,
            isSorted: primarySort === "customerName" ? true : false,
            isSortedDescending: isSortedDescendingCustomerName,
            isSortedAscending: !isSortedDescendingCustomerName,
            onColumnClick: () => {
                console.log("Sort customerName!");
                setPrimarySort("customerName");
                setIsSortedDescendingCustomerName(
                    !isSortedDescendingCustomerName
                );

                setLoadDataComplete(false);
                setDocuments([]);
                setItems([]);
                setGetNewDocuments(true);
                // setPreviousSearch("");
                // setSearch("");

                setTotalDocuments(0);
                setTotalVat(0);
                setTotalGrand(0);

                sessionStorage.setItem("uploadXml-primarySort", "customerName");
                sessionStorage.setItem(
                    "uploadXml-sort-customerName",
                    !isSortedDescendingCustomerName === true ? "true" : "false"
                );

                sessionStorage.setItem("uploadXml-sort-createdTime", "false");
                sessionStorage.setItem("uploadXml-sort-number", "false");
                sessionStorage.setItem("uploadXml-sort-customerPhone", "false");
                sessionStorage.setItem("uploadXml-sort-documentDate", "false");
                sessionStorage.setItem("uploadXml-sort-vatTotal", "false");
                sessionStorage.setItem("uploadXml-sort-grandTotal", "false");
                sessionStorage.setItem("uploadXml-sort-name", "false");
            },
            data: "string",
            onRender: (item) => {
                return (
                    <TooltipHost content={item.customerName}>
                        <span>{item.customerName}</span>
                    </TooltipHost>
                );
            },
            //isPadded: true,
        },
        {
            key: "column6",
            name: "ยอด (รวม Vat)",
            fieldName: "grandTotal",
            minWidth: 70,
            maxWidth: 70,
            isCollapsible: true,
            isRowHeader: true,
            isResizable: true,
            isSorted: primarySort === "grandTotal" ? true : false,
            isSortedDescending: isSortedDescendingGrandTotal,
            isSortedAscending: !isSortedDescendingGrandTotal,
            onColumnClick: () => {
                console.log("Sort grandTotal!");
                setPrimarySort("grandTotal");
                setIsSortedDescendingGrandTotal(!isSortedDescendingGrandTotal);

                setLoadDataComplete(false);
                setDocuments([]);
                setItems([]);
                setGetNewDocuments(true);
                // setPreviousSearch("");
                // setSearch("");

                setTotalDocuments(0);
                setTotalVat(0);
                setTotalGrand(0);

                sessionStorage.setItem("uploadXml-primarySort", "grandTotal");
                sessionStorage.setItem(
                    "uploadXml-sort-grandTotal",
                    !isSortedDescendingGrandTotal === true ? "true" : "false"
                );

                sessionStorage.setItem("uploadXml-sort-createdTime", "false");
                sessionStorage.setItem("uploadXml-sort-number", "false");
                sessionStorage.setItem("uploadXml-sort-documentDate", "false");
                sessionStorage.setItem("uploadXml-sort-customerName", "false");
                sessionStorage.setItem("uploadXml-sort-customerPhone", "false");
                sessionStorage.setItem("uploadXml-sort-vatTotal", "false");
                sessionStorage.setItem("uploadXml-sort-name", "false");
            },
            data: "string",
            onRender: (item) => {
                return (
                    <span style={{ display: "block", textAlign: "right" }}>
                        {item.grandTotal.toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2,
                        })}
                    </span>
                );
            },
            //isPadded: true,
        },
        {
            key: "column7",
            name: "เอกสาร",
            fieldName: "name",
            minWidth: 140,
            maxWidth: 180,
            isCollapsible: true,
            isRowHeader: true,
            isResizable: true,
            isSorted: primarySort === "name" ? true : false,
            isSortedDescending: isSortedDescendingName,
            isSortedAscending: !isSortedDescendingName,
            onColumnClick: () => {
                console.log("Sort name!");
                setPrimarySort("name");
                setIsSortedDescendingName(!isSortedDescendingName);

                setLoadDataComplete(false);
                setDocuments([]);
                setItems([]);
                setGetNewDocuments(true);
                // setPreviousSearch("");
                // setSearch("");

                setTotalDocuments(0);
                setTotalVat(0);
                setTotalGrand(0);

                sessionStorage.setItem("uploadXml-primarySort", "name");
                sessionStorage.setItem(
                    "uploadXml-sort-name",
                    !isSortedDescendingName === true ? "true" : "false"
                );

                sessionStorage.setItem("uploadXml-sort-createdTime", "false");
                sessionStorage.setItem("uploadXml-sort-number", "false");
                sessionStorage.setItem("uploadXml-sort-documentDate", "false");
                sessionStorage.setItem("uploadXml-sort-customerName", "false");
                sessionStorage.setItem("uploadXml-sort-customerPhone", "false");
                sessionStorage.setItem("uploadXml-sort-vatTotal", "false");
                sessionStorage.setItem("uploadXml-sort-grandTotal", "false");
            },
            data: "string",
            onRender: (item) => {
                return (
                    <ActionButton
                        title="เปิดไฟล์"
                        className={classNames.fileIconImg}
                        onClick={(event) => {
                            openFile(event, item);
                        }}
                    >
                        {item.name}
                    </ActionButton>
                );
            },
            //isPadded: true,
        },
        {
            key: "column8",
            name: "สถานะนำส่ง",
            fieldName: "status",
            minWidth: 60,
            maxWidth: 60,
            isResizable: true,
            //isSorted: true,
            //isSortedDescending: true,
            onColumnClick: "",
            data: "string",
            onRender: (item) => {
                return item.isUploadXml ? (
                    <Spinner size={SpinnerSize.small} />
                ) : (
                    <span style={{ display: "block", textAlign: "center" }}>
                        {item.rdStatus}
                    </span>
                );
            },
            // onRender: (item) => {
            //     return (
            //         item.isUploadXml
            //             ?
            //             <Spinner size={SpinnerSize.small} />
            //             :
            //             <span style={{ display: 'block', textAlign: 'center' }}>{item.rdStatus}</span>
            //     );
            // },
            //isPadded: true,
        },
        {
            key: "column9",
            name: "วันที่นำส่ง",
            fieldName: "status",
            minWidth: 50,
            maxWidth: 50,
            isResizable: true,
            //isSorted: true,
            //isSortedDescending: true,
            isCollapsible: true,
            onColumnClick: "",
            data: "string",
            onRender: (item) => {
                return (
                    <TooltipHost content={item.rdDeliveredTime}>
                        <span>{item.rdDeliveredTime}</span>
                    </TooltipHost>
                );
            },
            //isPadded: true,
        },
        {
            key: "column10",
            name: "หมายเลขนำส่ง",
            fieldName: "status",
            minWidth: 50,
            maxWidth: 50,
            isResizable: true,
            //isSorted: true,
            //isSortedDescending: true,
            isCollapsible: true,
            onColumnClick: "",
            data: "string",
            onRender: (item) => {
                return (
                    <TooltipHost content={item.rdRefNumber}>
                        <span>{item.rdRefNumber}</span>
                    </TooltipHost>
                );
            },
            //isPadded: true,
        },
        {
            key: "column11",
            name: "Status",
            fieldName: "rdStatusResponse",
            minWidth: 50,
            maxWidth: 50,
            isResizable: true,
            //isSorted: true,
            //isSortedDescending: true,
            onColumnClick: "",
            data: "string",
            onRender: (item) => {
                return (
                    <span style={{ display: "block", textAlign: "center" }}>
                        {item.rdStatusResponse}
                    </span>
                );
            },
            //isPadded: true,
        },
        {
            key: "column12",
            name: "สร้างโดย",
            fieldName: "createdBy",
            minWidth: 20,
            maxWidth: 50,
            isResizable: true,
            //isSorted: true,
            //isSortedDescending: true,
            // isCollapsible: true,
            onColumnClick: "",
            data: "string",
            onRender: (item) => {
                return (
                    <TooltipHost content={item.createdBy}>
                        <span style={{ display: "block", textAlign: "center" }}>
                            {item.createdBy}
                        </span>
                    </TooltipHost>
                );
            },
            //isPadded: true,
        },
        {
            key: "column13",
            name: "",
            fieldName: "command",
            minWidth: 120,
            maxWidth: 120,
            isResizable: true,
            //isCollapsible: true,
            data: "string",
            onColumnClick: "",
            onRender: (item) => {
                if (isProcessDocument || isProcessDocumentComplete) {
                    if (item.status === "pending") {
                        return (
                            <span style={{ color: "#106EBE" }}>
                                รอดำเนินการ
                            </span>
                        );
                        // return <Spinner label="กำลังสร้างเอกสาร..." labelPosition="right"/>
                    } else if (item.status === "processing") {
                        return (
                            <Spinner
                                label="กำลังดำเนินการ..."
                                labelPosition="right"
                                style={{ marginLeft: "-35px" }}
                            />
                        );
                    } else if (item.status === "successful") {
                        return <span style={{ color: "green" }}>สำเร็จ!</span>;
                    } else if (item.status === "fail") {
                        return <span style={{ color: "red" }}>ไม่สำเร็จ!</span>;
                    } else if (item.status === "deleting") {
                        return (
                            <Spinner
                                label="กำลังลบเอกสาร..."
                                labelPosition="right"
                                style={{ marginLeft: "-35px" }}
                            />
                        );
                    } else if (item.status === "deleted") {
                        return (
                            <span style={{ color: "green" }}>
                                ลบเอกสารสำเร็จ!
                            </span>
                        );
                    } else if (item.status === "uploading") {
                        return (
                            <Spinner
                                label="กำลังอัปโหลด..."
                                labelPosition="right"
                                style={{ marginLeft: "0px" }}
                            />
                        );
                    } else if (item.status === "uploaded") {
                        return (
                            <span style={{ color: "green" }}>
                                อัปโหลดสำเร็จ!
                            </span>
                        );
                    }
                } else {
                    return (
                        <span data-selection-disabled={true}>
                            {isAdmin
                                ?
                                    <DefaultButton
                                        text="เลือก"
                                        //iconProps={{ iconName: 'Add' }}
                                        menuProps={{
                                            items: [
                                                {
                                                    key: "upload",
                                                    text: "นำส่ง XML",
                                                    iconProps: {
                                                        iconName: "CloudUpload",
                                                    },
                                                    //disabled: item.rdRefNumber ? false : true,
                                                    disabled:
                                                        item.rdStatus !== "successful"
                                                            ? false
                                                            : true,
                                                    onClick: (event) => {
                                                        uploadXml(event, item);
                                                    },
                                                },
                                                {
                                                    key: "open",
                                                    text: "เปิดเอกสาร",
                                                    iconProps: { iconName: "OpenFile" },
                                                    onClick: (event) => {
                                                        openFile(event, item);
                                                    },
                                                },
                                                {
                                                    key: "download",
                                                    text: "ดาวน์โหลด",
                                                    iconProps: {
                                                        iconName: "DownloadDocument",
                                                    },
                                                    onClick: (event) => {
                                                        download(event, item);
                                                    },
                                                },                                        
                                                {
                                                    key: "create",
                                                    text: "สร้างไฟล์ XML ใหม่",
                                                    iconProps: {
                                                        iconName: "RepeatAll",
                                                    },
                                                    //className: { classNames: classNames.fileIconImg },
                                                    // styles: {
                                                    //     root: {
                                                    //         //fontSize: 20,
                                                    //         color: '#D65633',
                                                    //     }
                                                    // },
                                                    onClick: (event) => {
                                                        reCreateFile(event, item);
                                                    },
                                                },
/* 
                                                {
                                                    key: "divider_1",
                                                    itemType:
                                                        ContextualMenuItemType.Divider,
                                                },
                                                {
                                                    key: "delete",
                                                    text: "ลบไฟล์ XML",
                                                    iconProps: { iconName: "Delete" },
                                                    //className: { classNames: classNames.fileIconImg },
                                                    // styles: {
                                                    //     root: {
                                                    //         //fontSize: 20,
                                                    //         color: '#D65633',
                                                    //     }
                                                    // },
                                                    onClick: (event) => {
                                                        deleteFile(event, item);
                                                    },
                                                },
                                                 */
                                            ],
                                            shouldFocusOnMount: true,
                                            directionalHintFixed: true,
                                        }}
                                        // Optional callback to customize menu rendering
                                        menuAs={(props) => (
                                            <ContextualMenu {...props} />
                                        )}
                                        // Optional callback to do other actions (besides opening the menu) on click
                                        //onMenuClick={(ev) => { console.log(ev); }}
                                        // By default, the ContextualMenu is re-created each time it's shown and destroyed when closed.
                                        // Uncomment the next line to hide the ContextualMenu but persist it in the DOM instead.
                                        // persistMenu={true}
                                        //allowDisabledFocus
                                        //disabled={(props) => props}
                                        //checked={(props) => props}
                                    />
                                :
                                    <DefaultButton
                                        text="เลือก"
                                        //iconProps={{ iconName: 'Add' }}
                                        menuProps={{
                                            items: [                                                
                                                {
                                                    key: "open",
                                                    text: "เปิดเอกสาร",
                                                    iconProps: { iconName: "OpenFile" },
                                                    onClick: (event) => {
                                                        openFile(event, item);
                                                    },
                                                },
                                                {
                                                    key: "download",
                                                    text: "ดาวน์โหลด",
                                                    iconProps: {
                                                        iconName: "DownloadDocument",
                                                    },
                                                    onClick: (event) => {
                                                        download(event, item);
                                                    },
                                                },                                       
                                                                                            
                                            ],
                                            shouldFocusOnMount: true,
                                            directionalHintFixed: true,
                                        }}
                                        // Optional callback to customize menu rendering
                                        menuAs={(props) => (
                                            <ContextualMenu {...props} />
                                        )}
                                        // Optional callback to do other actions (besides opening the menu) on click
                                        //onMenuClick={(ev) => { console.log(ev); }}
                                        // By default, the ContextualMenu is re-created each time it's shown and destroyed when closed.
                                        // Uncomment the next line to hide the ContextualMenu but persist it in the DOM instead.
                                        // persistMenu={true}
                                        //allowDisabledFocus
                                        //disabled={(props) => props}
                                        //checked={(props) => props}
                                    />
                            }
                        </span>
                    );
                }
            },
        },
        /* 
      {
        key: 'column15',
        name: '',
        fieldName: 'command',
        minWidth: 70,
        maxWidth: 100,
        isResizable: true,
        isCollapsible: true,
        data: 'string',
        onColumnClick: '',
        onRender: (item) => {
          //console.log('Item: ', item);

          
            return <ActionButton iconProps={
              {
                iconName: 'Delete',
                //iconName: 'PageRemove',

                styles: {
                    root: {
                        //fontSize: 20,
                        color: '#D65633',
                    }
                }
                
              }
            } title="ลบ" className={classNames.fileIconImg} onClick={(event) => {deleteFile(event, item)}} >
              ลบ
            </ActionButton>;
           
        },
       
      },
       */
        /*   {
        key: 'column8',
        name: 'สถานะการนำส่ง',
        fieldName: 'deliveryStatus',
        minWidth: 70,
        maxWidth: 90,
        isResizable: true,
        isCollapsible: true,
        data: 'string',
        onColumnClick: '',
        onRender: (item) => {
          return <span>{item.deliveryStatus}</span>;
        },
      }, */
    ];

    const isCompactMode = false;
    //const isModealSelection = false;
    //const annoucedMessage = undefined;

    return (
        <div style={homeStyles}>
            <Stack>
                <center>
                    <h2 style={{ marginLeft: "0px", marginTop: "25px", color: "#A4262C" }}>
                        เอกสาร XML Legacy
                    </h2>
                    {/* <h4>( สำหรับส่งให้กรมสรรพากร )</h4> */}
                    {/* <br/> */}
                </center>

                <Stack
                    horizontal
                    horizontalAlign="center"
                    //wrap
                    /* 
                styles={{
                  root: {
                    background: '#FAF9F8',                    
                  } 
                }} 
                tokens={{ childrenGap: '2%' }}
 */
                    tokens={{ childrenGap: "30px" }}
                >
                    <DocumentCard
                        aria-label="รอนำส่ง"
                        styles={cardStyles.cardStyles}
                        type={DocumentCardType.compact}
                        //onClickHref="http://bing.com"
                    >
                        <DocumentCardLogo
                            {...{ logoIcon: "Recent" }}
                            title="รอนำส่ง"
                        />
                        {/* <div className={conversationTileClass}> */}
                        <DocumentCardDetails>
                            <DocumentCardTitle
                                title="รอนำส่ง"
                                //shouldTruncate
                                styles={cardStyles.header}
                            />
                            {/* <Text styles={cardStyles.amount}>{totalDocuments} ใบ</Text> */}
                            <Text styles={cardStyles.amount}>
                                <CountUp
                                    end={totalPending}
                                    separator=","
                                    duration={1}
                                />{" "}
                                เอกสาร
                            </Text>
                            {/* </div>  */}
                        </DocumentCardDetails>
                    </DocumentCard>

                    <DocumentCard
                        aria-label="นำส่งไม่สำเร็จ"
                        styles={cardStyles.cardStyles}
                        type={DocumentCardType.compact}
                        //onClickHref="http://bing.com"
                    >
                        <DocumentCardLogo
                            {...{ logoIcon: "Info" }}
                            title="นำส่งไม่สำเร็จ"
                        />
                        {/* <div className={conversationTileClass}> */}
                        <DocumentCardDetails>
                            <DocumentCardTitle
                                title="นำส่งไม่สำเร็จ"
                                //shouldTruncate
                                styles={cardStyles.header}
                            />
                            {/* <Text styles={cardStyles.amount}>{totalGrand.toLocaleString(undefined, {maximumFractionDigits:2, minimumFractionDigits:2})} บาท</Text> */}
                            <Text styles={cardStyles.amount}>
                                <CountUp
                                    end={totalFail}
                                    separator=","
                                    duration={1}
                                />{" "}
                                เอกสาร
                            </Text>
                            {/* </div>  */}
                        </DocumentCardDetails>
                    </DocumentCard>

                    <DocumentCard
                        aria-label="นำส่งสำเร็จ"
                        styles={cardStyles.cardStyles}
                        type={DocumentCardType.compact}
                        //onClickHref="http://bing.com"
                    >
                        <DocumentCardLogo
                            {...{ logoIcon: "Completed" }}
                            title="นำส่งสำเร็จ"
                        />
                        {/* <div className={conversationTileClass}> */}
                        <DocumentCardDetails>
                            <DocumentCardTitle
                                title="นำส่งสำเร็จ"
                                //shouldTruncate
                                styles={cardStyles.header}
                            />
                            {/* <Text styles={cardStyles.amount}>{totalVat.toLocaleString(undefined, {maximumFractionDigits:2, minimumFractionDigits:2})} บาท</Text> */}
                            <Text styles={cardStyles.amount}>
                                <CountUp
                                    end={totalSuccessful}
                                    separator=","
                                    duration={1}
                                />{" "}
                                เอกสาร
                            </Text>
                            {/* </div>  */}
                        </DocumentCardDetails>
                    </DocumentCard>

                    
                </Stack>
            </Stack>
            {/* <br /> */}
            {/* { true && <div className="ms-Grid" dir="ltr" style={{ paddingLeft: 30, paddingRight: 30, paddingTop: 20, marginLeft: '5%', marginRight: '5%' }}> 
                
                <ProgressIndicator label="กำลังดำเนินการ" description="สร้างเอกสารและลงลายเซ็นดิจิทัล" percentComplete={percentComplete} style={centerItem} />
                <br />
            </div> } */}

            {totalProcessingDocuments > 0 && (
                <div>
                    {/* <Label>Spinner with label positioned below</Label> */}
                    <br />
                    <Spinner
                        label="กำลังสร้างเอกสารและลงลายเซ็นดิจิทัล..."
                        size={SpinnerSize.large}
                    />
                </div>
            )}
            <br />
            {/* <Fabric> */}
            <Stack horizontal horizontalAlign="center">
                <Stack
                    vertical
                    style={{
                        width: "98%",
                        //minWidth: '1070px',
                        maxWidth: "1350px",
                    }}
                >
                    <CommandBar
                        items={commandBarItems}
                        //overflowItems={_overflowItems}
                        //overflowButtonProps={overflowProps}
                        //farItems={_farItems}
                        //ariaLabel="Use left and right arrow keys to navigate between commands"
                    />
                    <ShimmeredDetailsList
                        items={items || []}
                        enableShimmer={!loadDataComplete}
                        compact={isCompactMode}
                        columns={columns}
                        // selectionMode={SelectionMode.none}
                        //selectionMode={SelectionMode.multiple}
                        selection={selection}
                        // selectionMode={SelectionMode.none}
                        selectionMode={
                            !loadDataComplete || isProcessDocument || !isAdmin
                                ? SelectionMode.none
                                : SelectionMode.multiple
                        }
                        //getKey="1"
                        //setKey="set"
                        layoutMode={DetailsListLayoutMode.justified}
                        isHeaderVisible={true}
                        checkboxVisibility={CheckboxVisibility.always}
                        //selection={Selection}
                        selectionPreservedOnEmptyClick={true}
                        //onItemInvoked={download}
                        //enterModalSelectionOnTouch={true}
                        //ariaLabelForSelectionColumn="Toggle selection"
                        //ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                        //checkButtonAriaLabel="Row checkbox"
                        //onRenderItemColumn={ this.onRenderItemColumn }
                        //listProps={shimmeredDetailsListProps}
                    />
                </Stack>
            </Stack>
            {/* </Fabric> */}
            <br />
            <center>
                {!(documents.length === 0 || documents.length < pageSize) && (
                    <Pagination
                        //selectedPageIndex={page}
                        selectedPageIndex={currentPage - 1}
                        //pageCount={pageCount}
                        pageCount={pageData.totalPages}
                        onPageChange={onPageChange}
                        format
                        firstPageIconProps={{ iconName: "DoubleChevronLeft" }}
                        previousPageIconProps={{ iconName: "ChevronLeft" }}
                        nextPageIconProps={{ iconName: "ChevronRight" }}
                        lastPageIconProps={{ iconName: "DoubleChevronRight" }}
                    />
                )}

                <br />
                <br />

                {/* 
            <br/>
            <a href="http://nav.cx/3p1xabz" target="_blank">
                <img src={PUBLIC_URL + '/images/line-add-friend.png'} alt="เพิ่มเพื่อน" height="36" border="0"></img>
            </a>
            <br/>
            <br/>
            <div>ฝ่ายบริการลูกค้า Line: @leceipt</div>
            <br/>
            <div>โทร. 082-579-8555</div>
            <br/>
            <div>เวลาทำการ 8.30 น. - 17.30 น. ทุกวัน</div>
 */}
            </center>
        </div>
    );
};

export default Xml;
